import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { updateUser } from './service';
import { AboutCompany } from '../onboarding/form/onboarding-form-recruiter';

const CandidateInfo = ({ userInfo, setLoading }) => {
  const {
    formState: { errors },
    trigger,
    control,
    getValues,
  } = useForm({
    defaultValues: {
      companyName: userInfo.company_name || '',
      companyWebsite: userInfo.company_website || '',
      aboutCompany: userInfo.about_company || '',
      name: userInfo.name || '',
      designation: userInfo.designation || '',
      emailId: userInfo.email || '',
      linkedInProfile: userInfo.linkedin_profile || '',
    },
  });

  const queryClient = useQueryClient();

  const { mutate } = useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('get-auth0-profile');
      toast.success('Updated Succesfully');
      setLoading(false);
    },
    onError: () => {
      toast.error("Couldn't Update");
      setLoading(false);
    },
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    await trigger([
      'companyName',
      'companyWebsite',
      'aboutCompany',
      'linkedInProfile',
      'name',
      'designation',
    ]).then(async (noError) => {
      if (noError) {
        setLoading(true);
        let {
          companyName,
          companyWebsite,
          aboutCompany,
          name,
          designation,
          emailId,
          linkedInProfile,
        } = getValues();

        let params = {
          ...userInfo,
          designation,
          name,
          about_company: aboutCompany,
          company_name: companyName,
          company_website: companyWebsite,
          email: emailId,
          linkedin_profile: linkedInProfile,
          profile_published: true,
        };

        mutate({ ...params });
      }
    });
  };

  return (
    <div className="recruiter-profile">
      <Form
        className={'w-75 about-company'}
        noValidate
        id="profile"
        onSubmit={onSubmit}
      >
        <AboutCompany
          errors={errors}
          page="profile"
          control={control}
          values={getValues}
        />
        <div className="w-100 d-flex">
          <button className="btn btn-primary btn-rounded text-white ml-auto">
            Save Profile
          </button>
        </div>
      </Form>
    </div>
  );
};

CandidateInfo.propTypes = {
  setLoading: PropTypes.func,
  userInfo: PropTypes.any,
};

export default CandidateInfo;
