import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import '../styles.scss';
import Classnames from 'classnames';
import { useDimensions } from '../../../hooks/useDimensions';

function Personal(props) {
  const { register, errors, values } = props;
  const dimensions = useDimensions();

  const [errorState, setErrorState] = React.useState(errors);

  const [firstField, setFirstField] = useState('');
  const [secondField, setSecondField] = useState('');

  React.useEffect(() => {
    const fieldsValue = values();
    fieldsValue?.professionalExperience &&
      setFirstField(fieldsValue?.professionalExperience);
    fieldsValue?.dreamJob && setSecondField(fieldsValue.dreamJob);
  }, []);

  React.useEffect(() => {
    setErrorState(errors);
  }, [errors?.dreamJob, errors?.professionalExperience]);

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <Form.Group className="form-group position-relative">
            <Form.Label>Tell us about your professional experience.</Form.Label>
            <Form.Control
              id="professionalExperience"
              name="professionalExperience"
              {...register('professionalExperience', { required: true })}
              required
              style={{ height: '112px' }}
              onChange={(e) =>
                setFirstField(e.target.value) +
                setErrorState({ ...errorState, professionalExperience: false })
              }
              type="text"
              as="textarea"
              maxLength="2000"
              placeholder={
                dimensions.width < 576
                  ? 'Tell us about your professional experience'
                  : 'Enter value here'
              }
              className={Classnames('form-control', {
                'is-invalid': errorState?.professionalExperience,
              })}
            />
            <label
              className={`custom-label position-absolute${
                firstField?.length > 0 || errorState?.professionalExperience
                  ? ' show'
                  : ''
              }`}
            >
              {errorState?.professionalExperience
                ? 'Tell us about your professional experience (Required)'
                : 'Tell us about your professional experience'}
            </label>
            <Form.Control.Feedback className="d-flex text-grey" type="invalid">
              {firstField.length}/2000
              {Boolean(errorState?.professionalExperience) && (
                <span className="d-flex text-danger ml-2" type="invalid">
                  Required
                </span>
              )}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <Form.Group className="form-group position-relative">
            <Form.Label>Tell us a little bit about your Dream job</Form.Label>
            <Form.Control
              id="dreamJob"
              name="dreamJob"
              {...register('dreamJob', { required: true })}
              required
              style={{ height: '112px' }}
              maxLength="2000"
              type="text"
              as="textarea"
              onChange={(e) =>
                setSecondField(e.target.value) +
                +setErrorState({ ...errorState, dreamJob: false })
              }
              placeholder={
                dimensions.width < 576
                  ? 'Tell us a little bit about your Dream job'
                  : 'Enter value here'
              }
              className={Classnames('form-control', {
                'is-invalid': errorState?.dreamJob,
              })}
            />
            <label
              className={`custom-label position-absolute${
                secondField?.length > 0 || errorState?.professionalExperience
                  ? ' show'
                  : ''
              }`}
            >
              {errorState?.professionalExperience
                ? 'Tell us a little bit about your Dream job (Required)'
                : 'Tell us a little bit about your Dream job'}
            </label>
            <Form.Control.Feedback className="d-flex text-grey" type="invalid">
              {secondField.length}/2000
              {Boolean(errorState?.dreamJob) && (
                <span className="d-flex text-danger ml-2" type="invalid">
                  Required
                </span>
              )}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </div>
    </>
  );
}

Personal.propTypes = {
  register: PropTypes.any,
  errors: PropTypes.any,
  values: PropTypes.any,
};

export default Personal;
