import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import './styles.scss';
import close from '../../images/Icons/close-black.svg';
import search from '../../images/Icons/search_lens.svg';
import Select from 'react-select';
import {
  useEmploymentOptions,
  useGeographies,
  useSkills,
  useLanguages,
} from '../../hooks/useEntities';
import { useFilters } from '../../context/filters-context';

const Filter = ({ view, onClearAll, setSkip }) => {
  const { data: skills } = useSkills();
  const { data: employementOptions } = useEmploymentOptions();
  const { data: geographies } = useGeographies();
  const { data: languages } = useLanguages();
  const { loading } = useSelector((state) => state.jobs);

  const {
    clearAllFilters,
    selectedTitleFilter,
    setSelectedTitleFilter,
    selectedSkillsFilter,
    setSelectedSkillsFilter,
    selectedLanguagesFilter,
    setSelectedLanguagesFilter,
    jobTypesFilter,
    setJobTypesFilter,
    experience,
    setExperience,
    salary,
    setSalary,
    selectedGeosFilter,
    setSelectedGeosFilter,
  } = useFilters();

  //accordions state
  const [allOpenAccordion, setOpenAccordion] = useState([]);
  const [titleFilterValue, setTitleFilterValue] = useState(selectedTitleFilter);

  const addTitleFilter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let toUpdate = true;
    if (selectedTitleFilter) {
      toUpdate = !(selectedTitleFilter === titleFilterValue);
    }
    titleFilterValue && toUpdate && setSelectedTitleFilter(titleFilterValue);
  };
  const removeTitleFilter = (e) => {
    e.stopPropagation();
    setSelectedTitleFilter(null);
    setTitleFilterValue('');
  };

  const handleSalaryChange = (e) => {
    if (e.currentTarget.value < 100000 && e.currentTarget.value >= 0) {
      setSalary(e.currentTarget.value);
    } else if (e.currentTarget.value >= 100000) {
      setSalary(100000);
    }
    setSkip(0);
  };

  const handleJobTypeChange = (e) => {
    const jobType = employementOptions.filter(
      ({ id }) => id === e.currentTarget.id
    )[0];
    const isSelected = jobTypesFilter.includes(jobType);
    isSelected
      ? setJobTypesFilter(
          jobTypesFilter.filter((type) => type.id !== e.currentTarget.id)
        )
      : setJobTypesFilter([...jobTypesFilter, jobType]);
    setSkip(0);
  };

  const handleExperienceChange = (e) => {
    if (e.target.value <= 99 && e.target.value >= 0) {
      setExperience(e.target.value);
    } else if (e.target.value === '') {
      setExperience(0);
    }
    setSkip(0);
  };

  const preventCharacters = (e) => {
    let charCode = e.which ? e.which : e.keyCode;

    if (
      charCode == 69 ||
      charCode == 40 ||
      charCode == 38 ||
      charCode == 110 ||
      charCode == 107 ||
      charCode == 109 ||
      charCode == 190 ||
      charCode == 189 ||
      charCode == 187
    ) {
      e.preventDefault();
    }
  };

  const toggleAccordian = (e) => {
    const { id } = e.currentTarget;
    if (allOpenAccordion.includes(id)) {
      setOpenAccordion([]);
    } else {
      setOpenAccordion([id]);
    }
  };
  const renderValueCount = (inputValue) => inputValue !== '' && '1';
  const renderArrayValueCount = (inputArrayValue) =>
    inputArrayValue.length?.toString();

  return (
    <>
      <div className="card-body pb-0">
        <div
          className={`row card-border-bottom${!view && ' mb-0'}`}
          {...(!view ? { style: { borderBottom: 'none' } } : {})}
        >
          <div className="col">
            <h5 className="card-title pl-3">Filter By</h5>
          </div>
          <div className="col text-right">
            <button
              onClick={() => {
                clearAllFilters();
                setTitleFilterValue('');
                onClearAll && onClearAll();
              }}
              disabled={
                view
                  ? !(
                      selectedTitleFilter ||
                      selectedSkillsFilter.length > 0 ||
                      selectedLanguagesFilter.length > 0 ||
                      jobTypesFilter.length > 0 ||
                      selectedGeosFilter.length > 0 ||
                      experience ||
                      salary
                    )
                  : true
              }
              className={`btn button1 ${
                view &&
                (selectedTitleFilter ||
                  selectedSkillsFilter.length > 0 ||
                  selectedLanguagesFilter.length > 0 ||
                  jobTypesFilter.length > 0 ||
                  selectedGeosFilter.length > 0 ||
                  experience ||
                  salary)
                  ? 'btn-primary'
                  : 'btn-light'
              } sidebarBtn`}
            >
              Clear All
            </button>
          </div>
        </div>
      </div>

      {view && (
        <div className="accordion-container">
          <Accordion className="accordion-controller">
            <div className="card">
              <div className="card-header">
                <h5 className="mb-0">
                  <Accordion.Toggle
                    eventKey="0"
                    id="0"
                    onClick={toggleAccordian}
                    className={`btn accordion-button accordion-button-sm  f16 d-flex justify-content-between ${
                      !allOpenAccordion.includes('0') && 'collapsed'
                    }`}
                    data-toggle="collapse"
                    aria-expanded="true"
                  >
                    <span className="fw300">Title/postions</span>
                    {selectedTitleFilter && (
                      <div className="filter-count text-center pr-1">
                        {renderValueCount(selectedTitleFilter)}
                      </div>
                    )}
                  </Accordion.Toggle>
                </h5>
              </div>
              <Accordion.Collapse eventKey="0">
                <div className="card-body">
                  <div className="accordion-padding light-bg">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-xl-12">
                        <div className="col-xs-12 col-md-12 col-lg-12 text-right p-0 d-flex flex-row justify-content-end align-items-start">
                          <span className="position-relative w-100">
                            <form onSubmit={!loading ? addTitleFilter : () => {}}>
                              <div onClick={addTitleFilter} className="input-icon">
                                <img className="absolute-left" src={search} />{' '}
                              </div>
                              <input
                                onBlur={!loading ? addTitleFilter : () => {}}
                                className="form-control input-custom mb-0 pl-30 border-3"
                                type="text"
                                onChange={(e) => {
                                  setTitleFilterValue(e.currentTarget.value);
                                  setSkip(0);
                                }}
                                value={titleFilterValue}
                                placeholder="Search By Title"
                              />
                              {selectedTitleFilter && (
                                <div
                                  onClick={removeTitleFilter}
                                  className="dropdown-arrow r-15"
                                >
                                  <img className="absolute-right" src={close} />{' '}
                                </div>
                              )}
                            </form>
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-xl-12"></div>
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </div>
            <div className="card">
              <div className="card-header" id="headingOne">
                <h5 className="mb-0">
                  <Accordion.Toggle
                    eventKey="5"
                    id="5"
                    onClick={toggleAccordian}
                    className={`btn accordion-button accordion-button-sm  f16 d-flex justify-content-between ${
                      !allOpenAccordion.includes('5') && 'collapsed'
                    }`}
                    data-toggle="collapse"
                    aria-expanded="true"
                  >
                    <span className="fw300">Minimum work experience</span>
                    {experience !== '' && (
                      <div className="filter-count text-center pr-1">
                        {renderValueCount(experience)}
                      </div>
                    )}
                  </Accordion.Toggle>
                </h5>
              </div>
              <Accordion.Collapse eventKey="5">
                <div className="card-body">
                  <div className="accordion-padding light-bg">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-xl-12">
                        {/* <input type="range" className="form-range form-control-range border-3"  min={0} max={100} value={experience} onChange={handleExperienceChange} step={1} /> */}
                      </div>
                      <div className="col  col-xs-12">
                        <div className="input-group mb-0">
                          <div className="input-group-prepend">
                            <span className="input-group-text mb-0">years</span>
                          </div>
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            className="form-control border-3 mb-0"
                            min={0}
                            max={100}
                            id="input-right"
                            onKeyDown={preventCharacters}
                            value={experience}
                            onChange={handleExperienceChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </div>
            <div className="card">
              <div className="card-header" id="headingOne">
                <h5 className="mb-0">
                  <Accordion.Toggle
                    eventKey="1"
                    id="1"
                    onClick={toggleAccordian}
                    className={`btn accordion-button accordion-button-sm  f16 d-flex justify-content-between ${
                      !allOpenAccordion.includes('1') && 'collapsed'
                    }`}
                    data-toggle="collapse"
                    aria-expanded="true"
                  >
                    <span className="fw300">Skills</span>
                    {selectedSkillsFilter.length > 0 && (
                      <div className="filter-count text-center pr-1">
                        {renderArrayValueCount(selectedSkillsFilter)}
                      </div>
                    )}
                  </Accordion.Toggle>
                </h5>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="card-body">
                  <div className="accordion-padding light-bg">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-xl-12">
                        <Select
                          options={skills}
                          getOptionValue={(opt) => opt.id}
                          getOptionLabel={(opt) => opt.name}
                          isMulti
                          placeholder="Select Skills"
                          key={selectedSkillsFilter.length}
                          onChange={(data) => {
                            setSelectedSkillsFilter(data);
                            setSkip(0);
                          }}
                          defaultValue={selectedSkillsFilter}
                          classNamePrefix="checkbox-select-filter-box"
                          className="checkbox-select-filter-box"
                          defaultMenuIsOpen={false}
                          closeMenuOnSelect={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </div>
            <div className="card">
              <div className="card-header" id="headingOne">
                <h5 className="mb-0">
                  <Accordion.Toggle
                    eventKey="6"
                    id="6"
                    onClick={toggleAccordian}
                    className={`btn accordion-button accordion-button-sm  f16 d-flex justify-content-between ${
                      !allOpenAccordion.includes('6') && 'collapsed'
                    }`}
                    data-toggle="collapse"
                    aria-expanded="true"
                  >
                    <span className="fw300">Languages</span>
                    {selectedLanguagesFilter.length > 0 && (
                      <div className="filter-count text-center pr-1">
                        {renderArrayValueCount(selectedLanguagesFilter)}
                      </div>
                    )}
                  </Accordion.Toggle>
                </h5>
              </div>
              <Accordion.Collapse eventKey="6">
                <div className="card-body">
                  <div className="accordion-padding light-bg">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-xl-12">
                        <Select
                          options={languages}
                          getOptionValue={(opt) => opt.id}
                          getOptionLabel={(opt) => opt.name}
                          isMulti
                          placeholder="Select Languages"
                          key={selectedLanguagesFilter.length}
                          onChange={(data) => {
                            setSelectedLanguagesFilter(data);
                            setSkip(0);
                          }}
                          defaultValue={selectedLanguagesFilter}
                          classNamePrefix="checkbox-select-filter-box"
                          className="checkbox-select-filter-box"
                          defaultMenuIsOpen={false}
                          closeMenuOnSelect={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </div>
            <div className="card">
              <div className="card-header" id="headingOne">
                <h5 className="mb-0">
                  <Accordion.Toggle
                    eventKey="2"
                    id="2"
                    onClick={toggleAccordian}
                    className={`btn accordion-button accordion-button-sm f16 d-flex justify-content-between fw300 ${
                      !allOpenAccordion.includes('2') && 'collapsed'
                    }`}
                    data-toggle="collapse"
                    aria-expanded="true"
                  >
                    <span className="text-nowrap">
                      Salary expectations (monthly)
                    </span>
                    {salary && (
                      <div className="filter-count text-center pr-1">
                        {renderValueCount(salary)}
                      </div>
                    )}
                  </Accordion.Toggle>
                </h5>
              </div>
              <Accordion.Collapse eventKey="2">
                <div className="card-body">
                  <div className="accordion-padding light-bg">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-xl-12">
                        {/* <input type="range" className="form-range form-control-range border-3"  id="input-right" min="0" max="100000" value={salary} onChange={handleSalaryChange} step="100" /> */}
                      </div>
                      <div className="col  col-xs-12">
                        <div className="input-group mb-0">
                          <div className="input-group-prepend">
                            <span className="input-group-text mb-0">$</span>
                          </div>
                          <input
                            type="number"
                            className="form-control border-3 fs-16 mb-0"
                            id="input-right"
                            onWheel={(e) => e.target.blur()}
                            value={salary}
                            onKeyDown={preventCharacters}
                            onChange={handleSalaryChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </div>
            <div className="card">
              <div className="card-header" id="headingOne">
                <h5 className="mb-0">
                  <Accordion.Toggle
                    eventKey="3"
                    id="3"
                    onClick={toggleAccordian}
                    className={`btn accordion-button accordion-button-sm f16 d-flex justify-content-between ${
                      !allOpenAccordion.includes('3') && 'collapsed'
                    }`}
                    data-toggle="collapse"
                    aria-expanded="true"
                  >
                    <span className="fw300"> Employment options</span>
                    {jobTypesFilter.length > 0 && (
                      <div className="filter-count text-center pr-1">
                        {renderArrayValueCount(jobTypesFilter)}
                      </div>
                    )}
                  </Accordion.Toggle>
                </h5>
              </div>
              <Accordion.Collapse eventKey="3">
                <div className="card-body">
                  <div className="accordion-padding light-bg">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-xl-12 pl-4">
                        <ul className="list-group no-bg ">
                          {employementOptions &&
                            employementOptions.map((type) => (
                              <li
                                key={type.id}
                                className="list-group-item border-0 no-bg p-1 px-4 form-check"
                              >
                                <input
                                  className="form-check-input me-1 f16 border-3 cursor-pointer"
                                  type="checkbox"
                                  checked={
                                    jobTypesFilter.filter((t) => type.id === t.id)
                                      .length
                                  }
                                  onChange={handleJobTypeChange}
                                  id={type.id}
                                  value={type.name}
                                />
                                <label
                                  htmlFor={type.id}
                                  className="f16 cursor-pointer"
                                >
                                  {type.name}
                                </label>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </div>
            <div className="card pb-4">
              <div className="card-header border-0" id="headingOne">
                <h5 className="mb-0">
                  <Accordion.Toggle
                    eventKey="4"
                    id="4"
                    onClick={toggleAccordian}
                    className={`btn accordion-button accordion-button-sm f16 d-flex justify-content-between ${
                      !allOpenAccordion.includes('4') && 'collapsed'
                    }`}
                    data-toggle="collapse"
                    aria-expanded="true"
                  >
                    <span className="fw300">Preferred geographies</span>
                    {selectedGeosFilter.length > 0 && (
                      <div className="filter-count text-center pr-1">
                        {renderArrayValueCount(selectedGeosFilter)}
                      </div>
                    )}
                  </Accordion.Toggle>
                </h5>
              </div>
              <Accordion.Collapse eventKey="4">
                <div className="card-body">
                  <div className="accordion-padding light-bg">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-xl-12">
                        <Select
                          options={geographies}
                          getOptionValue={(opt) => opt.id}
                          getOptionLabel={(opt) => opt.name}
                          isMulti
                          key={selectedGeosFilter.length}
                          placeholder="Select country"
                          onChange={(data) => {
                            setSelectedGeosFilter(data);
                            setSkip(0);
                          }}
                          defaultValue={selectedGeosFilter}
                          classNamePrefix="checkbox-select-filter-box"
                          className="checkbox-select-filter-box"
                          defaultMenuIsOpen={false}
                          closeMenuOnSelect={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </div>
          </Accordion>
        </div>
      )}
    </>
  );
};

Filter.propTypes = {
  getAppliedFilters: PropTypes.any,
  view: PropTypes.bool,
  onClearAll: PropTypes.func,
  setSkip: PropTypes.func,
};

export default Filter;
