import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import CardHeader from '../card-header';
import CardBody from '../card-body';
const Card = (props) => {
  const {
    id,
    title,
    bodyHeader,
    body,
    locations,
    employmentOptions,
    show_tag,
    skills,
    experience,
    userInfo,
    companyName,
    domain,
    appliedJobs,
    salary,
    contactedJobs,
    onMessage,
    status,
    onSave,
    className,
    saved,
    savingId,
    onClick,
    showRemoveText,
    showSkills,
  } = props;

  return (
    <div
      className={`card ${className}${
        appliedJobs?.includes(id) && show_tag ? ' applied' : ''
      }`}
      key={id}
    >
      <CardHeader
        id={id}
        title={title}
        onSave={onSave}
        savingId={savingId}
        status={status}
        saved={saved}
        show_tag={show_tag}
        userInfo={userInfo}
        onMessage={onMessage}
        contactedJobs={contactedJobs}
        appliedJobs={appliedJobs}
        companyName={companyName}
        experience={experience}
        onClick={() => onClick(id)}
        locations={locations}
        employmentOptions={employmentOptions}
        salary={salary}
      />
      <CardBody body={body} bodyHeader={bodyHeader} onClick={() => onClick(id)} />
      {showRemoveText && (
        <span className="remove-text" onClick={() => onSave(id)}>
          Remove from my list.
        </span>
      )}
      {showSkills && (
        <div className="card-body d-flex align-items-center skills mx-3 px-0 py-2">
          {skills.map((s, i) => (
            <span key={i} className="badge badge-secondary p-1 fs-14">
              {s}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

Card.propTypes = {
  userInfo: PropTypes.any,
  id: PropTypes.string,
  title: PropTypes.string,
  bodyHeader: PropTypes.string,
  body: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.string),
  employmentOptions: PropTypes.arrayOf(PropTypes.string),
  savedJobs: PropTypes.arrayOf(PropTypes.string),
  contactedJobs: PropTypes.arrayOf(PropTypes.string),
  appliedJobs: PropTypes.arrayOf(PropTypes.string),
  skills: PropTypes.arrayOf(PropTypes.string),
  companyName: PropTypes.string,
  domain: PropTypes.string,
  salary: PropTypes.number,
  onMessage: PropTypes.func,
  onSave: PropTypes.func,
  className: PropTypes.string,
  experience: PropTypes.number,
  saved: PropTypes.bool,
  showRemoveText: PropTypes.bool,
  status: PropTypes.bool,
  savingId: PropTypes.string,
  onClick: PropTypes.func,
  showSkills: PropTypes.bool,
  show_tag: PropTypes.bool,
};

Card.defaultProps = {
  id: '',
  title: '',
  bodyHeader: '',
  body: '',
  experience: null,
  locations: [],
  employmentOptions: [],
  savedJobs: [],
  appliedJobs: [],
  contactedJobs: [],
  skills: [],
  companyName: '',
  domain: '',
  salary: null,
  onMessage: null,
  onSave: null,
  className: '',
  saved: false,
  savingId: null,
  onClick: null,
  showSkills: false,
  show_tag: false,
};

export default Card;
