import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap';
import CardHeader from '../card-header';
import ReactTooltip from 'react-tooltip';
import { FiInfo } from 'react-icons/fi';
import { useMessageThreads } from '../../context/message-threads-context';

const CandidateDetailCard = ({
  candidate,
  mutate,
  savingCandidateId,
  savedCandidates,
}) => {
  const { openThreadModal, setCandidateId, setCandidateData, contactedUsers } =
    useMessageThreads();
  const [allOpenAccordion, setOpenAccordion] = React.useState([]);

  const onCandidateMessage = (item, id) => {
    setCandidateData(item);
    setCandidateId(id);
    openThreadModal();
  };

  const contacted = contactedUsers
    ?.map((i) => i?.candidate_id)
    ?.includes(candidate?.id);

  const toggleAccordian = (e) => {
    const { id } = e.currentTarget;
    allOpenAccordion.includes(id)
      ? setOpenAccordion(allOpenAccordion.filter((i) => i !== id))
      : setOpenAccordion([...allOpenAccordion, id]);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="row">
      <div className="col-xs-12 col-md-12 col-xl-12">
        <div className="card">
          <CardHeader
            id={candidate?.id}
            userInfo={{ user_type: 'recruiter' }}
            title={candidate?.designation}
            onSave={mutate}
            savingId={savingCandidateId}
            contactedJobs={contactedUsers?.map((i) => i?.candidate_id)}
            saved={savedCandidates && savedCandidates?.includes(candidate?.id)}
            onMessage={(id) => onCandidateMessage(candidate, candidate?.id)}
            experience={candidate?.work_experience}
            locations={candidate?.locations.map((l) => l.name)}
            employmentOptions={candidate?.employment_options.map((e) => e.name)}
            salary={candidate?.salary_expectations}
          />
          <Accordion
            id="accordion"
            className="accordion-controller no-sidebar py-2 px-3 border-bottom"
            defaultActiveKey="0"
          >
            <div className="card">
              <div className="card-header" id="headingOne">
                <p className="mb-0">
                  <Accordion.Toggle
                    id="0"
                    eventKey="0"
                    onClick={toggleAccordian}
                    className={`btn accordion-button accordion-button-sm ${
                      allOpenAccordion.includes('0') && 'collapsed'
                    } right-0 font-weight-bold`}
                    data-toggle="collapse"
                    aria-expanded="true"
                  >
                    Experience
                  </Accordion.Toggle>
                </p>
              </div>
              <Accordion.Collapse eventKey="0">
                <div className="card-body">
                  <p className="text-grey preserve-line">
                    {candidate?.about_professional_experience}
                  </p>
                </div>
              </Accordion.Collapse>
            </div>
          </Accordion>
          <Accordion
            id="skillsaccordion"
            className="accordion-controller no-sidebar py-2 px-3 border-bottom"
            defaultActiveKey="0"
          >
            <div className="card">
              <div className="card-header" id="skillsAccordionHeadingOne">
                <p className="mb-0">
                  <Accordion.Toggle
                    eventKey="0"
                    id="1"
                    onClick={toggleAccordian}
                    className={`btn accordion-button accordion-button-sm ${
                      allOpenAccordion.includes('1') && 'collapsed'
                    } right-0 font-weight-bold`}
                    data-toggle="collapse"
                    aria-expanded="true"
                  >
                    Highlights
                  </Accordion.Toggle>
                </p>
              </div>
              <Accordion.Collapse eventKey="0">
                <div className="card-body">
                  <div className="d-flex flex-row">
                    <div className="py-2 pe-2" style={{ minWidth: '12em' }}>
                      Skills
                    </div>
                    <div className="card-body py-2">
                      {candidate?.skills?.map((s, i) => (
                        <span key={i} className="badge badge-secondary p-1 fs-14">
                          {s.name}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="py-2 pe-2" style={{ minWidth: '12em' }}>
                      Salary Expectations{' '}
                      <FiInfo
                        className="salary-tooltip"
                        data-tip
                        data-for="password-info"
                      />
                      <ReactTooltip
                        id="password-info"
                        type="light"
                        effect="solid"
                        place="top"
                        className="tooltip-shadow"
                      >
                        <p className="p-1 pb-0 mb-0">Monthly gross salary in USD</p>
                      </ReactTooltip>
                    </div>
                    <div className="card-body py-2 px-1 font-weight-bold">
                      ${candidate?.salary_expectations}
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="py-2 pe-2" style={{ minWidth: '12em' }}>
                      Languages
                    </div>
                    <div className="card-body py-2 font-weight-bold">
                      {candidate?.languages.map((s, i) => (
                        <span key={i} className="badge badge-secondary p-1 fs-14">
                          {s.name}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="py-2 pe-2" style={{ minWidth: '12em' }}>
                      Employment Options
                    </div>
                    <div className="card-body py-2 font-weight-bold">
                      {candidate?.employment_options.map((s, i) => (
                        <span key={i} className="badge badge-secondary p-1 fs-14">
                          {s.name}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="py-2 pe-2" style={{ minWidth: '12em' }}>
                      Cities
                    </div>
                    <div className="card-body py-2 font-weight-bold">
                      {candidate?.locations.map((s, i) => (
                        <span key={i} className="badge badge-secondary p-1 fs-14">
                          {s.name}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </div>
          </Accordion>
          <Accordion
            id="dreamjobaccordian"
            className="accordion-controller no-sidebar py-2 px-3 border-bottom"
            defaultActiveKey="0"
          >
            <div className="card">
              <div className="card-header" id="skillsAccordionHeadingOne">
                <p className="mb-0">
                  <Accordion.Toggle
                    eventKey="0"
                    id="2"
                    onClick={toggleAccordian}
                    className={`btn accordion-button accordion-button-sm ${
                      allOpenAccordion.includes('2') && 'collapsed'
                    } right-0 font-weight-bold`}
                    data-toggle="collapse"
                    aria-expanded="true"
                  >
                    My dream job
                  </Accordion.Toggle>
                </p>
              </div>
              <Accordion.Collapse eventKey="0">
                <div className="card-body">
                  <p className="text-grey preserve-line">
                    {candidate?.about_dream_job}
                  </p>
                </div>
              </Accordion.Collapse>
            </div>
          </Accordion>
          <div className="card-footer bg-white d-flex justify-content-end border-top-0">
            <button
              className="btn btn-primary btn-rounded text-white"
              onClick={() => onCandidateMessage(candidate, candidate?.id)}
            >
              {contacted ? 'Messages' : 'Contact'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

CandidateDetailCard.propTypes = {
  candidate: PropTypes.object,
  mutate: PropTypes.func,
  savingCandidateId: PropTypes.string,
  savedCandidates: PropTypes.array,
};

CandidateDetailCard.defaultProps = {
  candidate: null,
  mutate: null,
  savingCandidateId: '',
  savedCandidates: [],
};

export default CandidateDetailCard;
