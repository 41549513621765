import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import reducer from './store/reducers/rootReducer';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './context/auth-provider';
import { QueryClientProvider, QueryClient } from 'react-query';
import { MessageThreadsProvider } from './context/message-threads-provider';
import { ApplyJobProvider } from './context/apply-job-provider';
import { FiltersProvider } from './context/filters-provider';
import { JobSortProvider } from './context/job-sort-provider';
import { ListsProvider } from './context/lists-provider';

export const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(thunk))
);

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          <ListsProvider>
            <MessageThreadsProvider>
              <ApplyJobProvider>
                <FiltersProvider>
                  <JobSortProvider>
                    <Provider store={store}>
                      <App />
                    </Provider>
                  </JobSortProvider>
                </FiltersProvider>
              </ApplyJobProvider>
            </MessageThreadsProvider>
          </ListsProvider>
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
