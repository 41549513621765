import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
    };
  }
  componentDidCatch(error) {
    if (error.response) {
      if (error.response.status === 401) {
        this.setState({ error: true });
      }
    } else {
      console.log(error);
    }
  }

  static get propTypes() {
    return {
      children: PropTypes.any,
    };
  }

  render() {
    if (this.state.error) {
      return <Redirect to="/login" />;
    }

    return this.props.children;
  }
}
