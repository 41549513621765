import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useAuth } from "../../context/auth-context";
import Loader from "../loader";

const Callback = () => {
  const auth = useAuth();

  useEffect(() => {
    auth.handleAuth();
  }, []);

  return (
    <div className="d-flex w-100 vh-100 justify-content-center align-items-center">
      <Loader/>
    </div>
  );
};

Callback.propTypes = {
  auth: PropTypes.any,
};

export default Callback;
