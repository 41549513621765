import lzs from 'lz-string';
import Resizer from 'react-image-file-resizer';

export const validateEmail = (email) =>
  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(email);

export const validateUrl = (url, optional) =>
  optional && url == ''
    ? true
    : /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(
        url
      );

export const validatePassword = (pass) =>
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/.test(pass);

export const validateLinkedInUrl = (url) =>
  /^(http(s?):\/\/)?((www)\.)?linkedin\.com\/[\w]+\/[a-zA-Z0-9-]+\/?$/.test(url);

export const validateGitHubUrl = (url) =>
  /^(http(s?):\/\/)?((www)\.)?github\.com\/[a-zA-Z0-9-]+\/?$/.test(url);

/**
 * constructs a URL to send information to a detail page
 * @param {any} parameters "{ id: '', filters: {...}}"
 * @param {string} noun "candidates|jobs"
 * @returns
 */
export const constructEncodedUrl = (parameters, noun, tab = 'same') => {
  const compressed = lzs.compressToEncodedURIComponent(JSON.stringify(parameters));
  return tab == 'same' ? `/${noun}/${compressed};` : `/${noun}?${compressed};`;
};

export const deconstructEncodedUrl = (data) =>
  JSON.parse(lzs.decompressFromEncodedURIComponent(data));

export const isCandidate = (user_type) => {
  return user_type === 'candidate';
};

export const isRecruiter = (user_type) => {
  return user_type === 'recruiter';
};

export const orderByName = (data, field_name) => {
  return data.sort((a, b) =>
    a[field_name]?.toLowerCase()?.trim() > b[field_name]?.toLowerCase()?.trim()
      ? 1
      : b[field_name]?.toLowerCase()?.trim() > a[field_name]?.toLowerCase()?.trim()
      ? -1
      : 0
  );
};

export const checkImageWidth = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const image = new Image();
      image.src = event.target.result;
      image.onload = () => {
        resolve(image.width);
        return image.width;
      };
      reader.onerror = (err) => reject(err);
    };
  });
};

export const resizeImage = (file) => {
  let quality = 100;
  //4MB image file
  if (file.size > 4000000) {
    quality = 90;
  }
  //8MB image file
  if (file.size > 8000000) {
    quality = 85;
  }
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1400,
      1000,
      'JPEG',
      quality,
      0,
      (uri) => {
        resolve(uri);
      },
      'blob'
    );
  });
};
