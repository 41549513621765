import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';

const Loader = (props) => {
  const { className } = props;
  return (
    <div
      className={`d-flex flex-column justify-content-center align-items-center ${className}`}
    >
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

Loader.propTypes = {
  className: PropTypes.string
}

Loader.defaultProps = {
  className: ""
}

export default Loader;
