import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { FiInfo } from 'react-icons/fi';
import '../styles.scss';
import { Controller } from 'react-hook-form';
import Classnames from 'classnames';
import _ from 'lodash';
import Select from 'react-select';
/* eslint-disable react/prop-types */

function About(props) {
  const { errors, control, skills, languages, geos, employments } = props;
  const [errorState, setErrorState] = React.useState(errors);

  React.useEffect(() => {
    setErrorState(errors);
  }, [
    errors?.title,
    errors?.skills,
    errors?.salaryExpectation,
    errors?.languages,
    errors?.geos,
    errors?.experience,
    errors?.employment,
  ]);

  const preventCharacters = (e) => {
    let charCode = e.which ? e.which : e.keyCode;

    if (
      charCode == 69 ||
      charCode == 40 ||
      charCode == 38 ||
      charCode == 110 ||
      charCode == 107 ||
      charCode == 109 ||
      charCode == 190 ||
      charCode == 189 ||
      charCode == 187
    ) {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <Form.Group className="form-group position-relative">
            <Form.Label>Current Position/Title</Form.Label>
            <Controller
              control={control}
              id={`title`}
              name={`title`}
              defaultValue={''}
              rules={{ required: true }}
              render={(props) => (
                <>
                  <input
                    type="text"
                    className={Classnames(
                      'form-control',
                      errorState?.title && 'is-invalid'
                    )}
                    placeholder={`Enter title here`}
                    onChange={(e) =>
                      props.field.onChange(e.target.value) +
                      setErrorState({ ...errorState, title: false })
                    }
                    defaultValue={props.field.value}
                  />
                  <label
                    className={`custom-label position-absolute${
                      props.field.value !== '' || errorState?.title ? ' show' : ''
                    }`}
                  >
                    {errorState?.title
                      ? 'Current Position/Title (Required)'
                      : 'Current Position/Title'}
                  </label>
                </>
              )}
            />

            {Boolean(errorState?.title) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Required
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-xl-6">
          <Form.Group className="form-group position-relative">
            <Form.Label>Work experience (years)</Form.Label>
            <Controller
              control={control}
              id={`experience`}
              name={`experience`}
              defaultValue={''}
              rules={{ required: true }}
              render={(props) => (
                <>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={preventCharacters}
                    className={Classnames(
                      'form-control',
                      errorState?.experience && 'is-invalid'
                    )}
                    placeholder={`Number of years of work experience`}
                    onChange={(e) =>
                      props.field.onChange(e.target.value) +
                      setErrorState({ ...errorState, experience: false })
                    }
                    defaultValue={props.field.value}
                  />
                  <label
                    className={`custom-label position-absolute${
                      props.field.value !== '' || errorState?.experience
                        ? ' show'
                        : ''
                    }`}
                  >
                    {errorState?.title
                      ? 'Work experience (years) (Required)'
                      : 'Work experience (years)'}
                  </label>
                </>
              )}
            />
            {Boolean(errorState?.experience) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Required
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
        <div className="col-sm-12 col-md-6 col-xl-6">
          <Form.Group className="form-group position-relative">
            <Form.Label>Skills</Form.Label>
            <Controller
              control={control}
              id="skills"
              name="skills"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <>
                  <Select
                    options={skills}
                    getOptionValue={(opt) => opt.id}
                    getOptionLabel={(opt) => opt.name}
                    isMulti
                    placeholder="Select skills"
                    onChange={(e) =>
                      onChange(e) + setErrorState({ ...errorState, skills: false })
                    }
                    defaultValue={value}
                    className={Classnames(
                      'mb-3',
                      errorState?.skills && 'is-invalid'
                    )}
                    classNamePrefix="checkbox-select"
                  />
                  <label
                    className={`custom-label select position-absolute${
                      value?.length > 0 || errorState?.skills ? ' show' : ''
                    }`}
                  >
                    {errorState?.skills ? 'Skills (Required)' : 'Skills'}
                  </label>
                </>
              )}
            />
            {Boolean(errorState?.skills) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Required
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-xl-6">
          <Form.Group className="form-group position-relative">
            <Form.Label>
              Salary expectations{' '}
              <FiInfo
                className="mb-1 salary-tooltip"
                data-tip
                data-for="password-info"
              />
              <ReactTooltip
                id="password-info"
                type="light"
                effect="solid"
                place="top"
                className="tooltip-shadow"
              >
                <p className="p-1 pb-0 mb-0">Monthly gross salary in USD</p>
              </ReactTooltip>
            </Form.Label>
            <Controller
              control={control}
              id={`salaryExpectation`}
              name={`salaryExpectation`}
              defaultValue={''}
              rules={{ required: true, min: 3 }}
              render={(props) => (
                <>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className={Classnames(
                          'input-group-text',
                          errorState?.salaryExpectation && 'is-invalid'
                        )}
                      >
                        $
                      </span>
                    </div>
                    <input
                      onKeyDown={preventCharacters}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      className={Classnames(
                        'form-control mb-0',
                        errorState?.salaryExpectation && 'is-invalid'
                      )}
                      placeholder={`Expected monthly salary`}
                      onChange={(e) =>
                        props.field.onChange(e.target.value) +
                        setErrorState({ ...errorState, salaryExpectation: false })
                      }
                      defaultValue={props.field.value}
                    />
                    <label
                      style={{ borderRadius: '4px' }}
                      className={`custom-label position-absolute${
                        props.field.value > 0 || errorState?.salaryExpectation
                          ? ' show'
                          : ''
                      }`}
                    >
                      {errorState?.salaryExpectation
                        ? 'Salary expectations (Required)'
                        : 'Salary expectations'}
                    </label>
                  </div>
                </>
              )}
            />
            {Boolean(errorState?.salaryExpectation) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Required
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
        <div className="col-sm-12 col-md-6 col-xl-6">
          <Form.Group className="form-group position-relative">
            <Form.Label>Language</Form.Label>
            <Controller
              control={control}
              id="languages"
              name="languages"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <>
                  <Select
                    options={languages}
                    getOptionValue={(opt) => opt.id}
                    getOptionLabel={(opt) => opt.name}
                    isMulti
                    placeholder="Select languages"
                    onChange={(e) =>
                      onChange(e) +
                      setErrorState({ ...errorState, languages: false })
                    }
                    defaultValue={value}
                    className={Classnames(
                      'mb-3',
                      errorState?.languages && 'is-invalid'
                    )}
                    classNamePrefix="checkbox-select"
                  />
                  <label
                    className={`custom-label select position-absolute${
                      value?.length > 0 || errorState?.languages ? ' show' : ''
                    }`}
                  >
                    {errorState?.languages ? 'Language (Required)' : 'Language'}
                  </label>
                </>
              )}
            />
            {Boolean(errorState?.languages) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Required
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-xl-6">
          <Form.Group className="form-group position-relative">
            <Form.Label>Employment options</Form.Label>
            <Controller
              control={control}
              id="employment"
              name="employment"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <>
                  <Select
                    options={employments}
                    getOptionValue={(opt) => opt.id}
                    getOptionLabel={(opt) => opt.name}
                    isMulti
                    placeholder="Select employment options"
                    onChange={(e) =>
                      onChange(e) +
                      setErrorState({ ...errorState, employment: false })
                    }
                    defaultValue={value}
                    className={Classnames(
                      'mb-3',
                      errorState?.employment && 'is-invalid'
                    )}
                    classNamePrefix="checkbox-select"
                  />{' '}
                  <label
                    className={`custom-label select position-absolute${
                      value?.length > 0 || errorState?.employment ? ' show' : ''
                    }`}
                  >
                    {errorState?.employment
                      ? 'Employment options (Required)'
                      : 'Employment options'}
                  </label>
                </>
              )}
            />
            {Boolean(errorState?.employment) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Required
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
        <div className="col-sm-12 col-md-6 col-xl-6">
          <Form.Group className="form-group position-relative">
            <Form.Label>Preferred geographies</Form.Label>
            <Controller
              control={control}
              id="geos"
              name="geos"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <>
                  <Select
                    options={geos}
                    getOptionValue={(opt) => opt.id}
                    getOptionLabel={(opt) => opt.name}
                    isMulti
                    placeholder="Select geographies"
                    onChange={(e) =>
                      onChange(e) + setErrorState({ ...errorState, geos: false })
                    }
                    defaultValue={value}
                    className={Classnames('mb-3', errorState?.geos && 'is-invalid')}
                    classNamePrefix="checkbox-select"
                  />
                  <label
                    className={`custom-label select position-absolute${
                      value?.length > 0 || errorState?.geos ? ' show' : ''
                    }`}
                  >
                    {errorState?.geos
                      ? 'Preferred geographies (Required)'
                      : 'Preferred geographies'}
                  </label>
                </>
              )}
            />
            {Boolean(errorState?.geos) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Required
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
      </div>
    </>
  );
}

About.propTypes = {
  register: PropTypes.any,
  errors: PropTypes.any,
  trigger: PropTypes.any,
  control: PropTypes.any,
  handleSubmit: PropTypes.func,
  setValue: PropTypes.func,
  clearErrors: PropTypes.any,
  data: PropTypes.any,
};

export default About;
