import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/auth-context';
import { useQuery } from 'react-query';
import {
  fetchUserOnboardingStatus,
  fetchUserProfileDetails,
} from './services/with-user-state-checks.service';
import Loader from '../components/loader';
import { Redirect } from 'react-router';
const withUserChecks = (Component) => {
  return function RenderComponent(props) {
    /**
     * Used to keep track of auth0 userinfo calls
     */
    let [auth0ProfileFetched, setAuth0ProfileFetched] = useState(false);
    /**
     * Used to keep track of user email verified on auth0
     */
    let [isVerified, setIsVerifed] = useState(false);
    const auth = useAuth();
    /**
     * Get user profile from auth0
     */
    useEffect(() => {
      if (auth.isAuthenticated()) {
        auth.getProfile((profile) => {
          const { email_verified, email } = profile;
          if (window.heap) {
            window.heap.identify(email);
          }
          if (email_verified) {
            setIsVerifed(email_verified);
          }
          setAuth0ProfileFetched(true);
        });
      }
    }, [auth, auth.isAuthenticated()]);
    /**
     * Fetch onboarding status of a user
     * Depends on
     *  - user authenticated
     *  - user verified
     */
    const {
      data: onboardingStatus,
      isLoading: isLoadingOnboardingStatus,
      isError: isErrorOnboardingStatus,
      error: errorOnboarding,
    } = useQuery('get-user-onboarding-status', fetchUserOnboardingStatus, {
      enabled: auth.isAuthenticated() && isVerified,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      useErrorBoundary: (error) => {
        return error.response.status === 401;
      },
      retry: (count, error) => {
        if (error.response.status === 401) {
          return false;
        } else if (count <= 3) {
          return true;
        } else {
          false;
        }
      },
    });
    /**
     * Fetch user profile details
     * Depends on
     *  - user authenticated
     *  - user verified
     *  - user onboarded
     */
    const { data, isLoading, isFetching, isError, error } = useQuery(
      'get-auth0-profile',
      fetchUserProfileDetails,
      {
        enabled:
          auth.isAuthenticated() &&
          isVerified &&
          onboardingStatus !== undefined &&
          onboardingStatus === true,
        refetchOnWindowFocus: false,
      }
    );

    const [user_type, setUser_type] = React.useState('');

    React.useEffect(() => {
      if (data && data.user_type !== user_type) {
        setUser_type(data.user_type);
      }
    }, [data]);

    if (!auth.isAuthenticated()) {
      return <Redirect to="/login" />;
    }

    if (!auth0ProfileFetched || isLoadingOnboardingStatus || isLoading) {
      return (
        <div className="d-flex vh-100 w-100 justify-content-center align-items-center">
          <Loader className="mt-5" />
        </div>
      );
    }

    if (auth0ProfileFetched && !isVerified) {
      return <Redirect to="/verify" />;
    }

    if (onboardingStatus === false && !isLoadingOnboardingStatus) {
      return <Redirect to="/onboarding" />;
    }

    return (
      <Component {...props} userInfo={{ user_type, ...(!isFetching && data) }} />
    );
  };
};

export default withUserChecks;
