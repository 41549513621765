export const filtersEmptyState = {
  skills: [],
  languages: [],
  employmentOptions: [],
  locations: [],
};

export const postTypes = {
  job: 'job',
  candidate: 'candidate',
};

export const allowedTypes = {
  candidate:{
    resume: ['application/pdf'],
    profilePhoto: ['image']
  }
}