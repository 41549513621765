import { useState } from 'react';
import PropTypes from 'prop-types';
import { ApplyJobContext } from './apply-job-context';

export const ApplyJobProvider = ({ children }) => {
  const [showApplyJob, setShowApplyJob] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showMessages, setShowMessages] = useState(true);
  const [job, setJob] = useState(null);

  const toggleApplyJob = (job = null, userInfo = null) => {
    if (job) {
      const { resume_link } = userInfo;
      setJob(job);
      setShowApplyJob(true);
      if (resume_link) {
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }
    } else {
      setJob(null);
      setShowApplyJob(false);
      setShowWarning(false);
    }
  };
  return (
    <ApplyJobContext.Provider
      value={{
        toggleApplyJob,
        job,
        showMessages,
        showApplyJob,
        showWarning,
        setShowMessages,
        setShowApplyJob,
      }}
    >
      {children}
    </ApplyJobContext.Provider>
  );
};

ApplyJobProvider.propTypes = {
  children: PropTypes.any,
};
