import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, Redirect } from 'react-router';
import Loader from '../../components/loader';
import PropTypes from 'prop-types';
import Filter from '../dashboard/filter';
import Gauge from '../dashboard/gauge';
import JobList from '../dashboard/joblist';
import { getJobs, saveJob } from '../dashboard/service';
import { constructEncodedUrl } from '../../utils/helpers';
import { useSavedJobs } from '../../hooks/useSavedJobs';
import { useFilters } from '../../context/filters-context';
import { useJobSort } from '../../context/job-sort-context';
import { useAppliedJobs } from '../../hooks/useAppliedJobs';

const SearchPage = (props) => {
  const { userInfo, contactedJobs } = props;

  if (userInfo?.user_type == 'recruiter') {
    return <Redirect to="/candidates" />;
  }

  const history = useHistory();
  const [savingJobId, setSavingJobId] = useState(null);
  const [skip, setSkip] = useState(history.location.state?.skip || 0);
  const [limit] = useState(5);
  const { sortBy } = useJobSort();
  const { appliedJobs } = useAppliedJobs();

  const { appliedFilters } = useFilters();
  const queryClient = useQueryClient();

  const {
    data,
    isLoading,
    refetch: refetchSearchJobs,
  } = useQuery(
    [
      'search-jobs',
      { ...appliedFilters, from: skip, size: limit, sortBy: sortBy.value },
    ],
    getJobs,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      useErrorBoundary: (error) => {
        return error.response.status === 401;
      },
      retry: (count, error) => {
        if (error.response.status === 401) {
          return false;
        } else if (count <= 3) {
          return true;
        } else {
          false;
        }
      },
    }
  );

  React.useEffect(() => {
    if (skip >= data?.totalJobs) {
      setSkip(0);
    }
  }, [data?.totalJobs]);

  const {
    jobs: savedJobs,
    isLoading: isLoadingSavedJobs,
    refetch: refetchSavedJobs,
    isFetching: isFetchingSavedJobs,
  } = useSavedJobs();
  useEffect(() => {
    /**
     * Doing this to get smooth transitions between saved/unsave and loading
     */
    if (!isFetchingSavedJobs) {
      if (savingJobId !== null) {
        setSavingJobId(null);
      }
    }
  }, [isFetchingSavedJobs]);

  React.useEffect(() => {
    if (
      appliedFilters.employmentOptions?.length > 0 ||
      appliedFilters.languages?.length > 0 ||
      appliedFilters.locations?.length > 0 ||
      appliedFilters.skills?.length > 0 ||
      appliedFilters.salary ||
      appliedFilters.title ||
      appliedFilters.experience
    ) {
      !history.location.state?.skip && setSkip(0);
    }
  }, [appliedFilters]);

  const { mutate } = useMutation(saveJob, {
    onSettled: () => {
      refetchSavedJobs();
    },
    onMutate: (id) => setSavingJobId(id),
    useErrorBoundary: (error) => {
      return error.response?.status === 401;
    },
    retry: (count, error) => {
      if (error.response?.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const selectJob = (id, key, returnLink = false) => {
    const filters = {
      ...appliedFilters,
      sortBy: sortBy.value,
      from: skip,
      size: limit,
    };
    if (returnLink) {
      return constructEncodedUrl({ id, filters }, 'job');
    }
    history.push(constructEncodedUrl({ id, filters }, 'job'));
  };

  const paginationArray = [
    ...Array(parseInt(Math.ceil(data?.totalJobs / limit) || 0)).keys(),
  ];

  const renderPagination = () => {
    let pages = paginationArray;

    if (pages.length > 5) {
      if (skip <= 2 * limit) {
        pages = pages.slice(0, 4).concat(['...', pages[pages.length - 1]]);
      } else if (skip > 2 * limit && skip < limit * (pages.length - 2)) {
        pages = [
          pages[0],
          '...',
          skip / limit - 1,
          skip / limit,
          skip / limit + 1,
          '...',
          pages[pages.length - 1],
        ];
      } else if (skip > limit * (pages.length - 3)) {
        pages = [
          pages[0],
          '...',
          pages[pages.length - 4],
          pages[pages.length - 3],
          pages[pages.length - 2],
          pages[pages.length - 1],
        ];
      }
    }

    return (
      <>
        {pages.length > 5 && (
          <button
            className="btn py-1 d-flex align-items-center justify-content-center"
            style={{ width: 'min-content' }}
            onClick={() => onPaginate(skip / limit - 1)}
          >
            <span className="chevron left"></span>
          </button>
        )}
        {pages.map((item, key) => {
          return (
            <button
              className={classNames(
                'btn px-3 py-1 d-flex align-items-center justify-content-center',
                {
                  'bg-primary text-white': item == skip / limit,
                  'cursor-default': item === '...',
                  'mr-2': key !== pages.length - 1,
                }
              )}
              key={key}
              onClick={() => (item !== '...' ? onPaginate(item) : null)}
            >
              <span className="d-block">{item !== '...' ? item + 1 : item}</span>
            </button>
          );
        })}
        {pages.length > 5 && (
          <button
            className="btn py-1 d-flex align-items-center justify-content-center"
            style={{ width: 'min-content' }}
            onClick={() => onPaginate(skip / limit + 1)}
          >
            <span className="chevron right"></span>
          </button>
        )}
      </>
    );
  };

  const onPaginate = (page) => {
    if (
      page == skip / limit ||
      page < 0 ||
      page > paginationArray[paginationArray.length - 1]
    ) {
      return;
    }
    window && window.scrollTo(0, 0);
    history.push('/jobs', { skip: limit * page });
  };

  useEffect(() => {
    queryClient.invalidateQueries('search-jobs');
    refetchSearchJobs();
  }, [sortBy]);

  return (
    <>
      <div className="w-320px">
        <div className="row sticky-header">
          <div className="col-xs-12 col-md-12 col-xl-12">
            <div className="card sticky-header">
              <Filter
                view={true}
                setSkip={setSkip}
                onClearAll={() => onPaginate(0)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-75 cx-padding">
        <div className="row">
          <div className="col-xs-12 col-md-12 col-xl-12">
            <Gauge
              view="all"
              appliedFilters={appliedFilters}
              listLength={data?.totalJobs}
              showSearchaAndSortOptions={true}
            />

            {isLoading ? (
              <Loader className="mt-3" />
            ) : (
              <>
                <JobList
                  data={data?.jobs}
                  loading={isLoading}
                  to={selectJob}
                  userInfo={userInfo}
                  contactedJobs={contactedJobs}
                  saveJobFn={mutate}
                  appliedJobs={appliedJobs?.map((i) => i?.id)}
                  savingJobId={savingJobId}
                  savedJobs={
                    (!isLoadingSavedJobs && savedJobs?.map((job) => job.id)) || []
                  }
                  onSelect={(id, key) => selectJob(id, key)}
                  candidateId={userInfo.id}
                />
                {data?.totalJobs == 0 ? (
                  <h3 className="text-center text-primary my-4">No Jobs</h3>
                ) : (
                  ''
                )}
                {data && (
                  <div className="pagination d-flex align-items-center justify-content-center mb-4">
                    {!(data?.totalJobs <= limit) && renderPagination()}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

SearchPage.propTypes = {
  userInfo: PropTypes.any,
  contactedJobs: PropTypes.any,
};

SearchPage.defaultProps = {
  userInfo: null,
};

export default SearchPage;
