import { getUserOnboardingStatus, getUserProfile } from '../../api';

export const fetchUserProfileDetails = async ({ queryKey: [_] }) => {
  try {
    const { data } = await getUserProfile();
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchUserOnboardingStatus = async ({ queryKey: [_] }) => {
  try {
    const {
      data: { onboarded },
    } = await getUserOnboardingStatus();
    return onboarded;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
