import React from "react";
import Router from "./router";
import "./App.scss";
import Notification from "./components/notification";

function App() {
  return (
    <div>
      <Notification />
      <Router />
    </div>
  );
}

export default App;
