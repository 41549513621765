import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useAuth } from "../../context/auth-context";

const Home = () => {
  const auth = useAuth();
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    auth.getProfile((profile) => {
      setProfile(profile);
    });
  }, [auth]);

  if (!profile) {
    return <h1>Loading...</h1>;
  }

  return (
    <div>
      <h1>home</h1>
      <button onClick={auth.logout}>
        {auth.isAuthenticated() ? "logout" : "fail"}
      </button>
      <div>
        <h1>profile</h1>
        <>
          <p>{profile.name}</p>
          <p>{profile.nickname}</p>
          <img src={profile.picture} />
          <pre>{JSON.stringify(profile, null, 2)}</pre>
        </>
      </div>
    </div>
  );
};

Home.propTypes = {
  auth: PropTypes.any,
};

export default Home;
