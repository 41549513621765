import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { sortBy, escape, unescape, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import level from '../../images/Icons/level.svg';
import backBig from '../../images/Icons/back-big.svg';
import handsIcon from '../../images/Icons/hands.svg';
import tagIcon from '../../images/Icons/tag.svg';
import home from '../../images/Icons/home.svg';
import classNames from 'classnames';
import smile from '../../images/Icons/smile.svg';
import location from '../../images/Icons/location.svg';
import Loader from '../../components/loader';
import moment from 'moment';
import './styles.scss';
import { useMessageThreads } from '../../context/message-threads-context';
import { constructEncodedUrl } from '../../utils/helpers';
import AdaptiveTextArea from '../../components/adaptive-textarea';
import EmojiPicker from 'emoji-picker-react';
import { useApplyJob } from '../../context/apply-job-context';
import {
  useQuery as apolloUseQuery,
  useMutation as apolloUserMutation,
  useApolloClient,
} from '@apollo/client';
import { GET_MESSAGES, MARK_AS_READ, SEND_MESSAGE } from '../../utils/graphql';
import httpClient from '../../utils/httpClient';

const ApplicationContent = ({ candidate, jobId, setRefetchLoading }) => {
  const titleMapping = {
    designation: 'Designation',
    resume_key: 'CV',
    portfolio_link: 'Portfolio',
    git_profile_link: 'Github',
    linkedin_profile: 'Linkedin',
    name: 'Name',
    email: 'Email',
  };

  const contentKeys = Object.keys(titleMapping);
  const getCv = async () => {
    let response;
    setRefetchLoading(true);
    try {
      response = await httpClient.getFetch(
        `/job/${jobId}/candidate/${candidate.id}/resume`
      );
      window.open(response.url, '_blank');
      setRefetchLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const generateLink = (key, content) => {
    if (key === 'email') {
      return <a href={`mailto:${content}`}>{content}</a>;
    }
    if (key == 'designation') {
      return <span>{content}</span>;
    }
    return (
      <a
        href={!content?.match(/^https?:\/\//i) ? 'http://' + content : content}
        rel="noreferrer"
        target="_blank"
      >
        {content}
      </a>
    );
  };

  return (
    <div className="d-flex flex-column application-message text-truncate">
      {contentKeys.map((key, index) => {
        if (key == 'name') {
          return;
        }
        if (key == 'resume_key') {
          return (
            <p key={`${key}-${index}`} className="mb-0">
              {titleMapping[key]} :{' '}
              <a
                href="#"
                onClick={async () => {
                  let response = await getCv();
                }}
              >
                Resume
              </a>
            </p>
          );
        }
        if (candidate[key] && candidate[key].length > 0) {
          return (
            <p key={`${key}-${index}`} className="mb-0">
              {titleMapping[key]} :{' '}
              {/* <a href={candidate[key]}>{candidate[key]}</a> */}
              {generateLink(key, candidate[key])}
            </p>
          );
        }
      })}
    </div>
  );
};

ApplicationContent.propTypes = {
  jobId: PropTypes.string,
  candidate: PropTypes.any,
  setRefetchLoading: PropTypes.func,
};

const MessageRow = React.forwardRef((props, ref) => {
  const {
    userType,
    content,
    content_type,
    setRefetchLoading,
    created_at,
    from,
    userInfo: { id, name },
    sender_id,
    candidate,
    recruiter,
    applied,
    jobId,
  } = props;
  const userImage = () => {
    /**
     * If it is me, show my name
     * If it is the rec/candidate, for rec, show their name, for candidate, show their name or designation
     */
    let name = userName();
    return (
      <span
        className="align-self-top mr-2 d-flex align-items-center justify-content-center rounded-circle text-center text-white"
        style={{
          width: 32,
          minWidth: 32,
          height: 32,
          backgroundColor: userType == 'recruiter' ? '#f18343' : '#43adf1',
        }}
      >
        {name[0]?.toUpperCase()}
      </span>
    );
  };

  const userName = () => {
    let name = '';
    if (sender_id === id) {
      name = 'You';
    } else {
      if (userType === 'recruiter') {
        name = applied ? candidate.name : candidate.designation;
      } else {
        name = recruiter.name;
      }
    }
    return name;
  };

  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;

  function detectURLs(message) {
    return content?.match(urlRegex);
  }

  const urlify = (text) => {
    const links = detectURLs(text);
    let newText = text;
    let newMessageObject = {};

    links?.map((link, key) => {
      newText = newText?.replace(link, sender_id);
    });

    newText = newText?.split(sender_id);

    links?.forEach((link, key) => {
      newMessageObject[`text-${key}`] = newText[key];
      newMessageObject[`link-${key}`] = link;
      if (key == links?.length - 1) {
        newMessageObject[`text-${key + 1}`] = newText[key + 1];
      }
    });

    return Object.keys(newMessageObject)?.map((key, idx) => {
      if (key?.includes('text')) {
        return <span key={idx}>{unescape(newMessageObject[key])}</span>;
      } else if (key?.includes('link')) {
        return (
          <a
            href={
              newMessageObject[key]?.match(/^https?:\/\//i)
                ? newMessageObject[key]
                : `http://${newMessageObject[key]}`
            }
            key={idx}
            target="_blank"
            rel="noreferrer"
            style={{ lineBreak: 'anywhere' }}
          >
            {newMessageObject[key]}
          </a>
        );
      }
    });
  };

  return (
    <div className="d-flex mb-3" ref={ref}>
      {userImage()}
      <div className="d-flex flex-column">
        <p className="font-weight-bold mb-0">
          {userName()}{' '}
          <span className="message-date">
            {created_at && moment.utc(created_at).local().format('MMM DD HH:mm')}
          </span>
        </p>
        {content_type === 'text' && (
          <p className="mb-0 preserve-line" style={{ wordBreak: 'break-word' }}>
            {detectURLs(content)?.length > 0 ? urlify(content) : unescape(content)}
          </p>
        )}
        {content_type === 'application' && (
          <ApplicationContent
            jobId={jobId}
            candidate={candidate}
            setRefetchLoading={setRefetchLoading}
          />
        )}
      </div>
    </div>
  );
});

MessageRow.displayName = 'MessageRow';

MessageRow.propTypes = {
  userInfo: PropTypes.any,
  userType: PropTypes.string,
  content: PropTypes.string,
  content_type: PropTypes.string,
  created_at: PropTypes.string,
  jobId: PropTypes.string,
  candidateId: PropTypes.string,
  name: PropTypes.string,
  from: PropTypes.string,
  sender_id: PropTypes.string,
  candidate: PropTypes.any,
  setRefetchLoading: PropTypes.func,
  recruiter: PropTypes.any,
  applied: PropTypes.bool,
};
const MessageDetails = ({
  userInfo,
  candidateId,
  setCandidateId,
  setResetThreads,
  setRecruiterId,
}) => {
  const {
    setJobId,
    threadId,
    jobId,
    setCandidateData,
    setThreadId,
    setJobData,
    candidateData,
    jobData,
  } = useMessageThreads();
  const { toggleApplyJob, showMessages } = useApplyJob();
  const [showPicker, setShowPicker] = useState(false);
  const [refetchLoading, setRefetchLoading] = useState(false);
  const [textAreaFocusKey, setTextAreaFocusKey] = useState(0);
  const [thread, setThread] = useState(
    jobData
      ? {
          job: {
            ...jobData,
            locations: jobData.location
              ? jobData.location.map((item) => item.name)
              : jobData.locations.map((item) => item.name),
            employment_options: jobData.employmentOptions
              ? jobData.employmentOptions.map((item) => item.name)
              : jobData.employment_options.map((item) => item.name),
            company_name: jobData.companyName || jobData.company_name,
          },
        }
      : candidateData
      ? {
          candidate: {
            ...candidateData,
            employment_options: candidateData.employment_options.map(
              (item) => item.name
            ),
            locations: candidateData.locations.map((item) => item.name),
          },
        }
      : {}
  );
  const [messages, setMessages] = useState([]);
  const client = useApolloClient();

  const [markThreadRead] = apolloUserMutation(MARK_AS_READ, {
    variables: {
      thread_id: threadId,
    },
  });
  const { loading, error, data } = apolloUseQuery(GET_MESSAGES, {
    variables: {
      thread_id: threadId,
    },
    pollInterval: 5000,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      let { candidate, job, thread_id } = data.getThread;
      const transform = (items) => (items ? items.map((item) => item.name) : []);
      setThread({
        ...data.getThread,
        candidate: {
          ...candidate,
          locations: transform(candidate.locations),
          employment_options: transform(candidate.employment_options),
        },
        job: {
          ...job,
          locations: transform(job.locations),
          employment_options: transform(job.employment_options),
        },
      });
      const sortedMessages = sortBy(data.getThread.messages.items, ['created_at']);
      markThreadRead({
        variables: {
          thread_id,
        },
      });
      setMessages(sortedMessages);
    }
  }, [data]);

  const [sendMessage, { loading: sendMessageLoading, error: sendMessageError }] =
    apolloUserMutation(SEND_MESSAGE, {
      refetchQueries: [GET_MESSAGES],
      variables: {
        thread_id: threadId,
        content: '',
        content_type: 'text',
      },
    });
  const ref = useRef();
  const titleRef = useRef();
  const [msg, setMsg] = useState('');
  const [rows, setRows] = React.useState(1);
  const [threadContainerHeight, setThreadContainerHeight] = useState('big');

  useEffect(() => {
    ref.current && ref.current.scrollIntoView();
    if (titleRef.current) {
      titleRef.current.offsetHeight > 45 && setThreadContainerHeight('small');
    }
  }, [titleRef.current]);

  useEffect(() => {
    ref.current && ref.current.scrollIntoView();
  }, [messages.length]);

  const showDetails = (showJob = false) => {
    if (!showJob) {
      window.open(
        constructEncodedUrl(
          { id: userInfo.user_type == 'recruiter' ? candidateId : jobId },
          userInfo.user_type == 'recruiter' ? 'candidate' : 'job'
        )
      );
    } else {
      return constructEncodedUrl({ id: data?.getThread?.job?.id }, 'job');
    }
  };

  const send = (e) => {
    e.preventDefault();
    if (msg?.trim() == '') {
      return;
    }
    sendMessage({
      variables: {
        content: escape(msg?.trimEnd()),
        content_type: 'text',
        thread_id: threadId,
      },
    });
    setMsg('');
  };

  const onBackClick = async () => {
    setRefetchLoading(true);
    await client.refetchQueries({
      include: ['GetAllUnreadThreads'],
    });
    setResetThreads(true);
    setRefetchLoading(false);
    setCandidateId(null);
    setRecruiterId(null);
    setCandidateData(null);
    setThreadId(null);
    setJobData(null);
    setJobId(null);
  };

  const keyPressHandler = (e) => {
    setRows(1);
    if ((e.keyCode === 13 || e.which == 13) && e.shiftKey == false) {
      send(e);
    }
  };

  const getContainerHeight = () => {
    if (threadContainerHeight == 'small') {
      if (userInfo?.user_type == 'candidate') {
        return 7;
      } else {
        return 7.5;
      }
    } else if (threadContainerHeight == 'big') {
      if (userInfo?.user_type == 'candidate') {
        return 5.5;
      } else {
        return 6;
      }
    }
  };

  return (
    <>
      <div className="slide-container right-slide bg-white">
        {(loading || !showMessages || refetchLoading) && (
          <Loader className="messages-loader" />
        )}
        {(!loading || jobData || candidateData) && showMessages && !isEmpty(thread) && (
          <>
            {' '}
            <div className="row card-border-bottom mb-0 d-flex align-items-center">
              <div
                className={classNames(
                  'col-md-12 col text-md-center text-xl-left pr-0',
                  {
                    'col-xl-6': userInfo.user_type == 'candidate',
                    'd-flex col-xl-8': userInfo.user_type == 'recruiter',
                  }
                )}
                onClick={() => onBackClick()}
              >
                <img
                  className="slide-bt-back btn-ab-left cursor-pointer mt-1"
                  src={backBig}
                />
                <h5
                  className={`slide-header job-title pl-large${
                    userInfo.user_type == 'recruiter' ||
                    (userInfo.user_type == 'candidate' &&
                      thread?.job?.status == 'published')
                      ? ' mb-0'
                      : ' mb-4'
                  }`}
                  onClick={(e) => e.stopPropagation()}
                  style={{
                    lineBreak:
                      !isEmpty(thread) && !thread?.job?.title?.includes(' ')
                        ? 'anywhere'
                        : '',
                  }}
                  ref={titleRef}
                >
                  {!isEmpty(thread) &&
                    (userInfo.user_type == 'candidate'
                      ? thread?.job?.title
                      : thread?.candidate?.name || thread?.candidate?.designation)}
                </h5>
              </div>
              <div
                className={classNames('col-md-12 col text-md-center text-xl-right', {
                  'col-xl-6': userInfo.user_type == 'candidate',
                  'col-xl-4': userInfo.user_type == 'recruiter',
                })}
              >
                <div
                  className={classNames(
                    'confirmation-buttons messages d-flex align-items-center',
                    { 'justify-content-around': userInfo.user_type == 'candidate' }
                  )}
                >
                  {(userInfo.user_type == 'recruiter' ||
                    (userInfo.user_type == 'candidate' &&
                      thread?.job?.status == 'published')) && (
                    <button
                      type="button"
                      className={classNames(
                        'btn btn-outline btn-primary btn-rounded ml-2',
                        { 'ml-4': userInfo.user_type == 'recruiter' }
                      )}
                      data-dismiss="modal"
                      onClick={(e) => e.target.blur() + showDetails(false)}
                    >
                      See Details
                    </button>
                  )}
                  {userInfo.user_type == 'candidate' &&
                    thread?.job?.status == 'published' &&
                    (!isEmpty(thread) && thread?.applied ? (
                      <button
                        type="button"
                        className="btn btn-primary btn-rounded text-white"
                        disabled={true}
                      >
                        Applied
                      </button>
                    ) : (
                      <button
                        onClick={() => toggleApplyJob(thread?.job, userInfo)}
                        type="button"
                        className="btn btn-primary btn-rounded text-white"
                      >
                        Apply
                      </button>
                    ))}
                </div>
              </div>
              <div className="col-xs-12 col-md-12 col-lg-12">
                {userInfo.user_type == 'recruiter' && (
                  <div
                    className="pl-large d-flex align-items-center"
                    style={{ fontSize: '13px' }}
                  >
                    <span className="text-grey">Position:</span>
                    <Link
                      className="text-truncate cursor-pointer d-block ml-2"
                      target="_blank"
                      to={() => showDetails(true)}
                      style={{
                        color: 'blue',
                        textDecoration: 'underline',
                        maxWidth: '75%',
                      }}
                    >
                      {!isEmpty(thread) && thread?.job?.title}
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div
              className="d-flex flex-column justify-content-between"
              style={{
                height: `calc(100vh - ${getContainerHeight()}rem)`,
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                showPicker && setShowPicker(false);
              }}
            >
              <div
                className="mesages-container py-1"
                style={{
                  maxHeight: `calc(100vh - 168px${rows && ' - ' + rows + '*24px'})`,
                }}
              >
                {data &&
                  messages?.map((message, index) => (
                    <MessageRow
                      key={index}
                      jobId={jobId}
                      candidateId={candidateId}
                      userType={userInfo.user_type}
                      from={message?.from}
                      setRefetchLoading={setRefetchLoading}
                      content={message?.content}
                      name={
                        userInfo.user_type == 'recruiter'
                          ? userInfo.name
                          : data?.getThread?.applied
                          ? userInfo.name || userInfo?.designation
                          : userInfo?.designation
                      }
                      userInfo={userInfo}
                      content_type={message?.content_type}
                      ref={ref}
                      created_at={message?.created_at}
                      sender_id={message?.sender_id}
                      applied={thread?.applied}
                      candidate={thread?.candidate}
                      recruiter={thread?.recruiter}
                    />
                  ))}
              </div>
              {showPicker && (
                <div
                  className="chat-emoji-container"
                  onClick={(e) => e.preventDefault() + e.stopPropagation()}
                >
                  <EmojiPicker
                    onEmojiClick={(event, emojiObject) => {
                      setMsg((prev) => prev + emojiObject.emoji);
                      setTextAreaFocusKey((prev) => prev + 1);
                      setShowPicker(!showPicker);
                    }}
                  />
                </div>
              )}
              <form
                className={`mt-0${userInfo.user_type == 'recruiter' ? ' mb-2' : ''}`}
                onSubmit={send}
                onKeyPress={keyPressHandler}
              >
                <div className="d-flex align-items-start position-relative">
                  <AdaptiveTextArea
                    {...{ autofocus: true }}
                    msg={msg}
                    setMsg={setMsg}
                    getRows={(data) => setRows(data)}
                    onClick={() => showPicker && setShowPicker(false)}
                    rows={rows}
                    focusKey={textAreaFocusKey}
                  />
                  <img
                    className="emoji-toggler position absolute"
                    src={smile}
                    alt="smile"
                    onClick={() => setShowPicker(!showPicker)}
                  />
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </>
  );
};

MessageDetails.propTypes = {
  applyJob: PropTypes.func,
  job: PropTypes.object,
  messages: PropTypes.array,
  sendMessages: PropTypes.func,
  setResetThreads: PropTypes.func,
  isApplied: PropTypes.bool,
  sendingMessage: PropTypes.bool,
  userInfo: PropTypes.any,
  candidateId: PropTypes.string,
  recruiterId: PropTypes.string,
  setCandidateId: PropTypes.func,
  setRecruiterId: PropTypes.func,
};

export default MessageDetails;
