const initialState = {
  skills: [],
  languages: [],
  employementOptions: [],
  geographies: [],
  loading: false,
  error: false,
};

export default function entities(state = initialState, action) {
  switch (action.type) {
    case 'GET_SKILLS':
      return { ...state, loading: true, error: false };
    case 'GET_SKILLS_SUCCESS':
      return { ...state, skills: action.payload.data, loading: false, error: false };
    case 'GET_SKILLS_FAIL':
      return { ...state, loading: false, error: true };

    case 'GET_GEOGRAPHIES':
      return { ...state, loading: true, error: false };
    case 'GET_GEOGRAPHIES_SUCCESS':
      return {
        ...state,
        geographies: action.payload.data,
        loading: false,
        error: false,
      };
    case 'GET_GEOGRAPHIES_FAIL':
      return { ...state, loading: false, error: true };

    case 'GET_EMPLOYEMENT_OPTIONS':
      return { ...state, loading: true, error: false };
    case 'GET_EMPLOYEMENT_OPTIONS_SUCCESS':
      return {
        ...state,
        employementOptions: action.payload.data,
        loading: false,
        error: false,
      };
    case 'GET_EMPLOYEMENT_OPTIONS_FAIL':
      return { ...state, loading: false, error: true };

    case 'GET_LANGUAGES':
      return { ...state, loading: true, error: false };
    case 'GET_LANGUAGES_SUCCESS':
      return {
        ...state,
        languages: action.payload.data,
        loading: false,
        error: false,
      };
    case 'GET_LANGUAGES_FAIL':
      return { ...state, loading: false, error: true };
    case 'CLEAN_ENTITIES':
      return { ...initialState };
    default:
      return state;
  }
}
