import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import { createJob } from './service';
import { toast } from 'react-toastify';
import Loader from '../../components/loader';
import { Form } from 'react-bootstrap';
import { JobForm } from './form/jobForm';
import { useForm } from 'react-hook-form';

export const CreateJob = ({
  onClose,
  setLoading,
  userInfo,
  isLoading,
  setRecentJobsUpdated,
  recentUpdated,
}) => {
  const {
    register,
    formState: { errors },
    control,
    trigger,
    getValues,
  } = useForm({
    defaultValues: {
      title: '',
      experience: '',
      salary: 1000,
      description: '',
      show_salary: false,
      employment_options: [],
      skills: [],
      languages: [],
      locations: [],
    },
  });

  const queryClient = useQueryClient();

  const { mutate } = useMutation(createJob, {
    onSuccess: async (data) => {
      await queryClient.invalidateQueries('get-jobs');
      setLoading(false);
      onClose(data);
      toast.success('Successfully Posted');
      if (setRecentJobsUpdated !== null) {
        setRecentJobsUpdated(recentUpdated + 1);
      }
    },
    onError: () => {
      setLoading(false);
      toast.error("Couldn't Post");
    },
    useErrorBoundary: (error) => {
      return error?.response?.status === 401;
    },
    retry: (count, error) => {
      if (error?.response?.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    const {
      title,
      experience,
      salary,
      description,
      show_salary,
      employment_options,
      skills,
      languages,
      locations,
    } = getValues();

    await trigger([
      'title',
      'experience',
      'salary',
      'description',
      'skills',
      'employment_options',
      'languages',
      'locations',
    ]).then((noError) => {
      if (noError) {
        setLoading(true);
        mutate({
          title,
          experience: parseInt(experience),
          salary: parseInt(salary),
          description,
          show_salary,
          employment_options,
          skills,
          languages,
          locations,
          company_website: userInfo.company_website || '',
          company_name: userInfo.company_name || '',
          qualifications: '',
          about_company: userInfo.about_company || '',
        });
      }
    });
  };

  return (
    <>
      {isLoading && (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: 'calc( 100vh - 5rem)' }}
        >
          <Loader />
        </div>
      )}
      <div className="create-job py-3">
        {!isLoading && (
          <>
            <div className="card py-2 px-3 mb-3">
              <h2 className="mb-1">Job Details</h2>
              <p className="mb-0">
                Please describe your position in great details to attract more
                candidates
              </p>
            </div>
            <div className="card py-2 px-3">
              <Form id="createJob" onSubmit={onSubmit}>
                <JobForm control={control} errors={errors} register={register} />
              </Form>
            </div>
            <div className="d-flex align-items-center mt-4">
              <button
                type="button"
                form="createJob"
                className="btn btn-outline btn-primary btn-rounded px-4 py-1"
                onClick={(e) => {
                  e.preventDefault();
                  onClose();
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                form="createJob"
                className="btn btn-primary btn-rounded text-white px-4 py-1 ml-auto"
              >
                Create job
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

CreateJob.propTypes = {
  userInfo: PropTypes.any,
  recentUpdated: PropTypes.any,
  setRecentJobsUpdated: PropTypes.any,
  onClose: PropTypes.func,
  setLoading: PropTypes.func,
  isLoading: PropTypes.bool,
};

CreateJob.defaultProps = {
  userInfo: null,
  onClose: null,
  setRecentJobsUpdated: null,
  setLoading: null,
  isLoading: false,
};
