import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams, Redirect } from 'react-router';
import { saveJob } from '../dashboard/service';
import JobDetailCard from '../../components/job-detail-card';
import PropTypes from 'prop-types';
import DetailSidebar from '../../components/detail-sidebar/detail-sidebar';
import Loader from '../../components/loader';
import { fetchJob } from '../../hoc/services/get-job';
import { constructEncodedUrl, deconstructEncodedUrl } from '../../utils/helpers';
import { useSavedJobs } from '../../hooks/useSavedJobs';
import { useAppliedJobs } from '../../hooks/useAppliedJobs';

const SavedJobDetail = (props) => {
  const { userInfo } = props;

  if (userInfo?.user_type == 'recruiter') {
    return <Redirect to="/candidates" />;
  }
  const history = useHistory();
  const { id } = useParams();
  const decompressed = deconstructEncodedUrl(id);
  const [skip, setSkip] = useState(
    history.location.state?.from || decompressed?.filters?.from || 0
  );
  const [limit] = useState(decompressed.filters.size);
  const [savingJobId, setSavingJobId] = useState(null);
  const [unAvailablePost] = useState(decompressed?.filters?.unavilablePost || false);
  const { appliedJobs } = useAppliedJobs();

  const {
    jobs: savedJobs,
    refetch: refetchSavedJobs,
    isFetching: SavedJobsFetching,
    isLoading,
  } = useSavedJobs();

  const { mutate } = useMutation(saveJob, {
    onSettled: () => refetchSavedJobs(),
    onMutate: (id) => setSavingJobId(id),
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const { data: job, isLoading: jobLoading } = useQuery(
    ['fetch-job', { id: decompressed.id }],
    fetchJob,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      useErrorBoundary: (error) => {
        return error.response.status === 401;
      },
      retry: (count, error) => {
        if (error.response.status === 401) {
          return false;
        } else if (count <= 3) {
          return true;
        } else {
          false;
        }
      },
    }
  );

  const data = {
    jobs: savedJobs?.slice(skip, skip + limit),
    totalJobs: savedJobs?.length,
  };

  const inArray = (id, array) => {
    return array?.find((key) => key === id) ? true : false;
  };

  const onPaginate = (type) => {
    if (type === 'next' && !(data?.jobs?.length < limit)) {
      history.push(history.location.pathname, { from: skip + limit });
    } else if (type === 'prev' && skip) {
      history.push(history.location.pathname, { from: skip - limit });
    } else {
      return;
    }
  };

  const onJobSelected = (jobId, params) => {
    return constructEncodedUrl(
      {
        id: jobId,
        filters: { ...decompressed.filters, from: skip, size: limit, ...params },
        view: decompressed?.view,
      },
      'job/saved'
    );
  };

  useEffect(() => {
    if (!SavedJobsFetching) {
      if (savingJobId !== null) {
        setSavingJobId(null);
      }
    }
  }, [SavedJobsFetching]);

  useEffect(() => {
    if (history.location.state?.from === 0) {
      setSkip(0);
    }
  }, [history.location.state?.from]);

  if (isLoading) {
    return (
      <div className="detail-loader">
        <Loader />
      </div>
    );
  }

  return (
    <>
      {decompressed.filters != undefined && (
        <div className="w-320px">
          <div className="row sticky-header">
            <div className="col-xs-12 mb-2 col-md-12 col-xl-12">
              <div className="sticky-header">
                <DetailSidebar
                  onPaginate={onPaginate}
                  greyOut={true}
                  header="Saved Jobs"
                  jobType="saved"
                  bodyKey="description"
                  data={{ ...data, skip, limit }}
                  selectedItemId={decompressed.id}
                  onSelectItem={onJobSelected}
                  onNavigateBack={() =>
                    history.push('/jobs/saved', {
                      skip: decompressed.filters.from,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`w-${
          decompressed.filters != undefined ? '75' : '100'
        } cx-padding`}
      >
        {jobLoading && (
          <div className="d-flex flex-column h-100">
            <Loader className="h-100" />
          </div>
        )}
        {unAvailablePost && !jobLoading && (
          <div className="card w-100 text-left p-4">
            <h4 className="bb">About Job</h4>
            <p className="mb-0 text-grey">This position is no longer available</p>
          </div>
        )}
        {!jobLoading && !unAvailablePost && (
          <JobDetailCard
            userInfo={userInfo}
            job={job}
            mutate={mutate}
            savedJobs={savedJobs?.map((i) => i.id)}
            appliedJobs={appliedJobs}
            applied={inArray(
              decompressed.id,
              appliedJobs?.map((i) => i?.id)
            )}
            savingJobId={savingJobId}
          />
        )}
      </div>
    </>
  );
};

SavedJobDetail.propTypes = {
  userInfo: PropTypes.any,
};

SavedJobDetail.defaultProps = {
  userInfo: null,
};

export default SavedJobDetail;
