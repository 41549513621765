import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  validateLinkedInUrl,
  validateUrl,
  validateGitHubUrl,
} from '../../utils/helpers';
import { updateUser } from './service';
import upload from '../../images/Icons/upload.svg';
import slideClose from '../../images/Icons/close-black.svg';
import { allowedTypes } from '../../utils/constants';

const Details = ({ userInfo, setLoading, published }) => {
  const [fileName, setFileName] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const [dragOver, setDragOver] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [params, setParams] = React.useState({
    firstName: userInfo?.name?.split(' ')[0] || '',
    lastName: userInfo?.name?.split(' ')[1] || '',
    email: userInfo?.email || '',
    phone: userInfo?.phone || '',
    git: userInfo?.git_profile_link || '',
    linkedIn: userInfo?.linkedin_profile || '',
    portfolio: userInfo?.portfolio_link || '',
    file: null,
  });

  const queryClient = useQueryClient();

  const { mutate } = useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('get-auth0-profile');
      setLoading(false);
      toast.success('Updated Succesfully');
    },
    onError: () => {
      setLoading(false);
      toast.error("Couldn't Update");
    },
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const preventCharacters = (e) => {
    let charCode = e.which ? e.which : e.keyCode;

    if (
      charCode == 69 ||
      charCode == 40 ||
      charCode == 38 ||
      charCode == 110 ||
      charCode == 109 ||
      charCode == 107 ||
      charCode == 190 ||
      charCode == 189 ||
      charCode == 187 ||
      charCode == 188
    ) {
      e.preventDefault();
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let resume = null;
    if (params.file) {
      resume = await toBase64(params.file[0]);
    }
    let queryParams = {
      ...userInfo,
      name: [params.firstName.trim(), params.lastName.trim()]
        .filter(Boolean)
        .join(' '),
      phone: params.phone,
      linkedin_profile: params.linkedIn,
      git_profile_link: params.git,
      portfolio_link: params.portfolio,
      email: params.email,
      profile_published: published,
    };
    if (!params.file && update) queryParams = { ...queryParams, resume_doc: '' };

    if (resume) queryParams = { ...queryParams, resume_doc: resume };

    let validationErrors = errors;

    if (
      (params.linkedIn && !validateLinkedInUrl(params.linkedIn)) ||
      (params.git && !validateGitHubUrl(params.git)) ||
      (params.portfolio && !validateUrl(params.portfolio))
    ) {
      setLoading(false);
      if (
        params.linkedIn &&
        !validateLinkedInUrl(params.linkedIn) &&
        !errors.includes('linkedIn')
      ) {
        validationErrors = validationErrors.concat(['linkedIn']);
      }
      if (
        params.git &&
        !validateGitHubUrl(params.git) &&
        !errors.includes('gitHub')
      ) {
        validationErrors = validationErrors.concat(['gitHub']);
      }
      if (
        params.portfolio &&
        !validateUrl(params.portfolio) &&
        !errors.includes('portfolio')
      ) {
        validationErrors = validationErrors.concat(['portfolio']);
      }

      setErrors(validationErrors);

      return;
    }

    mutate({ ...queryParams });
  };

  const handleResumeUpload = (e) => {
    if (allowedTypes.candidate.resume.indexOf(e.target?.files[0]?.type) > -1) {
      e.target?.files[0]?.name && setFileName(e.target.files[0].name);
      setParams((prevState) => ({
        ...prevState,
        file: e.target.files,
      }));
      setUpdate(false);
    } else {
      toast.error('only .pdf format is allowed');
    }
  };

  return (
    <div className="profile-details w-100">
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <h3 className="mb-3">Tell us more about yourself</h3>
          <h5 className="text-grey">
            This information stays private untill you share it with recruiters by
            applying to a job
          </h5>
        </div>
      </div>
      <form id="details" className="form mt-2" onSubmit={onSubmit}>
        <div className="w-75">
          <div className="row">
            <div className="form-group col-6 mb-0">
              <label className="form-label">First Name</label>
              <input
                type="text"
                className="form-control"
                value={params.firstName}
                onChange={(e) =>
                  setParams((prevState) => ({
                    ...prevState,
                    firstName: e.target.value,
                  }))
                }
              />
            </div>
            <div className="form-group col-6 mb-0">
              <label className="form-label">Last Name</label>
              <input
                type="text"
                className="form-control"
                value={params.lastName}
                onChange={(e) =>
                  setParams((prevState) => ({
                    ...prevState,
                    lastName: e.target.value,
                  }))
                }
              />
            </div>
            <div className="form-group col-6 mb-0">
              <label className="form-label">Email</label>
              <input
                type="text"
                className="form-control"
                value={params.email}
                onChange={(e) =>
                  setParams((prevState) => ({
                    ...prevState,
                    email: e.target.value,
                  }))
                }
                disabled
              />
            </div>
            <div className="form-group position-relative col-6 mb-0">
              <label className="form-label">Phone</label>
              <div className="position-relative">
                <input
                  type="number"
                  onKeyDown={preventCharacters}
                  className="form-control"
                  onWheel={(e) => e.target.blur()}
                  value={params.phone}
                  onChange={(e) =>
                    setParams((prevState) => ({
                      ...prevState,
                      phone: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="col-12">
              <h3>Online Presence</h3>
            </div>
            <div className="form-group col-6 mb-0">
              <label className="form-label">LinkedIn</label>
              <input
                type="text"
                className="form-control"
                value={params.linkedIn}
                onChange={(e) => {
                  if (e.target.value == '' || validateLinkedInUrl(e.target.value)) {
                    setErrors(errors.filter((i) => i !== 'linkedIn'));
                  }
                  setParams((prevState) => ({
                    ...prevState,
                    linkedIn: e.target.value,
                  }));
                }}
              />
              {errors.includes('linkedIn') && (
                <span className="d-block invalid-feedback text-danger pb-1">
                  Invalid link
                </span>
              )}
            </div>
            <div className="form-group col-6 mb-0">
              <label className="form-label">Git</label>
              <input
                type="text"
                className="form-control"
                value={params.git}
                onChange={(e) => {
                  if (e.target.value == '' || validateGitHubUrl(e.target.value)) {
                    setErrors(errors.filter((i) => i !== 'gitHub'));
                  }
                  setParams((prevState) => ({
                    ...prevState,
                    git: e.target.value,
                  }));
                }}
              />
              {errors.includes('gitHub') && (
                <span className="d-block invalid-feedback text-danger pb-1">
                  Invalid link
                </span>
              )}
            </div>
            <div className="form-group col-6 mb-0">
              <label className="form-label">Portfolio</label>
              <input
                type="text"
                className="form-control"
                value={params.portfolio}
                onChange={(e) => {
                  if (e.target.value == '' || validateUrl(e.target.value)) {
                    setErrors(errors.filter((i) => i !== 'portfolio'));
                  }
                  setParams((prevState) => ({
                    ...prevState,
                    portfolio: e.target.value,
                  }));
                }}
              />
              {errors.includes('portfolio') && (
                <span className="d-block invalid-feedback text-danger pb-1">
                  Invalid link
                </span>
              )}
            </div>
          </div>
          <h3>Upload you resume/CV</h3>
          <h6 className="text-grey">Upload your latest resume</h6>
          <div
            onDrop={() => {
              setDragOver(false);
            }}
            onDragEnter={() => {
              setDragOver(true);
            }}
            onDragLeave={() => {
              setDragOver(false);
            }}
            className={classNames(
              'dash-border position-relative orange-border p-3 d-flex flex-column justify-content-center align-items-center',
              { 'drag-over': dragOver }
            )}
          >
            {!update && (userInfo?.resume_link || fileName) && (
              <div
                className="d-flex align-items-center position-relative overflow-hidden border-grey"
                style={{
                  zIndex: 5,
                  cursor: fileName == null ? 'pointer' : 'default',
                  borderRadius: '0.5rem',
                  width: '14rem',
                }}
                onClick={() => {
                  if (fileName == null) {
                    window.open(userInfo?.resume_link, '_blank');
                  }
                }}
              >
                <div className="download-cv position-absolute d-flex align-items-center justify-content-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#666666"
                    stroke="#666666"
                    strokeWidth="1"
                    className="bi bi-download mr-2  text-grey"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                  </svg>
                  <span className="d-block bb text-grey">Download</span>
                </div>
                <span
                  className="d-block w-25 p-4 bb d-flex align-items-center justify-content-center text-white"
                  style={{ backgroundColor: '#cc1016', letterSpacing: '1px' }}
                >
                  PDF
                </span>
                <span className="d-block ml-3">
                  {fileName || userInfo?.resume_name || 'Resume'}
                </span>
                <img
                  className="close-icon ml-auto p-2 mr-2"
                  onClick={(e) => {
                    e.stopPropagation();
                    setUpdate(true);
                    setParams({ ...params, file: null });
                  }}
                  src={slideClose}
                  alt="close"
                />
              </div>
            )}
            {(!userInfo?.resume_link || update) && (
              <React.Fragment>
                <img src={upload} />
                <div className="m-0">
                  <div>
                    <input
                      className="upload-zone"
                      id="resume"
                      accept=".pdf"
                      name="resume"
                      type="file"
                      onChange={handleResumeUpload}
                      defaultValue={params?.field?.value}
                    />
                  </div>
                  <a style={{ zIndex: '11111' }} href="#">
                    Upload your resume
                  </a>{' '}
                  or drag and drop it here
                </div>
                <p className="m-0">(only .pdf format is allowed)</p>
              </React.Fragment>
            )}
          </div>
        </div>
      </form>
      <div className="w-75">
        <div className="d-flex justify-content-end mt-4">
          <button
            className="btn btn-primary btn-rounded text-white"
            form="details"
            id="saveProfileButton"
          >
            Save Profile
          </button>
        </div>
      </div>
    </div>
  );
};

Details.propTypes = {
  published: PropTypes.bool,
  userInfo: PropTypes.any,
  setLoading: PropTypes.func,
};

export default Details;
