import React from 'react';
import Form from 'react-bootstrap/Form';
import { useDispatch } from 'react-redux';
import logo from '../../images/logo.png';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import { useDimensions } from '../../hooks/useDimensions';

import { Spinner } from 'react-bootstrap';
import { useAuth } from '../../context/auth-context';
import { useForm } from 'react-hook-form';
import { setNotification } from '../../store/actions/notificationActions';
import logoColored from '../../images/JobTerix-full.svg';
import ForgotPassSuccess from '../../images/forgot-pass-success.png';
const ForgotPassword = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const [validated, setValidated] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [sendSuccess, setSuccessSendSuccess] = React.useState(false);
  const dimensions = useDimensions();
  const [emailDisplay, setEmailDisplay] = React.useState('');
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const onSubmit = ({ email }) => {
    if (!submitting) {
      setSubmitting(true);
      setEmailDisplay(email);
      auth
        .forgotPassword(email)
        .then(() => {
          setSuccessSendSuccess(true);
        })
        .catch(() => {
          dispatch(
            setNotification({ type: 'error', message: 'Error in sending request' })
          );
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };
  if (sendSuccess) {
    return (
      <>
        <div className="d-lg-flex vh-100">
          <div className="w-50 jtx-signup-img p-3">
            <img src={logo} alt="JobTerix" />
          </div>
          <div className="d-flex flex-column align-item-xl-start align-items-center justify-content-center d-lg-none responsive-logo p-4">
            <img src={logoColored} alt="JobTerix" />
          </div>
          <div className="d-lg-flex flex-column align-items-xl-start justify-content-around w-100 p-3 d-none">
            <div className="d-flex flex-column ml-4">
              <h1>SUCCESS!</h1>
              <p style={{ color: '#333333' }}>
                {` We've just sent you an email with instructions how to reset your
                password.`}
              </p>
              <div className="w-100 d-flex justify-content-between">
                <button
                  onClick={() => {
                    history.push('./login');
                  }}
                  type="button"
                  className="btn sw-btn-default font-weight-bold btn-rounded p-0"
                >
                  BACK TO LOGIN PAGE
                </button>
              </div>
            </div>
            <div className="ml-4"></div>
          </div>
          <div className="d-flex flex-column justify-content-between text-center w-100 p-3 d-lg-none d-xl-none">
            <div>
              <h1 className="forgot-pass-mobile">
                Forgot <span className="d-none d-lg-inline-block">your</span>{' '}
                password<span className="d-none d-lg-inline">?</span>
              </h1>
              <div className="d-lg-none">
                <img src={ForgotPassSuccess} alt="Success" />
              </div>
              <p
                style={{ color: '#333333' }}
                className="d-lg-none lg-none xl-none mt-2 txt-mobile"
              >
                Your email is on the way
              </p>
              <p className="d-lg-none lg-none xl-none forgot-pass-success-text">
                Check your email <b>{emailDisplay}</b> and follow the instructions to
                reset your password
              </p>
            </div>
            <div className="w-100 justify-content-center">
              <button
                onClick={() => {
                  history.push('./login');
                }}
                style={{
                  marginTop:
                    dimensions?.width < 576
                      ? 'clamp(1rem, calc( 100vh - 620px ), calc( 100vh - 620px ))'
                      : '1rem',
                }}
                type="button"
                className="w-100 d-flex justify-content-center btn sw-btn-next"
              >
                <b>Back to Login</b>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="d-lg-flex vh-100">
        <div className="w-50 jtx-signup-img p-3 d-none d-md-block">
          <img src={logo} alt="JobTerix" />
        </div>
        <div className="d-flex flex-column align-item-xl-start align-items-center justify-content-center d-md-none responsive-logo p-4">
          <img src={logoColored} alt="JobTerix" />
        </div>
        <div className="d-flex flex-column align-items-xl-start justify-content-around w-100 p-3">
          <div className="d-flex flex-column ml-lg-4">
            <h1 className="forgot-pass-mobile">
              Forgot <span className="d-none d-lg-inline-block">your</span> password
              <span className="d-none d-lg-inline">?</span>
            </h1>
            <p style={{ color: '#333333' }} className="d-none d-lg-inline">
              Please provide your registered email address to get a reset password
              link in your mailbox.
            </p>
            <p
              style={{ color: '#333333' }}
              className="d-lg-none lg-none xl-none mt-2 txt-mobile"
            >
              Don&apos;t worry.
              <br />
              Enter your email and we&apos;ll send you a link to reset your password
            </p>
            <Form
              className={'w-100 d-flex flex-column justify-content-between'}
              noValidate
              validated={validated}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Form.Group className="form-group mt-5 mb-5">
                <Form.Label className="d-none d-lg-inline-block">
                  Email ID
                </Form.Label>
                <>
                  <input
                    id="email"
                    className={`form-control no-shadow ${
                      errors.email && 'is-invalid'
                    }`}
                    aria-invalid={errors.email ? 'true' : 'false'}
                    {...register('email', {
                      required: 'Email cannot be empty',
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: 'Please enter a valid email address',
                      },
                    })}
                    type="email"
                    placeholder="Enter your email id"
                  />
                  {Boolean(errors?.email) && (
                    <Form.Control.Feedback className="d-flex" type="invalid">
                      {errors.email.message}
                    </Form.Control.Feedback>
                  )}
                </>
              </Form.Group>

              <div className="w-100 d-lg-flex d-none justify-content-between">
                <button
                  onClick={() => {
                    history.push('./login');
                  }}
                  type="button"
                  className="btn sw-btn-default font-weight-bold btn-rounded p-0"
                >
                  BACK TO LOGIN PAGE
                </button>
                <button type="submit" className="btn btn-rounded sw-btn-next">
                  {submitting ? (
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>{' '}
                    </Spinner>
                  ) : (
                    'Submit'
                  )}
                </button>
              </div>
              <div className="w-100 d-lg-none d-flex align-bottom">
                <button
                  type="submit"
                  className="w-100 d-flex justify-content-center btn sw-btn-next"
                  style={{
                    marginTop:
                      dimensions?.width < 576
                        ? 'clamp(1rem, calc( 100vh - 535px ), calc( 100vh - 535px ))'
                        : '1rem',
                  }}
                >
                  <b>
                    {submitting ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>{' '}
                      </Spinner>
                    ) : (
                      'Continue'
                    )}
                  </b>
                </button>
              </div>
            </Form>
          </div>
          <div className="ml-4"></div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
