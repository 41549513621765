const initialState = {
  loading: false,
  messages: [],
};

export default function messages(state = initialState, action) {
  let messageThread = [];
  var time = new Date();
  switch (action.type) {
    case 'GET_MESSAGES':
      return { ...state, loading: true };
    case 'GET_MESSAGES_SUCCESS':
      return { ...state, messages: action.payload, loading: false };
    case 'SEND_MESSAGES':
      messageThread = state.messages.filter(
        ({ jobId }) => jobId === action.payload.jobId
      );
      if (messageThread.length) {
        state.messages
          .filter(({ jobId }) => jobId === action.payload.jobId)[0]
          .messages.push({
            sender: 'me',
            time: time.toLocaleString('en-US', {
              hour: 'numeric',
              hour12: true,
              minute: 'numeric',
            }),
            message: action.payload.message,
          });
      } else {
        state.messages.push({
          id: state.messages.length + 1,
          jobId: action.payload.jobId,
          messages: [
            {
              sender: 'me',
              time: time.toLocaleString('en-US', {
                hour: 'numeric',
                hour12: true,
                minute: 'numeric',
              }),
              message: action.payload.message,
            },
          ],
        });
      }
      return { ...state, loading: false };
    default:
      return state;
  }
}
