import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const StyledInput = ({ children }) => {
  return <div className="custom-input">{children}</div>;
};

StyledInput.propTypes = {
  children: PropTypes.any,
};

export default StyledInput;
