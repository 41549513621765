import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from 'react-query';
import { useHistory, Redirect } from 'react-router';
import Loader from '../../components/loader';
import Filter from '../dashboard/filter';
import Gauge from '../dashboard/gauge';
import { fetchCandidateSearchResults, toggleSaveCandidate } from './service';
import { constructEncodedUrl, deconstructEncodedUrl } from '../../utils/helpers';
import { useFilters } from '../../context/filters-context';
import CandidateList from '../dashboard/candidatelist';
import { useSavedCandidates } from '../../hooks/useSavedCandidates';

const AllCandidatesList = (props) => {
  const { userInfo, contactedCandidates } = props;

  if (userInfo?.user_type == 'candidate') {
    return <Redirect to="/jobs" />;
  }

  const history = useHistory();
  const [skip, setSkip] = useState(history.location.state?.skip || 0);
  const [limit] = useState(5);
  const [savingCandidateId, setSavingCandidateId] = useState(null);
  const decompressed = deconstructEncodedUrl(
    history.location?.search?.replace('?', '')
  );
  const [enabled, setEnabled] = useState(decompressed == null);
  const {
    appliedFilters,
    setSelectedSkillsFilter,
    setJobTypesFilter,
    setExperience,
    setSalary,
    setSelectedGeosFilter,
    setSelectedLanguagesFilter,
  } = useFilters();

  const { data, isLoading } = useQuery(
    ['search-candidates', { ...appliedFilters, from: skip, size: limit }],
    fetchCandidateSearchResults,
    {
      enabled,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      useErrorBoundary: (error) => {
        return error.response.status === 401;
      },
      retry: (count, error) => {
        if (error.response.status === 401) {
          return false;
        } else if (count <= 3) {
          return true;
        } else {
          false;
        }
      },
    }
  );

  const {
    candidates: savedCandidates,
    isLoading: isLoadingSavedCandidates,
    refetch: refetchSavedCandidates,
    isFetching: isFetchingSavedCandidates,
  } = useSavedCandidates();

  useEffect(() => {
    if (!isFetchingSavedCandidates) {
      if (savingCandidateId !== null) {
        setSavingCandidateId(null);
      }
    }
  }, [isFetchingSavedCandidates]);

  const { mutate } = useMutation(toggleSaveCandidate, {
    onSettled: () => {
      refetchSavedCandidates();
    },
    onMutate: (id) => setSavingCandidateId(id),
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  React.useEffect(() => {
    if (skip >= data?.total) {
      setSkip(0);
    }
  }, [data?.total]);

  React.useEffect(() => {
    if (decompressed) {
      setSelectedSkillsFilter(decompressed.filters?.skills);
      setJobTypesFilter(decompressed.filters?.employmentOptions);
      setExperience(decompressed.filters?.experience);
      setSalary(decompressed.filters?.salary);
      setSelectedGeosFilter(decompressed.filters?.geos);
      setSelectedLanguagesFilter(decompressed.filters?.languages);
    }
  }, []);

  React.useEffect(() => {
    if (
      appliedFilters.employmentOptions?.length > 0 ||
      appliedFilters.languages?.length > 0 ||
      appliedFilters.locations?.length > 0 ||
      appliedFilters.skills?.length > 0 ||
      appliedFilters.salary ||
      appliedFilters.title ||
      appliedFilters.experience
    ) {
      !enabled && setEnabled(true);
      history.location.state?.filtered && setSkip(0);
    }
  }, [appliedFilters]);

  const selectCandidate = (id, key, returnLink = false) => {
    const filters = { ...appliedFilters, from: skip, size: limit };

    if (returnLink) {
      return constructEncodedUrl({ id, filters }, 'candidate');
    }
    history.push(constructEncodedUrl({ id, filters }, 'candidate'));
  };

  const paginationArray = [
    ...Array(parseInt(Math.ceil(data?.total / limit) || 0)).keys(),
  ];

  const renderPagination = () => {
    let pages = paginationArray;

    if (pages.length > 5) {
      if (skip <= 2 * limit) {
        pages = pages.slice(0, 4).concat(['...', pages[pages.length - 1]]);
      } else if (skip > 2 * limit && skip < limit * (pages.length - 2)) {
        pages = [
          pages[0],
          '...',
          skip / limit - 1,
          skip / limit,
          skip / limit + 1,
          '...',
          pages[pages.length - 1],
        ];
      } else if (skip > limit * (pages.length - 3)) {
        pages = [
          pages[0],
          '...',
          pages[pages.length - 4],
          pages[pages.length - 3],
          pages[pages.length - 2],
          pages[pages.length - 1],
        ];
      }
    }

    return (
      <>
        {pages.length > 5 && (
          <button
            className="btn py-1 d-flex align-items-center justify-content-center"
            style={{ width: 'min-content' }}
            onClick={() => onPaginate(skip / limit - 1)}
          >
            <span className="chevron left"></span>
          </button>
        )}
        {pages.map((item, key) => {
          return (
            <button
              className={classNames(
                'btn px-3 py-1 d-flex align-items-center justify-content-center',
                {
                  'bg-primary text-white': item == skip / limit,
                  'cursor-default': item === '...',
                  'mr-2': key !== pages.length - 1,
                }
              )}
              key={key}
              onClick={() => (item !== '...' ? onPaginate(item) : null)}
            >
              <span className="d-block">{item !== '...' ? item + 1 : item}</span>
            </button>
          );
        })}
        {pages.length > 5 && (
          <button
            className="btn py-1 d-flex align-items-center justify-content-center"
            style={{ width: 'min-content' }}
            onClick={() => onPaginate(skip / limit + 1)}
          >
            <span className="chevron right"></span>
          </button>
        )}
      </>
    );
  };

  const onPaginate = (page) => {
    if (
      page == skip / limit ||
      page < 0 ||
      page > paginationArray[paginationArray.length - 1]
    ) {
      return;
    }
    window && window.scrollTo(0, 0);
    history.push('/candidates', { skip: limit * page });
  };

  return (
    <>
      <div className="w-320px">
        <div className="row sticky-header">
          <div className="col-xs-12 col-md-12 col-xl-12">
            <div className="card sticky-header">
              <Filter
                view={true}
                setSkip={setSkip}
                onClearAll={() => onPaginate(0)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-75 cx-padding">
        <div className="row">
          <div className="col-xs-12 col-md-12 col-xl-12">
            <Gauge
              recruiter
              view="all"
              title={'Candidates'}
              appliedFilters={appliedFilters}
              listLength={data && data?.total}
              showSearchaAndSortOptions={false}
            />
            {isLoading && <Loader className="mt-3" />}
            {!isLoading && (
              <CandidateList
                data={data && data.candidates}
                userInfo={userInfo}
                contactedCandidates={contactedCandidates}
                to={selectCandidate}
                onSelect={(id, key) => selectCandidate(id, key)}
                saveCandidate={mutate}
                savingCandidateId={savingCandidateId}
                savedCandidates={
                  (!isLoadingSavedCandidates && savedCandidates?.map((c) => c.id)) ||
                  []
                }
              />
            )}
            {data && (
              <div className="pagination d-flex align-items-center justify-content-center mb-4">
                {data?.total !== 0 &&
                  !(data?.total <= limit) &&
                  data?.total !== limit &&
                  renderPagination()}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

AllCandidatesList.propTypes = {
  userInfo: PropTypes.any,
  contactedCandidates: PropTypes.any,
};

AllCandidatesList.defaultProps = {
  userInfo: null,
};

export default AllCandidatesList;
