export const setNotification = (payload) => (dispatch, getState) => {
  const {
    notification: { notification },
  } = getState();
  if (!notification) {
    dispatch({ type: 'SET_NOTIFICATION', payload });
    setTimeout(() => {
      dispatch({ type: 'CLEAR_NOTIFICATION' });
    }, 2000);
  }
};

export const clearNotification = () => (dispatch) => {
  dispatch({ type: 'CLEAR_NOTIFICATION' });
};
