import { getSavedCandidates, postSaveCandidate, searchCandidates } from '../../api';

export const fetchCandidateSearchResults = async ({ queryKey: [_, filters] }) => {
  try {
    const {
      data: {
        hits: {
          hits,
          total: { value },
        },
      },
    } = await searchCandidates(filters);
    let candidates = [];
    hits.map((item) => candidates.push(item._source));
    /**
     * Leaving a gap here cause I need to return saved candidates in search endpoint
     */
    return { candidates, total: value, savedCandidates: [] };
  } catch (e) {
    console.log(e);
    throw e;
  }
};

/**
 * Still need a backend api for this, creating a mock for now
 */
export const toggleSaveCandidate = async (id) => {
  try {
    const { data } = await postSaveCandidate(id);
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const fetchSavedCandidates = async () => {
  try {
    const { data } = await getSavedCandidates();
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
