import { getCandidate } from '../../api';

export async function fetchCandidate({ queryKey: [_, { id }] }) {
  try {
    const { data } = await getCandidate(id);
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
}
