import { gql } from '@apollo/client';

/**
 * Queries
 */
export const GET_ALL_UNREAD_THREADS = gql`
  query GetAllUnreadThreads($limit: Int, $nextToken: String) {
    getAllThreads(limit: $limit, nextToken: $nextToken) {
      items {
        candidate_id
        job_id
        unread
        thread_id
      }
    }
  }
`;
export const GET_ALL_THREADS = gql`
  query GetAllThreads($limit: Int, $nextToken: String) {
    getAllThreads(limit: $limit, nextToken: $nextToken) {
      items {
        thread_id
        candidate_id
        job_id
        updated_at
        unread
        applied
        recruiter {
          id
        }
        candidate {
          id
          name
          work_experience
          about_professional_experience
          locations {
            name
          }
          employment_options {
            name
          }
          designation
          salary_expectations
        }
        job {
          description
          company_name
          employment_options {
            name
          }
          status
          locations {
            name
          }
          title
          experience
        }
      }
      nextToken
    }
  }
`;
/**
 * - Applied we have to figure out
 */
export const GET_MESSAGES = gql`
  query GetMessages($thread_id: String!) {
    getThread(thread_id: $thread_id) {
      thread_id
      unread
      applied
      candidate_id
      candidate {
        id
        name
        work_experience
        designation
        salary_expectations
        email
        linkedin_profile
        git_profile_link
        locations {
          name
        }
        employment_options {
          name
        }
      }
      job {
        id
        title
        company_name
        status
        experience
        locations {
          name
        }
        employment_options {
          name
        }
      }
      recruiter {
        name
      }
      messages {
        items {
          content
          content_type
          sender_id
          created_at
        }
        nextToken
      }
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation SendMessage(
    $content: String!
    $content_type: MessageContentType!
    $thread_id: String!
  ) {
    createMessage(
      content: $content
      content_type: $content_type
      thread_id: $thread_id
    ) {
      messages {
        items {
          content
          content_type
          sender_id
          created_at
        }
      }
    }
  }
`;

/**
 * Mutations
 */

export const MARK_AS_READ = gql`
  mutation MarkAsRead($thread_id: String!) {
    markThreadAsRead(thread_id: $thread_id) {
      thread_id
    }
  }
`;

export const CREATE_THREAD = gql`
  mutation CreateThread($job_id: String!, $candidate_id: String) {
    createThread(job_id: $job_id, candidate_id: $candidate_id) {
      job_id
      thread_id
    }
  }
`;

export const APPLY_TO_JOB = gql`
  mutation applyJob($job_id: String!) {
    applyToJob(job_id: $job_id) {
      thread_id
    }
  }
`;
