import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames';
import { getMessages } from '../dashboard/service';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import home from '../../images/Icons/home.svg';
import location from '../../images/Icons/location.svg';
import level from '../../images/Icons/level.svg';
import appliedIcon from '../../images/Icons/applied.svg';
import calender from '../../images/Icons/calender.svg';
import handsIcon from '../../images/Icons/hands.svg';
import tagIcon from '../../images/Icons/tag.svg';
import './styles.scss';

const Thread = ({
  data,
  onClick,
  jobId,
  unread,
  companyName,
  applied,
  title,
  lastMessage,
  jobTitle,
  locations,
  experience,
  status,
  employmentOptions,
  showDetails,
  candidateView,
  salary,
  updatedAt,
}) => {
  const getTimeString = (date) => {
    return moment.utc(date).local().format('DD MMM, YYYY');
  };

  return (
    <div
      onClick={onClick}
      className={`col-xs-12 col-md-12 col-lg-12 p-0 my-${candidateView ? '3' : '2'}${
        candidateView && (status == 'unpublished' || status == 'deleted')
          ? ' greyed-out'
          : ''
      }`}
    >
      <div className="job-card">
        <div className="card">
          <div
            className={classNames('card-body job-detail big pb-1', {
              active: unread,
              'greyed-out':
                candidateView && (status == 'unpublished' || status == 'deleted'),
            })}
          >
            <div className="row">
              <div className="col">
                <h5
                  className="card-title pb-2 mb-0 m-card-title"
                  {...(candidateView ? { style: { fontWeight: 500 } } : {})}
                >
                  {title}
                  {candidateView &&
                    (status == 'unpublished' || status == 'deleted') && (
                      <i style={{ fontWeight: 400 }}>
                        {' '}
                        (this job is no longer available)
                      </i>
                    )}
                </h5>
              </div>
            </div>
            <div className="d-flex align-items-center">
              {companyName && candidateView && (
                <span className="m-detail d-flex align-items-center text-truncate">
                  <img src={home} />
                  <span
                    className="ml-2 text-truncate"
                    style={{
                      maxWidth: locations?.length > 2 ? '8rem' : '10rem',
                    }}
                  >
                    {companyName}
                  </span>
                </span>
              )}
              {((candidateView && locations.length !== 0) ||
                (!candidateView && locations)) && (
                <span className="m-detail d-flex align-items-center text-truncate">
                  <img src={location} />
                  <span className="ml-2 text-truncate" style={{ maxWidth: '7rem' }}>
                    {locations.length > 2
                      ? locations?.slice(0, 2).join(', ')
                      : locations.join(', ') || 'Location'}
                  </span>
                </span>
              )}
              {!isNaN(experience) && (
                <span className="m-detail d-flex align-items-center text-truncate">
                  <img src={level} />
                  <span className="ml-2 text-truncate">
                    {experience > 1
                      ? `${experience} years of experience`
                      : `${experience} year of experience`}
                  </span>
                </span>
              )}
              {employmentOptions !== null && employmentOptions.length !== 0 && (
                <span className="m-detail d-flex align-items-center text-truncate">
                  <img src={handsIcon} />
                  <span className="ml-2 text-truncate" style={{ maxWidth: '6rem' }}>
                    {employmentOptions.length > 2
                      ? employmentOptions?.slice(0, 2).join(', ')
                      : employmentOptions.join(', ') || 'Employment'}
                  </span>
                </span>
              )}
              {salary && (
                <span className="m-detail d-flex align-items-center text-truncate">
                  <img src={tagIcon} />
                  <span className="ml-2 text-truncate text-primary">{salary}</span>
                </span>
              )}
            </div>
            {!candidateView && (
              <div
                className="d-flex align-items-center"
                style={{ fontSize: '13px' }}
              >
                <span className="text-grey">Position:</span>
                <Link
                  className="text-truncate cursor-pointer d-block ml-2"
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                  to={() => showDetails(jobId)}
                  style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    maxWidth: '75%',
                  }}
                >
                  {jobTitle}
                </Link>
              </div>
            )}
            {lastMessage && (
              <p className="my-1 text-grey m-content">{lastMessage}</p>
            )}
            <div className="d-flex align-items-center pt-2">
              {applied && !candidateView && (
                <div className="d-flex mr-2">
                  <img
                    style={{ padding: '5px', transform: 'rotateY(180deg)' }}
                    className="ch-icons pl-0"
                    src={appliedIcon}
                  />
                  <p className="ml-1 mb-0 text-grey" style={{ fontSize: '12px' }}>
                    Applied
                  </p>
                </div>
              )}
              {updatedAt && (
                <div className="d-flex flex-row">
                  <a className="m-detail d-flex">
                    <img
                      style={{ padding: '5px' }}
                      className="ch-icons pl-0"
                      src={calender}
                    />
                    <span>{getTimeString(updatedAt)}</span>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Thread.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
  showDetails: PropTypes.func,
  jobId: PropTypes.string,
  unread: PropTypes.bool,
  applied: PropTypes.bool,
  candidateView: PropTypes.bool,
  title: PropTypes.string,
  jobTitle: PropTypes.string,
  lastMessage: PropTypes.string,
  status: PropTypes.string,
  companyName: PropTypes.string,
  locations: PropTypes.any,
  experience: PropTypes.any,
  employmentOptions: PropTypes.any,
  salary: PropTypes.number,
  updatedAt: PropTypes.string,
};

export default Thread;
