import React, { useState } from 'react';
import PropTypes from 'prop-types';
import btnArrow from '../../../images/Icons/btn-arrow.svg';
import { Form } from 'react-bootstrap';
import '../styles.scss';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDimensions } from '../../../hooks/useDimensions';
import About from './about';
import Personal from './personal';
import Summary from './summary';
import Page from '../page';
import { Spinner } from 'react-bootstrap';

import {
  useEmploymentOptions,
  useGeographies,
  useLanguages,
  useSkills,
} from '../../../hooks/useEntities';
import { useMutation, useQueryClient } from 'react-query';
import { createCandidateUser } from './service';

export const candidate = Object.freeze({
  ABOUT: 1,
  PERSONAL: 2,
  SUMMARY: 3,
});
function CandidateOnboardingForm(props) {
  const { setStep, currentStep } = props;
  const { push } = useHistory();
  const [validated, setValidated] = useState(false);
  const dimensions = useDimensions();

  const { data: skills } = useSkills();
  const { data: languages } = useLanguages();
  const { data: empOpts } = useEmploymentOptions();
  const { data: geographies } = useGeographies();

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    trigger,
    getValues,
    setError,
    clearErrors,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      title: '',
      experience: '',
      salaryExpectation: '1000',
      employment: [],
      skills: [],
      languages: [],
      geos: [],
    },
  });
  const queryClient = useQueryClient();

  const { isLoading, isError, isSuccess, mutate } = useMutation(
    (data) => createCandidateUser(data),
    {
      useErrorBoundary: (error) => {
        return error.response.status === 401;
      },
      retry: (count, error) => {
        if (error.response.status === 401) {
          return false;
        } else if (count <= 3) {
          return true;
        } else {
          false;
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries('get-user-onboarding-status');
        queryClient.invalidateQueries('get-auth0-profile');
        push({ pathname: '/', state: 'user-details-submitted' });
      },
    }
  );

  const next = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    await trigger(
      currentStep == 1
        ? [
            'title',
            'experience',
            'salaryExpectation',
            'skills',
            'employment',
            'languages',
            'geos',
          ]
        : ['professionalExperience', 'dreamJob']
    ).then((noError) => {
      noError && currentStep <= 2 && setStep(currentStep + 1);
    });
  };

  const previous = () => {
    if (currentStep === 1) {
      push('/onboarding');
    } else {
      setStep(currentStep - 1);
    }
  };

  const updateInitialValues = (fieldName, value) => {
    reset(
      {
        ...control.defaultValuesRef.current,
        [fieldName]: value,
      },
      { keepValues: true }
    );
  };

  const getTitleBasedOnStep = (step) => {
    let title = 'title';
    switch (step) {
      case candidate.PERSONAL:
        title = 'Tell us more about yourself';
        break;
      case candidate.ABOUT:
        title = 'Tell us more about yourself';
        break;
      case candidate.SUMMARY:
        title = 'Summary';
        break;
      default:
        break;
    }
    return title;
  };
  const getSubTitleBasedOnStep = (step) => {
    let subTitle = 'title';
    switch (step) {
      case candidate.PERSONAL:
        subTitle = 'Personal details';
        break;
      case candidate.ABOUT:
        subTitle = 'Describe your skills and experience';
        break;
      case candidate.SUMMARY:
        subTitle = '';
        break;
      default:
        break;
    }
    return subTitle;
  };
  return (
    <div className="w-100by485 sw-padding vh-100 bg-white">
      <div className="row">
        <div className="col-xs-12 col-md-12 col-xl-12">
          <div className="sw-headings sw-top-padding">
            <h1>{getTitleBasedOnStep(currentStep)}</h1>
            <h3 className={getSubTitleBasedOnStep(currentStep) == '' ? 'mb-0' : ''}>
              {getSubTitleBasedOnStep(currentStep)}
            </h3>
          </div>
          <Form
            className={'w-100 about-candidate'}
            noValidate
            validated={validated}
            onSubmit={handleSubmit(mutate)}
          >
            <div
              className="sw-form"
              {...(currentStep !== 2 && dimensions.width < 576
                ? { style: { paddingBottom: '5rem' } }
                : {})}
            >
              {currentStep === candidate.ABOUT && (
                <About
                  errors={errors}
                  control={control}
                  skills={skills ?? []}
                  geos={geographies ?? []}
                  languages={languages ?? []}
                  employments={empOpts ?? []}
                />
              )}
              {currentStep === candidate.PERSONAL && (
                <Personal register={register} values={getValues} errors={errors} />
              )}
              {currentStep === candidate.SUMMARY && (
                <Summary redirectToStep={setStep} data={getValues()} />
              )}
              <div className="sw-action">
                <div className={`onboarding-progress step-${currentStep}`}></div>
                <div className="row align-items-center">
                  <div className="confirmation-buttons">
                    {currentStep > 0 ? (
                      <button
                        type="button"
                        onClick={previous}
                        className="btn sw-btn-default btn-rounded "
                      >
                        {dimensions.width < 576 ? (
                          <span className="chevron left"></span>
                        ) : (
                          'PREVIOUS STEP'
                        )}
                      </button>
                    ) : (
                      <span></span>
                    )}
                  </div>
                  <div className="col text-right">
                    <div className="confirmation-buttons">
                      {currentStep > 2 ? (
                        <button
                          type="submit"
                          className="btn btn-rounded sw-btn-next"
                        >
                          {isLoading ? (
                            <Spinner animation="border" role="status">
                              <span className="sr-only">Loading...</span>{' '}
                            </Spinner>
                          ) : (
                            'Publish profile'
                          )}
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={next}
                          className="btn btn-rounded sw-btn-next"
                        >
                          {'Next Step'}
                          {currentStep <= 2 && (
                            <img height="11" width="16" src={btnArrow} />
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

CandidateOnboardingForm.propTypes = {
  currentStep: PropTypes.number,
  setStep: PropTypes.func,
};

const steps = [
  'Pick your role',
  'Tell us more about yourself',
  'Describe your experience',
  'Summary',
];

export default Page(CandidateOnboardingForm, steps, 1);
