import httpClient from '../../utils/httpClient';

export const getJobs = async (params) => {
  const { data } = await httpClient.get('/jobs', { ...params });
  return data;
};

export const createJob = async (data) => {
  return await httpClient.post('/job', { ...data });
};

export const deleteJob = async (data) => {
  return await httpClient.delete(`/job?id=${data.id}`);
};

export const getJob = async (data) => {
  return await httpClient.get(`/job?id=${data.id}`);
};

export const togglePublishState = async (data) => {
  return await httpClient.put(`/job/${data.id}/toggleState`);
};

export const updateJob = async (data) => {
  return await httpClient.put('/job', { ...data });
};
