import React from 'react';
import { Link } from 'react-router-dom';
import { useMessageThreads } from '../../context/message-threads-context';
import Card from '../../components/card';
import PropTypes from 'prop-types';

const CandidateList = ({
  data,
  onSelect,
  savedCandidates,
  to,
  userInfo,
  saveCandidate,
  contactedCandidates,
  savingCandidateId,
}) => {
  const { openThreadModal, setCandidateId, setCandidateData } = useMessageThreads();

  const onCandidateMessage = (item, id) => {
    setCandidateData(item);
    setCandidateId(id);
    openThreadModal();
  };

  return (
    <div className="tab-content" id="pills-tabContent">
      <div
        className="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        {data?.map((c, index) => (
          <Link
            className="card-link m-0"
            to={() => to(c.id, index, true)}
            key={c.id}
          >
            <Card
              id={c.id}
              title={c.designation}
              className="my-2"
              contactedJobs={contactedCandidates}
              experience={c.work_experience}
              userInfo={userInfo}
              locations={c.locations ? c.locations.map((l) => l.name) : []}
              employmentOptions={
                c.employment_options ? c.employment_options.map((e) => e.name) : []
              }
              skills={c.skills ? c.skills.map((s) => s.name) : []}
              salary={c.salary_expectations}
              body={c.about_professional_experience}
              savingId={savingCandidateId}
              saved={savedCandidates && savedCandidates?.includes(c.id)}
              onMessage={(id) => onCandidateMessage(c, id)}
              onClick={(id) => onSelect(id, index)}
              onSave={saveCandidate}
              showSkills
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

CandidateList.propTypes = {
  data: PropTypes.any,
  onSelect: PropTypes.func,
  savedCandidates: PropTypes.any,
  userInfo: PropTypes.any,
  saveCandidate: PropTypes.any,
  savingCandidateId: PropTypes.any,
  contactedCandidates: PropTypes.any,
  to: PropTypes.func,
};

export default CandidateList;
