import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import classNames from 'classnames';
import {
  useEmploymentOptions,
  useGeographies,
  useLanguages,
  useSkills,
} from '../../../hooks/useEntities';

export const JobForm = ({ errors, control, register, firsFieldLength }) => {
  const { data: skills } = useSkills();
  const { data: languages } = useLanguages();
  const { data: empOpts } = useEmploymentOptions();
  const { data: geographies } = useGeographies();
  const [errorState, setErrorState] = React.useState(errors);
  const [firstField, setFirstField] = React.useState(firsFieldLength || '');

  React.useEffect(() => {
    setErrorState(errors);
  }, [
    errors?.title,
    errors?.skills,
    errors?.salary,
    errors?.languages,
    errors?.locations,
    errors?.experience,
    errors?.employment_options,
  ]);

  const preventCharacters = (e) => {
    let charCode = e.which ? e.which : e.keyCode;

    if (
      charCode == 69 ||
      charCode == 40 ||
      charCode == 38 ||
      charCode == 110 ||
      charCode == 107 ||
      charCode == 109 ||
      charCode == 190 ||
      charCode == 189 ||
      charCode == 187
    ) {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <Form.Group className="form-group">
            <Form.Label>Position/Title</Form.Label>
            <Controller
              control={control}
              id={`title`}
              name={`title`}
              defaultValue={''}
              rules={{ required: true }}
              render={(params) => (
                <>
                  <input
                    type="text"
                    className={classNames('form-control', {
                      'is-invalid': errorState?.title,
                    })}
                    placeholder={`Enter title here`}
                    onChange={(e) =>
                      params.field.onChange(e.target.value) +
                      setErrorState({ ...errorState, title: false })
                    }
                    defaultValue={params.field.value}
                  />
                </>
              )}
            />
            {Boolean(errorState?.title) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Required
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-xl-6">
          <Form.Group className="form-group">
            <Form.Label className="d-flex align-items-center justify-content-between">
              Salary range (monthly){' '}
              <div className="d-flex align-items-center">
                <Controller
                  control={control}
                  id={`show_salary`}
                  name={`show_salary`}
                  defaultValue={''}
                  render={(params) => (
                    <>
                      <label className="radio-container d-flex align-items-center mb-0">
                        <input
                          type="radio"
                          readOnly
                          className="mr-2"
                          onChange={() => params.field.onChange(false)}
                          checked={params.field.value == false}
                        />
                        <span className="text-truncate">Private</span>
                        <span className="checkmark"></span>
                      </label>
                      <label className="radio-container d-flex align-items-center ml-2 mb-0">
                        <input
                          type="radio"
                          readOnly
                          className="mr-2"
                          checked={params.field.value == true}
                          onChange={() => params.field.onChange(true)}
                        />
                        <span className="text-truncate">Public</span>
                        <span className="checkmark"></span>
                      </label>
                    </>
                  )}
                />
              </div>
            </Form.Label>
            <Controller
              control={control}
              id={`salary`}
              name={`salary`}
              defaultValue={''}
              rules={{ required: true, min: 3 }}
              render={(params) => (
                <>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span
                        className={classNames('input-group-text', {
                          'is-invalid mb-3': errorState?.salary,
                        })}
                      >
                        $
                      </span>
                    </div>
                    <input
                      onKeyDown={preventCharacters}
                      onWheel={(e) => e.target.blur()}
                      type="number"
                      className={classNames('form-control mb-0', {
                        'is-invalid': errorState?.salary,
                      })}
                      placeholder={`Expected monthly salary`}
                      onChange={(e) =>
                        params.field.onChange(e.target.value) +
                        setErrorState({ ...errorState, salary: false })
                      }
                      defaultValue={params.field.value}
                    />
                  </div>
                </>
              )}
            />
            {Boolean(errorState?.salary) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Required
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
        <div className="col-sm-12 col-md-6 col-xl-6">
          <Form.Group className="form-group">
            <Form.Label>Minimum work experience (years)</Form.Label>
            <Controller
              control={control}
              id={`experience`}
              name={`experience`}
              defaultValue={''}
              rules={{
                required: true,
                min: 0,
                max: {
                  value: '99',
                  message: 'Work experience cannot be greater than 99',
                },
              }}
              render={(params) => (
                <>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={preventCharacters}
                    className={classNames('form-control', {
                      'is-invalid mb-3': errorState?.experience,
                    })}
                    placeholder={`Enter experience here`}
                    onChange={(e) =>
                      params.field.onChange(e.target.value) +
                      setErrorState({ ...errorState, experience: false })
                    }
                    defaultValue={params.field.value}
                  />
                </>
              )}
            />
            {Boolean(errorState?.experience) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                {errorState?.experience?.message
                  ? errorState?.experience?.message
                  : 'Required'}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-xl-6">
          <Form.Group className="form-group">
            <Form.Label>Skills</Form.Label>
            <Controller
              control={control}
              id="skills"
              name="skills"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={skills}
                  getOptionValue={(opt) => opt.id}
                  getOptionLabel={(opt) => opt.name}
                  isMulti
                  placeholder="Select skills"
                  onChange={(e) =>
                    onChange(e) + setErrorState({ ...errorState, skills: false })
                  }
                  defaultValue={value}
                  className={classNames({ 'is-invalid mb-3': errorState?.skills })}
                  classNamePrefix="checkbox-select"
                />
              )}
            />
            {Boolean(errorState?.skills) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Required
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
        <div className="col-sm-12 col-md-6 col-xl-6">
          <Form.Group className="form-group">
            <Form.Label>Languages</Form.Label>
            <Controller
              control={control}
              id="languages"
              name="languages"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={languages}
                  getOptionValue={(opt) => opt.id}
                  getOptionLabel={(opt) => opt.name}
                  isMulti
                  placeholder="Select languages"
                  onChange={(e) =>
                    onChange(e) + setErrorState({ ...errorState, languages: false })
                  }
                  defaultValue={value}
                  className={classNames({
                    'is-invalid mb-3': errorState?.languages,
                  })}
                  classNamePrefix="checkbox-select"
                />
              )}
            />
            {Boolean(errorState?.languages) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Required
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-xl-6">
          <Form.Group className="form-group">
            <Form.Label>Employment options</Form.Label>
            <Controller
              control={control}
              id="employment_options"
              name="employment_options"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={empOpts}
                  getOptionValue={(opt) => opt.id}
                  getOptionLabel={(opt) => opt.name}
                  isMulti
                  placeholder="Select"
                  onChange={(e) =>
                    onChange(e) +
                    setErrorState({ ...errorState, employment_options: false })
                  }
                  defaultValue={value}
                  maxMenuHeight="180px"
                  className={classNames({
                    'is-invalid mb-3': errorState?.employment_options,
                  })}
                  classNamePrefix="checkbox-select"
                />
              )}
            />
            {Boolean(errorState?.employment_options) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Required
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
        <div className="col-sm-12 col-md-6 col-xl-6">
          <Form.Group className="form-group">
            <Form.Label>Preferred geographies</Form.Label>
            <Controller
              control={control}
              id="locations"
              name="locations"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <Select
                  options={geographies}
                  maxMenuHeight="180px"
                  getOptionValue={(opt) => opt.id}
                  getOptionLabel={(opt) => opt.name}
                  isMulti
                  placeholder="Select geographies"
                  onChange={(e) =>
                    onChange(e) + setErrorState({ ...errorState, locations: false })
                  }
                  defaultValue={value}
                  className={classNames({
                    'is-invalid mb-3': errorState?.locations,
                  })}
                  classNamePrefix="checkbox-select"
                />
              )}
            />
            {Boolean(errorState?.locations) && (
              <Form.Control.Feedback className="d-flex" type="invalid">
                Required
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-6 col-xl-12">
          <Form.Group className="form-group">
            <Form.Label>Job description</Form.Label>
            <Form.Control
              id="description"
              name="description"
              {...register('description', { required: true })}
              style={{ height: 'calc( 100vh - 700px )', minHeight: '500px' }}
              onChange={(e) =>
                setFirstField(e.target.value) +
                setErrorState({ ...errorState, description: false })
              }
              type="text"
              as="textarea"
              maxLength="2000"
              className={
                ('form-control',
                classNames({
                  'is-invalid': errorState?.description,
                }))
              }
              placeholder="Enter value here"
            />
            <Form.Control.Feedback className="d-flex text-grey" type="invalid">
              {firstField.length}/2000
            </Form.Control.Feedback>
            {Boolean(errorState?.description) && (
              <Form.Control.Feedback className="d-flex pt-1" type="invalid">
                Required
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
      </div>
    </>
  );
};

JobForm.propTypes = {
  errors: PropTypes.any,
  firsFieldLength: PropTypes.any,
  register: PropTypes.any,
  control: PropTypes.any,
};
