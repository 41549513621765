import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { updateUser } from './service';
import {
  useEmploymentOptions,
  useGeographies,
  useLanguages,
  useSkills,
} from '../../hooks/useEntities';
import About from '../onboarding/form/about';
import Personal from '../onboarding/form/personal';

const Info = ({ userInfo, setLoading, published }) => {
  const { data: skills } = useSkills();
  const { data: languages } = useLanguages();
  const { data: empOpts } = useEmploymentOptions();
  const { data: geographies } = useGeographies();

  const {
    register,
    formState: { errors },
    trigger,
    control,
    getValues,
  } = useForm({
    defaultValues: {
      title: userInfo.designation,
      experience: userInfo.work_experience,
      salaryExpectation: userInfo.salary_expectations,
      employment: userInfo.employment_options,
      dreamJob: userInfo.about_dream_job,
      professionalExperience: userInfo.about_professional_experience,
      skills: userInfo.skills,
      languages: userInfo.languages,
      geos: userInfo.locations,
    },
  });

  const queryClient = useQueryClient();

  const { mutate } = useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('get-auth0-profile');
      toast.success('Updated Succesfully');
      setLoading(false);
    },
    onError: () => {
      toast.error("Couldn't Update");
      setLoading(false);
    },
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    await trigger([
      'title',
      'experience',
      'salaryExpectation',
      'skills',
      'employment',
      'languages',
      'geos',
    ]).then(async (noError) => {
      if (noError) {
        setLoading(true);
        let {
          dreamJob,
          title,
          employment,
          experience,
          geos,
          languages,
          professionalExperience,
          salaryExpectation,
          skills,
        } = getValues();

        let params = {
          ...userInfo,
          designation: title,
          work_experience: parseInt(experience),
          salary_expectations: parseInt(salaryExpectation),
          about_professional_experience: professionalExperience,
          about_dream_job: dreamJob,
          employment_options: employment,
          locations: geos,
          languages,
          skills,
          profile_published: published,
        };

        mutate({ ...params });
      }
    });
  };

  return (
    <div className="info-page">
      <h3 className="mb-3">Your Anonymous Profile</h3>
      <h5 className="mb-3 text-grey">Update your skills and recent expereince</h5>
      <Form className="w-75" noValidate id="profile" onSubmit={onSubmit}>
        <About
          errors={errors}
          control={control}
          skills={skills ?? []}
          geos={geographies ?? []}
          languages={languages ?? []}
          employments={empOpts ?? []}
        />
        <Personal register={register} errors={errors} values={getValues} />
        <div className="w-100 d-flex">
          <button className="btn btn-primary btn-rounded text-white ml-auto">
            Save Profile
          </button>
        </div>
      </Form>
    </div>
  );
};

Info.propTypes = {
  published: PropTypes.bool,
  setLoading: PropTypes.func,
  userInfo: PropTypes.any,
};

export default Info;
