import { combineReducers } from 'redux'
import user from './userReducer'
import jobs from './jobsReducer'
import notification from './notificationReducer'
import entities from './entitiesReducer'
import messages from './messagesReducer'

export default combineReducers({
  user,
  jobs,
  notification,
  entities,
  messages
})