import {
  getJobs as fetchJobs,
  getAllSavedJobs as fetchSavedJobs,
  applyJob as applyjobApi,
  saveJob as saveJobApi,
  getAppliedJobs as fetchAppliedJobs,
  getAllThreads as fetchAllThreads,
  getMessages as fetchMessages,
  sendMessage as sendMessageApi,
  getUserProfile as fetchUserProfile,
} from '../../api';

export function getJobs({ queryKey }) {
  return new Promise((resolve, reject) => {
    const filters = { ...queryKey[1] };
    try {
      let jobs = [];
      fetchJobs(filters).then((res) => {
        const {
          data: {
            body: {
              hits: { hits, total },
            },
            savedJobs,
          },
        } = res;
        const unresolvedPromises = hits.map(({ _source: job }) => {
          jobs.push({
            domain: job.company_website,
            employmentOptions: job.employment_options,
            languages: job.languages,
            experience: job.experience,
            location: job.locations,
            salary: job.salary,
            skills: job.skills,
            status: job.status,
            title: job.title,
            description: job.description,
            aboutCompany: job.about_company,
            companyName: job.company_name,
            qualifications: job.qualifications,
            id: job.id,
          });
        });
        Promise.all(unresolvedPromises).then(() => {
          resolve({ jobs: jobs, savedJobs, totalJobs: total?.value });
        });
      });
    } catch (error) {
      reject(new Error(error));
    }
  });
}

export const postJobs = async (filters) => {
  try {
    let jobs = [];
    await fetchJobs(filters).then((res) => {
      jobs = res.data.body.hits.hits.map(({ _source }) => {
        const job = _source;
        return {
          domain: job.company_website,
          employmentOptions: job.employment_options,
          languages: job.languages,
          experience: job.experience,
          location: job.locations,
          salary: job.salary,
          skills: job.skills,
          status: job.status,
          title: job.title,
          description: job.description,
          aboutCompany: job.about_company,
          companyName: job.company_name,
          qualifications: job.qualifications,
          id: job.id,
        };
      });
    });
    return jobs;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllSavedJobs = async () => {
  try {
    const { data } = await fetchSavedJobs();
    const jobs = data.map((job) => ({
      domain: job.company_website,
      employmentOptions: job.employment_options,
      languages: job.languages,
      experience: job.experience,
      location: job.locations,
      salary: job.salary,
      skills: job.skills,
      status: job.status,
      title: job.title,
      description: job.description,
      aboutCompany: job.about_company,
      companyName: job.company_name,
      qualifications: job.qualifications,
      id: job.id,
    }));

    return jobs;
  } catch (error) {
    throw new Error(error);
  }
};

export const applyJob = async ({ jobID, data }) => {
  try {
    applyjobApi({ jobID, data });
  } catch (error) {
    throw new Error(error);
  }
};

export const saveJob = async (jobID) => {
  try {
    const { data } = await saveJobApi(jobID);
  } catch (e) {
    throw new Error(e);
  }
};

export const getAppliedJobs = async () => {
  try {
    const { data } = await fetchAppliedJobs();
    const jobs = data.map((job) => ({
      domain: job.company_website,
      employmentOptions: job.employment_options,
      languages: job.languages,
      experience: job.experience,
      location: job.locations,
      salary: job.salary,
      skills: job.skills,
      status: job.status,
      title: job.title,
      description: job.description,
      aboutCompany: job.about_company,
      companyName: job.company_name,
      qualifications: job.qualifications,
      id: job.id,
    }));

    return jobs;
  } catch (error) {
    throw new Error(error);
  }
};

export const getAllThreads = async () => {
  try {
    const { data } = await fetchAllThreads();
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getMessages = async (jobId) => {
  try {
    const { data, headers } = await fetchMessages(jobId);
    return { data, userId: headers['x-user-id'] };
  } catch (error) {
    throw new Error(error);
  }
};

export const sendMessage = async ({ content, jobId, receiver_id }) => {
  try {
    const { data } = await sendMessageApi({ content, jobId, receiver_id });
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getUserProfile = async () => {
  try {
    const { data } = await fetchUserProfile();
    return data;
  } catch (error) {
    throw new Error(error);
  }
};
