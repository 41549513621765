import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useHistory, useParams, Redirect } from 'react-router';
import JobDetailCard from '../../components/job-detail-card';
import DetailSidebar from '../../components/detail-sidebar/detail-sidebar';
import Loader from '../../components/loader';
import { Modal } from 'react-bootstrap';
import { fetchJob } from '../../hoc/services/get-job';
import { useMutation, useQueryClient } from 'react-query';
import { constructEncodedUrl, deconstructEncodedUrl } from '../../utils/helpers';
import { EditJob } from '../myJobs/editJob';
import { getJobs, deleteJob, togglePublishState } from '../myJobs/service';
import './index.scss';

const MyJobDetail = (props) => {
  const { userInfo } = props;

  if (userInfo?.user_type == 'candidate') {
    return <Redirect to="/jobs" />;
  }
  const history = useHistory();
  const { id } = useParams();
  const [jobUpdateLoading, setJobUpdateLoading] = React.useState(false);
  const [editModalShow, setEditModalShow] = React.useState(false);
  const decompressed = deconstructEncodedUrl(id);
  const [recentUpdated, setRecentUpdated] = React.useState(
    decompressed?.recentUpdated || 0
  );
  const [skip, setSkip] = useState(
    history.location.state?.from || decompressed?.filters?.from || 0
  );
  const [limit] = useState(decompressed.filters.size);
  const [loading, setLoading] = React.useState(true);

  const { data: myJobs, isLoading } = useQuery(['get-jobs'], getJobs, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const {
    data: job,
    isLoading: jobLoading,
    isFetching,
  } = useQuery(['fetch-job', { id: decompressed.id }], fetchJob, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const queryClient = useQueryClient();

  const onDeleteJob = useMutation(deleteJob, {
    onMutate: () => {
      setJobUpdateLoading(true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('get-jobs');
      history.push('/my-jobs');
      toast.success('Successfully deleted');
    },
    onError: () => {
      setJobUpdateLoading(false);
      toast.error("Couldn't delete");
    },
  });

  React.useEffect(() => {
    if (!isFetching) {
      setLoading(false);
    }
  }, [isFetching]);

  const onJobSelected = (jobId) => {
    return constructEncodedUrl(
      {
        id: jobId,
        filters: {
          ...decompressed.filters,
          from: skip,
          size: limit,
        },
        view: decompressed?.view,
        recentUpdated,
      },
      'my-jobs'
    );
  };

  const data = {
    jobs: myJobs
      ?.filter((item) => {
        if (decompressed?.view == 'recent') {
          return item?.status == 'published';
        } else {
          return item?.status == 'unpublished';
        }
      })
      ?.sort((a, b) => moment.utc(b.created_at).diff(moment.utc(a.created_at)))
      ?.slice(skip, skip + limit),
    totalJobs: myJobs?.filter((item) => {
      if (decompressed?.view == 'recent') {
        return item?.status == 'published';
      } else {
        return item?.status == 'unpublished';
      }
    })?.length,
  };

  const { mutate: togglePublish } = useMutation(togglePublishState, {
    onSuccess: async (response, variables) => {
      if (decompressed?.view == 'draft') {
        if (variables.initialStatus == 'unpublished') {
          setRecentUpdated(recentUpdated + 1);
        } else {
          setRecentUpdated(recentUpdated - 1);
        }
      } else if (decompressed?.view == 'recent') {
        if (variables.initialStatus == 'unpublished') {
          setRecentUpdated(recentUpdated - 1);
        } else {
          setRecentUpdated(recentUpdated + 1);
        }
      }

      await queryClient.invalidateQueries('fetch-job');
      toast.success(
        variables.initialStatus == 'unpublished'
          ? 'Published succesfully'
          : 'Unpublished succesfully'
      );
      setJobUpdateLoading(false);
    },
    onError: () => {
      setJobUpdateLoading(false);
      toast.error('Something went wrong');
    },
    useErrorBoundary: (error) => {
      return error?.response?.status === 401;
    },
    retry: (count, error) => {
      if (error?.response?.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const onPaginate = (type) => {
    if (type === 'next' && !(data?.jobs?.length < limit)) {
      history.push(history.location.pathname, { from: skip + limit });
    } else if (type === 'prev' && skip) {
      history.push(history.location.pathname, { from: skip - limit });
    } else {
      return;
    }
  };

  const onAction = (type, id, status) => {
    switch (type) {
      case 'delete':
        onDeleteJob.mutate({ id });
        break;
      case 'edit':
        setEditModalShow(true);
        break;
      case 'togglePublish':
        setJobUpdateLoading(true);
        togglePublish({ id, initialStatus: status });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (history.location.state?.from === 0) {
      setSkip(0);
    }
  }, [history.location.state?.from]);

  return (
    <>
      {jobUpdateLoading && <Loader className="my-job-detail-loader" />}
      <Modal
        className="create-job-modal"
        show={editModalShow}
        animation="false"
        onHide={() => setEditModalShow(false)}
      >
        <EditJob
          userInfo={userInfo}
          inDetail
          data={job}
          onClose={() => setEditModalShow(false)}
          setLoading={setJobUpdateLoading}
        />
      </Modal>
      {decompressed.filters != undefined && (
        <div className="w-320px">
          <div className="row sticky-header">
            <div className="col-xs-12 mb-2 col-md-12 col-xl-12">
              <div className="sticky-header">
                <DetailSidebar
                  header="My Jobs"
                  onPaginate={onPaginate}
                  jobType="applied"
                  bodyKey="description"
                  data={{ ...data, skip, limit }}
                  selectedItemId={decompressed.id}
                  onSelectItem={onJobSelected}
                  onNavigateBack={async () => {
                    if (recentUpdated) {
                      setJobUpdateLoading(true);
                      await queryClient.invalidateQueries('get-jobs');
                      setJobUpdateLoading(false);
                    }
                    history.push('/my-jobs', {
                      view: decompressed?.view,
                      recentUpdated,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`w-${
          decompressed.filters != undefined ? '75' : '100'
        } cx-padding`}
      >
        {loading && (
          <div className="d-flex flex-column h-100">
            <Loader className="h-100" />
          </div>
        )}
        {!jobLoading && !loading && (
          <JobDetailCard
            onAction={onAction}
            showClipBoard={false}
            showActions={true}
            userInfo={userInfo}
            job={job}
          />
        )}
      </div>
    </>
  );
};

MyJobDetail.propTypes = {
  userInfo: PropTypes.any,
};

MyJobDetail.defaultProps = {
  userInfo: null,
};

export default MyJobDetail;
