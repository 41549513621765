const initialState = {
  loading: false,
  jobs: [],
  saved: [],
};

export default function jobs(state = initialState, action) {
  switch (action.type) {
    case 'GET_JOBS':
      return { ...state, loading: true };
    case 'GET_JOBS_SUCCESS':
      return { ...state, jobs: action.payload, loading: false };
    case 'GET_JOBS_FAILURE':
      return { ...state, loading: false };
    case 'GET_SAVED_JOBS':
      return { ...state, loading: true };
    case 'GET_SAVED_JOBS_SUCCESS':
      return { ...state, saved: action.payload, loading: false };
    case 'GET_SAVED_JOBS_FAILURE':
      return { ...state, loading: false };
    case 'SAVE_JOB':
      return { ...state, loading: true };
    case 'SAVE_JOB_SUCCESS':
      return { ...state, saved: action.payload, loading: false };
    case 'SAVE_JOB_FAILURE':
      return { ...state, loading: false };
    case 'FILTER_JOBS':
      return { ...state, loading: true };
    case 'FILTER_JOBS_SUCCESS':
      return { ...state, jobs: action.payload, loading: false };
    default:
      return state;
  }
}
