import React from 'react';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import './input-button.scss';

/**
 * This component is only to be used inside an InputGroup
 */
export const InputButton = ({
  children,
  style,
  className,
  position,
  isInvalid,
  ...otherProps
}) => (
  <Button
    tabIndex="-1"
    className={`btn-input ${className ? `${className}` : ''} input-btn-${position} ${
      isInvalid ? 'is-invalid' : ''
    }`}
    variant="outline-secondary"
    style={{
      ...style,
    }}
    {...otherProps}
  >
    {children}
  </Button>
);

InputButton.propTypes = {
  children: PropTypes.any,
  style: PropTypes.any,
  className: PropTypes.string,
  position: PropTypes.oneOf(['middle', 'end']),
  isInvalid: PropTypes.bool,
};
