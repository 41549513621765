import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { getJobs, saveJob } from '../dashboard/service';
import JobDetailCard from '../../components/job-detail-card';
import DetailSidebar from '../../components/detail-sidebar/detail-sidebar';
import { Modal } from 'react-bootstrap';
import Loader from '../../components/loader';
import { fetchJob } from '../../hoc/services/get-job';
import { toast } from 'react-toastify';
import { constructEncodedUrl, deconstructEncodedUrl } from '../../utils/helpers';
import { useSavedJobs } from '../../hooks/useSavedJobs';
import PropTypes from 'prop-types';
import { EditJob } from '../myJobs/editJob';
import { deleteJob, togglePublishState } from '../myJobs/service';
import { useAppliedJobs } from '../../hooks/useAppliedJobs';

const JobDetailPage = (props) => {
  const history = useHistory();
  const { userInfo } = props;
  const { id } = useParams();
  const decompressed = deconstructEncodedUrl(id);
  const [skip, setSkip] = useState(
    history.location.state?.from || decompressed?.filters?.from || 0
  );
  const [jobUpdateLoading, setJobUpdateLoading] = React.useState(false);
  const [editModalShow, setEditModalShow] = React.useState(false);
  const [limit] = useState(decompressed?.filters?.size || 5);
  const [savingJobId, setSavingJobId] = useState(null);
  const { appliedJobs } = useAppliedJobs(userInfo.user_type);
  const [unAvailablePost, setUnAvailablePost] = useState(
    decompressed?.filters?.unavilablePost || false
  );

  const {
    jobs: savedJobs,
    refetch: refetchSavedJobs,
    isFetching: SavedJobsFetching,
  } = useSavedJobs(userInfo.user_type);

  const { mutate } = useMutation(saveJob, {
    onSettled: () => refetchSavedJobs(),
    onMutate: (id) => setSavingJobId(id),
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const { data, isLoading } = useQuery(
    ['search-jobs', { ...decompressed.filters, from: skip, size: limit }],
    getJobs,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: decompressed.filters != undefined,
      useErrorBoundary: (error) => {
        return error.response.status === 401;
      },
      retry: (count, error) => {
        if (error.response.status === 401) {
          return false;
        } else if (count <= 3) {
          return true;
        } else {
          false;
        }
      },
    }
  );

  const {
    data: job,
    isLoading: jobLoading,
    isFetching,
  } = useQuery(['fetch-job', { id: decompressed.id }], fetchJob, {
    refetchOnMount: false,
    onSuccess: (data) => {
      if (data?.status == 'unpublished' || data?.status == 'deleted') {
        setUnAvailablePost(true);
      }
    },
    onError: () => {
      setUnAvailablePost(true);
    },
    refetchOnWindowFocus: false,
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const queryClient = useQueryClient();

  const onDeleteJob = useMutation(deleteJob, {
    onMutate: () => {
      setJobUpdateLoading(true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries('get-jobs');
      history.push('/my-jobs');
      toast.success('Successfully deleted');
    },
    onError: () => {
      setJobUpdateLoading(false);
      toast.error("Couldn't delete");
    },
  });

  const { mutate: togglePublish } = useMutation(togglePublishState, {
    onSuccess: async (response, variables) => {
      await queryClient.invalidateQueries('fetch-job');
      toast.success(
        variables.initialStatus == 'unpublished'
          ? 'Published succesfully'
          : 'Unpublished succesfully'
      );
      setJobUpdateLoading(false);
    },
    onError: () => {
      setJobUpdateLoading(false);
      toast.error('Something went wrong');
    },
    useErrorBoundary: (error) => {
      return error?.response?.status === 401;
    },
    retry: (count, error) => {
      if (error?.response?.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const inArray = (id, array) => {
    return array?.find((key) => key === id) ? true : false;
  };

  const onPaginate = (type) => {
    if (type === 'next' && !(data?.jobs?.length < limit)) {
      history.push(history.location.pathname, { from: skip + limit });
    } else if (type === 'prev' && skip) {
      history.push(history.location.pathname, { from: skip - limit });
    } else {
      return;
    }
  };

  const onJobSelected = (jobId, params) => {
    return constructEncodedUrl(
      {
        id: jobId,
        filters: { ...decompressed.filters, from: skip, size: limit, ...params },
      },
      'job'
    );
  };

  const onAction = (type, id, status) => {
    switch (type) {
      case 'delete':
        onDeleteJob.mutate({ id });
        break;
      case 'edit':
        setEditModalShow(true);
        break;
      case 'togglePublish':
        setJobUpdateLoading(true);
        togglePublish({ id, initialStatus: status });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!SavedJobsFetching) {
      if (savingJobId !== null) {
        setSavingJobId(null);
      }
    }
  }, [SavedJobsFetching]);

  useEffect(() => {
    if (history.location.state?.from === 0) {
      setSkip(0);
    }
  }, [history.location.state?.from]);

  if (isLoading) {
    return (
      <div className="detail-loader">
        <Loader />
      </div>
    );
  }

  if (
    (unAvailablePost || (decompressed.filters == undefined && unAvailablePost)) &&
    !jobLoading &&
    job?.author_id !== userInfo?.id
  ) {
    return (
      <div className="card w-100 text-left p-4">
        <h4 className="bb">About Job</h4>
        <p className="mb-0 text-grey">This position is no longer available</p>
      </div>
    );
  }

  return (
    <>
      {decompressed.filters != undefined && (
        <div className="w-320px">
          <div className="row sticky-header">
            <div className="col-xs-12 mb-2 col-md-12 col-xl-12">
              <div className="sticky-header">
                <DetailSidebar
                  greyOut={true}
                  header="Edit Filters"
                  onPaginate={onPaginate}
                  data={{ ...data, skip, limit }}
                  bodyKey="description"
                  selectedItemId={decompressed.id}
                  onSelectItem={onJobSelected}
                  onNavigateBack={() =>
                    history.push('/jobs', {
                      view: decompressed.view,
                      skip: decompressed.filters.from,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`w-${
          decompressed.filters != undefined ? '75' : '100'
        } cx-padding`}
      >
        {jobLoading && (
          <div className="d-flex flex-column h-100">
            <Loader className="h-100" />
          </div>
        )}
        {jobUpdateLoading && <Loader className="my-job-detail-loader" />}
        <Modal
          className="create-job-modal"
          show={editModalShow}
          animation="false"
          onHide={() => setEditModalShow(false)}
        >
          <EditJob
            userInfo={userInfo}
            inDetail
            data={job}
            onClose={() => setEditModalShow(false)}
            setLoading={setJobUpdateLoading}
          />
        </Modal>
        {!jobLoading && (
          <JobDetailCard
            userInfo={userInfo}
            job={job}
            mutate={userInfo?.user_type == 'candidate' && mutate}
            savedJobs={savedJobs?.map((i) => i.id)}
            appliedJobs={appliedJobs}
            onAction={onAction}
            showClipBoard={job.author_id !== userInfo?.id}
            showActions={job.author_id == userInfo?.id}
            applied={inArray(
              decompressed.id,
              appliedJobs?.map((i) => i?.id)
            )}
            savingJobId={savingJobId}
          />
        )}
      </div>
    </>
  );
};

JobDetailPage.propTypes = {
  userInfo: PropTypes.any,
};

JobDetailPage.defaultProps = {
  userInfo: null,
};

export default JobDetailPage;
