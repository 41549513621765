import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap';
import CardHeader from '../card-header';
import { useHistory, Link } from 'react-router-dom';
import { useMessageThreads } from '../../context/message-threads-context';
import { Modal } from 'react-bootstrap';
import { useApplyJob } from '../../context/apply-job-context';
import { constructEncodedUrl } from '../../utils/helpers';
import ReactTooltip from 'react-tooltip';
import { FiInfo } from 'react-icons/fi';
import './index.scss';

const JobDetailCard = ({
  job,
  mutate,
  onAction,
  savingJobId,
  ownJob,
  savedJobs,
  applied,
  appliedJobs,
  showClipBoard,
  showActions,
  userInfo,
}) => {
  const { toggleApplyJob } = useApplyJob();
  const {
    openThreadModal,
    setSelectedJobFilter,
    setShowApplied,
    setJobData,
    contactedUsers,
  } = useMessageThreads();

  const [url, setUrl] = React.useState(job?.company_website);

  const onMoreCandidates = () => {
    window.open(
      constructEncodedUrl(
        {
          filters: {
            skills: job?.skills,
            employmentOptions: job?.employment_options,
            languages: job?.languages,
            experience: job?.experience,
            salary: job?.salary,
            geos: job?.locations,
          },
        },
        'candidates',
        'new'
      )
    );
  };

  const getPostingDay = () => {
    if (moment().diff(moment(job?.created_at), 'days') < 3) {
      return `Posted ${moment(job?.created_at).fromNow()}`;
    } else {
      return `Posted on ${moment(job?.created_at).format('DD MMMM, YYYY')}`;
    }
  };

  const [deleteModalShow, setDeleteModalShow] = React.useState(false);
  const [allOpenAccordion, setOpenAccordion] = React.useState([]);

  const toggleAccordian = (e) => {
    const { id } = e.currentTarget;
    allOpenAccordion.includes(id)
      ? setOpenAccordion(allOpenAccordion.filter((i) => i !== id))
      : setOpenAccordion([...allOpenAccordion, id]);
  };

  React.useEffect(() => {
    if (!url?.match(/^https?:\/\//i)) {
      setUrl('http://' + url);
    }

    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="row job-detail-card mb-2 ">
      {userInfo?.user_type === 'recruiter' && (
        <Modal
          centered
          show={deleteModalShow}
          animation="false"
          onHide={() => setDeleteModalShow(false)}
        >
          <Modal.Body>
            <div className="text-left">
              <h3 className="mb-4 fw-700 text-truncate">{job?.title}</h3>
              <p className="mb-4">Are you sure you want to delete this job?</p>
              {job?.status == 'published' && (
                <p className="text-grey mb-4">
                  Note: You can always{' '}
                  <a
                    style={{ color: '#454fb4', textDecoration: 'underline' }}
                    role="button"
                    onClick={() => {
                      onAction('togglePublish', job?.id, job?.status);
                      setDeleteModalShow(false);
                    }}
                  >
                    Unpublish
                  </a>{' '}
                  it which will hide it from candidates
                </p>
              )}
              <div className="d-flex align-items-center justify-content-between">
                <button
                  onClick={() => setDeleteModalShow(false)}
                  className="btn btn-outline btn-primary btn-rounded px-4"
                >
                  Cancel
                </button>
                <button
                  onClick={() =>
                    onAction('delete', job?.id) + setDeleteModalShow(false)
                  }
                  className="btn btn-primary btn-rounded text-white px-4"
                >
                  Delete
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <div className="col-xs-12 col-md-12 col-xl-12">
        <div className="card">
          <CardHeader
            id={job?.id}
            userInfo={userInfo}
            contactedJobs={contactedUsers?.map((i) => i?.job_id)}
            inJobDetail={true}
            title={job?.title}
            onSave={mutate}
            appliedJobs={appliedJobs}
            showActions={showActions}
            onOpenConverSation={() => {
              setSelectedJobFilter([{ id: job?.id, title: job?.title }]);
              openThreadModal();
            }}
            moreCandidates={onMoreCandidates}
            seeApplicants={() => {
              setSelectedJobFilter([{ id: job?.id, title: job?.title }]);
              setShowApplied(true);
              openThreadModal();
            }}
            showCLipboard={showClipBoard}
            saved={savedJobs?.includes(job?.id)}
            onDelete={() => setDeleteModalShow(true)}
            onEdit={() => onAction('edit', job?.id)}
            savingId={savingJobId}
            onTogglePublish={() => onAction('togglePublish', job?.id, job?.status)}
            published={job?.status == 'published'}
            onMessage={
              userInfo?.user_type == 'candidate'
                ? (id) => {
                    openThreadModal(job.id);
                    setJobData(job);
                  }
                : null
            }
            companyName={job?.company_name}
            experience={job?.experience}
            locations={job?.locations.map((l) => l.name)}
            employmentOptions={job?.employment_options.map((e) => e.name)}
            salary={job?.salary}
          />
          <Accordion
            id="accordion"
            className="accordion-controller no-sidebar py-0 px-3 border-bottom"
            defaultActiveKey="0"
          >
            <div className="card">
              <div className="card-header" id="headingOne">
                <p className="mb-0">
                  <Accordion.Toggle
                    id="0"
                    eventKey="0"
                    onClick={toggleAccordian}
                    className={`btn accordion-button accordion-button-sm ${
                      allOpenAccordion.includes('0') && 'collapsed'
                    } right-0 font-weight-bold`}
                    data-toggle="collapse"
                    aria-expanded="true"
                  >
                    About the Job
                  </Accordion.Toggle>
                </p>
              </div>
              <Accordion.Collapse eventKey="0">
                <div className="card-body">
                  <p className="text-grey preserve-line">{job?.description}</p>
                </div>
              </Accordion.Collapse>
            </div>
          </Accordion>
          <div className="w-100 px-3 border-bottom additional-info mt-2">
            {job?.skills && (
              <div className="d-flex row align-items-center my-1">
                <label className="col-2 pr-0 bb mb-0">Skills</label>
                <div className="col-10 px-0">
                  {job?.skills?.map((s, i) => (
                    <span key={i} className="badge badge-secondary p-1 fs-14">
                      {s.name}
                    </span>
                  ))}
                </div>
              </div>
            )}
            {job?.salary && (
              <div className="d-flex row align-items-center my-1">
                <label className="bb mb-0 col-2 pr-0">
                  Salary{' '}
                  <FiInfo
                    className="salary-tooltip"
                    data-tip
                    data-for="password-info"
                  />
                  <ReactTooltip
                    id="password-info"
                    type="light"
                    effect="solid"
                    place="top"
                    className="tooltip-shadow"
                  >
                    <p className="p-1 pb-0 mb-0">Monthly gross salary in USD</p>
                  </ReactTooltip>
                </label>
                <div className="col-10 px-0">
                  ${job?.salary}{' '}
                  {userInfo.user_type == 'recruiter' ? (
                    <span>
                      {job.author_id == userInfo?.id &&
                        (job?.show_salary ? '( Public )' : '( Private )')}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            )}
            {job?.languages && (
              <div className="d-flex row align-items-center my-1">
                <label className="bb mb-0 col-2 pr-0">Languages</label>
                <div className="col-10 px-0">
                  {job?.languages?.map((l, i) => (
                    <span key={i} className="badge badge-secondary p-1 fs-14">
                      {l.name}
                    </span>
                  ))}
                </div>
              </div>
            )}
            {job?.locations && (
              <div className="d-flex row align-items-center my-1">
                <label className="bb mb-0 col-2 pr-0">Locations</label>
                <div className="col-10 px-0">
                  {job?.locations?.map((s, i) => (
                    <span key={i} className="badge badge-secondary p-1 fs-14">
                      {s.name}
                    </span>
                  ))}
                </div>
              </div>
            )}
            {job?.employment_options && (
              <div className="d-flex row align-items-center my-1">
                <label
                  className="bb mb-0 col-2 pr-0"
                  style={{ wordBreak: 'keep-all' }}
                >
                  Employment option
                </label>
                <div className="col-10 px-0">
                  {job?.employment_options?.map((s, i) => (
                    <span key={i} className="badge badge-secondary p-1 fs-14">
                      {s.name}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
          <Accordion
            id="accordion2"
            className="accordion-controller no-sidebar py-0 px-3 border-bottom"
            defaultActiveKey="0"
          >
            <div className="card">
              <div className="card-header" id="headingOne">
                <p className="mb-0">
                  <Accordion.Toggle
                    id="1"
                    eventKey="0"
                    onClick={toggleAccordian}
                    className={`btn accordion-button accordion-button-sm ${
                      allOpenAccordion.includes('1') && 'collapsed'
                    } right-0 font-weight-bold`}
                    data-toggle="collapse"
                    aria-expanded="true"
                  >
                    About the company
                  </Accordion.Toggle>
                </p>
              </div>
              <Accordion.Collapse eventKey="0">
                <div className="card-body">
                  <p className="text-grey preserve-line">{job?.about_company}</p>
                </div>
              </Accordion.Collapse>
            </div>
          </Accordion>
          {job?.company_website && (
            <div className="px-3 mt-3">
              <h5 className="bb">Company Website</h5>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={url}
                className="text-primary cursor-pointer"
              >
                {job?.company_website}
              </a>
            </div>
          )}
          <div className="px-3 date mt-3">
            <div className="p-3 d-flex align-items-center border-rounded">
              <div className="mr-4 item">
                <h5 className="bb mb-2">Date</h5>
                <p className="mb-0">{getPostingDay()}</p>
              </div>
              <div className="mx-4 item">
                <h5 className="bb mb-2">Posted By</h5>
                <Link
                  to={constructEncodedUrl({ id: job?.author_id }, 'recruiter')}
                  className="mb-0 author"
                  target="_blank"
                >
                  {job?.author_name}
                </Link>
              </div>
            </div>
          </div>
          <div className="card-footer bg-white d-flex justify-content-end border-top-0">
            {userInfo?.user_type == 'candidate' ? (
              <>
                <button
                  className="btn btn-outline btn-primary btn-rounded mr-2"
                  onClick={(id) => {
                    openThreadModal(job.id);
                    setJobData(job);
                  }}
                >
                  {contactedUsers?.map((i) => i?.job_id)?.includes(job?.id)
                    ? 'Messages'
                    : 'Ask Questions'}
                </button>
                <button
                  className="btn btn-primary btn-rounded text-white"
                  disabled={applied}
                  {...(applied && {
                    style: { backgroundColor: '#666', borderColor: '#666' },
                  })}
                  onClick={() =>
                    !applied &&
                    toggleApplyJob({ id: job.id, title: job.title }, userInfo)
                  }
                >
                  {applied ? 'Applied' : 'Apply'}
                </button>
              </>
            ) : job?.author_id == userInfo?.id ? (
              <>
                <button
                  className="btn btn-outline btn-primary btn-rounded mr-2"
                  onClick={() => {
                    setSelectedJobFilter([{ id: job?.id, title: job?.title }]);
                    setShowApplied(true);
                    openThreadModal();
                  }}
                >
                  Applicants
                </button>
                <button
                  className="btn btn-primary btn-rounded text-white"
                  onClick={onMoreCandidates}
                >
                  More Candidates
                </button>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

JobDetailCard.propTypes = {
  job: PropTypes.object,
  mutate: PropTypes.any,
  onAction: PropTypes.func,
  savingJobId: PropTypes.string,
  appliedJobs: PropTypes.array,
  savedJobs: PropTypes.array,
  applied: PropTypes.bool,
  showClipBoard: PropTypes.bool,
  ownJob: PropTypes.bool,
  showActions: PropTypes.bool,
  userInfo: PropTypes.any,
};

JobDetailCard.defaultProps = {
  job: null,
  mutate: null,
  onAction: null,
  savingJobId: '',
  appliedJobs: [],
  savedJobs: [],
  applied: false,
  ownJob: false,
  showClipBoard: true,
  showActions: false,
  userInfo: null,
};

export default JobDetailCard;
