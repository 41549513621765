import React, { useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import logo from '../images/JobTerix-full.svg';
import defaultAvatar from '../images/default-avatar.svg';
import { useMessageThreads } from '../context/message-threads-context';
import dropdown from '../images/Icons/dropdown.svg';
import './header.scss';
import { Dropdown } from 'react-bootstrap';
import { useAuth } from '../context/auth-context';
import PropTypes from 'prop-types';
import { GET_ALL_UNREAD_THREADS } from '../utils/graphql';
import { useQuery as apolloUseQuery } from '@apollo/client';

const Header = ({ userDetails }) => {
  const auth = useAuth();
  const [userType] = React.useState(userDetails.user_type);
  const { openThreadModal, setContactedUsers } = useMessageThreads();
  const {
    loading: gqlLoading,
    error: gqlError,
    data: gqlData,
  } = apolloUseQuery(GET_ALL_UNREAD_THREADS, {
    pollInterval: 60000,
    fetchPolicy: 'no-cache',
    variables: {
      limit: 100,
      nextToken: null,
    },
  });

  const logout = () => {
    auth.logout();
  };

  const showUnreadMessages = () => {
    let unreadMessages = 0;
    if (gqlData && !gqlLoading) {
      unreadMessages = gqlData?.getAllThreads?.items.filter(
        (message) => message.unread
      )?.length;
    }
    if (unreadMessages > 0) {
      return <span>1</span>;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (gqlData && !gqlLoading) {
      setContactedUsers(gqlData?.getAllThreads?.items);
    }
  }, [gqlData]);

  const generateLinkLable = (userType) =>
    userType === 'candidate' ? 'Jobs' : 'Candidates';

  const generateRoute = (userType) =>
    userType === 'candidate' ? '/jobs' : '/candidates';

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white">
      <div className="container-lg">
        <a className="navbar-brand" href="/">
          <img src={logo} />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav my-auto mb-2 sep-left mb-lg-0">
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to={generateRoute(userDetails.user_type)}
              >
                {generateLinkLable(userDetails.user_type)}
              </NavLink>
            </li>
            <li className="nav-item">
              {userType == 'recruiter' && (
                <NavLink className="nav-link" to="/my-jobs">
                  My Jobs
                </NavLink>
              )}
            </li>
            <li className="nav-item" onClick={() => openThreadModal()}>
              <p className="nav-link mb-0 messages-button" role="button">
                {showUnreadMessages()}
                Messages
              </p>
            </li>
          </ul>
          <Dropdown className="header-dropdown">
            <Dropdown.Toggle
              as={'div'}
              className="row cursor-pointer"
              id="dropdown-basic"
            >
              <div className="col pr-0 px-2">
                <img
                  className="user-avatar"
                  src={userDetails?.avatar_url || defaultAvatar}
                />
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <span className="e-mail">{userDetails?.email}</span>
              <Dropdown.Divider></Dropdown.Divider>
              <Dropdown.Item>
                <Link to="/profile">Profile</Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <button
                  type="button"
                  className="btn p-0"
                  data-dismiss="modal"
                  onClick={() => {
                    window.open(
                      'mailto:info@jobterix.com?subject="Support request"',
                      '_self'
                    );
                  }}
                >
                  Help
                </button>
              </Dropdown.Item>
              <Dropdown.Item>
                <button type="button" className="btn p-0" data-dismiss="modal">
                  Preferences
                </button>
              </Dropdown.Item>
              <Dropdown.Divider></Dropdown.Divider>
              <Dropdown.Item>
                <button
                  onClick={logout}
                  type="button"
                  className="btn p-0"
                  data-dismiss="modal"
                >
                  {'Sign out'}
                </button>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </nav>
  );
};

Header.propTypes = {
  userDetails: PropTypes.any,
};

export default Header;
