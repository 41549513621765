import React, { useState } from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import Loader from '../../components/loader';
import classNames from 'classnames';
import upload from '../../images/Icons/upload.svg';
import { toast } from 'react-toastify';
import slideClose from '../../images/Icons/close-black.svg';
import { updateUser } from '../profile/service';
import { APPLY_TO_JOB } from '../../utils/graphql';
import { useMutation as apolloUseMutation } from '@apollo/client';

import { useMessageThreads } from '../../context/message-threads-context';
import { useLists } from '../../context/lists-context';

import { useApplyJob } from '../../context/apply-job-context';
import { useQueryClient, useMutation } from 'react-query';
import { allowedTypes } from '../../utils/constants';

/* eslint-disable react/prop-types */

const ApplyJob = (props) => {
  const {
    toggleApplyJob,
    job: { id },
    showWarning,
    setShowMessages,
    setShowApplyJob,
  } = useApplyJob();
  const { userInfo } = props;
  const { openThreadModal } = useMessageThreads();
  const [dragOver, setDragOver] = useState(false);
  const [update, setUpdate] = React.useState(false);
  const [fileName, setFileName] = useState(null);
  const [file, setFile] = useState(null);
  const [updateProfileLoading, setUpdateProfileLoading] = useState(false);
  const queryClient = useQueryClient();
  const { setIsAppliedJobsInvalidated } = useLists();

  const [apply] = apolloUseMutation(APPLY_TO_JOB, {
    variables: {
      job_id: '',
    },
    onCompleted: async (data) => {
      openThreadModal(id, data.applyToJob.thread_id);
      await queryClient.invalidateQueries('get-auth0-profile');
      setIsAppliedJobsInvalidated(true);
      await queryClient.invalidateQueries('fetch-applied-jobs');
      setIsAppliedJobsInvalidated(false);
      setShowMessages(true);
    },
    onError: (e) => console.log(e),
  });

  const { mutate } = useMutation(updateUser, {
    onMutate: (data) => {
      setUpdateProfileLoading(true);
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries('get-auth0-profile');
      toast.success('Profile Updated Succesfully');
      onSubmit();
    },
    useErrorBoundary: (error) => {
      return error.response?.status === 401;
    },
    retry: (count, error) => {
      if (error.response?.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onSubmit = (e) => {
    setShowMessages(false);
    setShowApplyJob(false);
    openThreadModal(id);
    apply({
      variables: { job_id: id },
    });
    if (e) {
      e.preventDefault();
    }
  };

  const onProfileUpdate = async () => {
    let resume = null;
    if (file) {
      resume = await toBase64(file[0]);
    } else {
      return;
    }
    mutate({
      ...userInfo,
      resume_doc: resume,
    });
  };

  const handleResumeUpload = (e) => {
    if (allowedTypes.candidate.resume.indexOf(e.target?.files[0]?.type) > -1) {
      e.target?.files[0]?.name && setFileName(e.target.files[0].name);
      setFile(e.target.files);
      update && setUpdate(false);
    } else {
      toast.error('only .pdf format is allowed');
    }
  };

  return (
    <div className="popup-form overflow-auto w-100 h-100 d-flex flex-column justify-content-center align-items-center">
      <div className="content-holder rounded position-relative bg-white px-4 py-3">
        {updateProfileLoading && (
          <div className="card border-0">
            <div className="card-body justify-content-center d-flex flex-column align-items-center">
              <h3 className="mb-4">Updating Profile</h3>
              <Loader />
            </div>
          </div>
        )}
        {!updateProfileLoading && (
          <React.Fragment>
            <p className="mb-3 note">
              {showWarning && 'Are you sure you want to apply to this position?'}
              {!showWarning &&
                'To apply to this position you need to upload your resume'}
            </p>
            <div
              onDrop={() => {
                setDragOver(false);
              }}
              onDragEnter={() => {
                setDragOver(true);
              }}
              onDragLeave={() => {
                setDragOver(false);
              }}
              style={{ padding: '1rem 4rem' }}
              className={classNames(
                'dash-border position-relative orange-border d-flex flex-column justify-content-center align-items-center',
                { 'drag-over': dragOver }
              )}
            >
              {!update && (userInfo?.resume_link || fileName) && (
                <div
                  className="d-flex align-items-center position-relative overflow-hidden border-grey"
                  style={{
                    zIndex: 5,
                    cursor: fileName == null ? 'pointer' : 'default',
                    borderRadius: '0.5rem',
                    width: '14rem',
                  }}
                  onClick={() => {
                    if (fileName == null) {
                      window.open(userInfo?.resume_link, '_blank');
                    }
                  }}
                >
                  <div className="download-cv position-absolute d-flex align-items-center justify-content-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="#666666"
                      stroke="#666666"
                      strokeWidth="1"
                      className="bi bi-download mr-2  text-grey"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                    <span className="d-block bb text-grey">Download</span>
                  </div>
                  <span
                    className="d-block w-25 p-4 bb d-flex align-items-center justify-content-center text-white"
                    style={{ backgroundColor: '#cc1016', letterSpacing: '1px' }}
                  >
                    PDF
                  </span>
                  <span className="d-block ml-3">
                    {fileName || userInfo?.resume_name || 'Resume'}
                  </span>
                  <img
                    className="close-icon ml-auto p-2 mr-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      setFile(null);
                      setFileName(null);
                      setUpdate(true);
                    }}
                    src={slideClose}
                    alt="close"
                  />
                </div>
              )}
              {((showWarning && !userInfo?.resume_link) ||
                (!fileName && !showWarning) ||
                update) && (
                <React.Fragment>
                  <img src={upload} />
                  <div className="m-0">
                    <div>
                      <input
                        className="upload-zone"
                        id="resume"
                        name="resume"
                        accept=".pdf"
                        type="file"
                        onChange={handleResumeUpload}
                        defaultValue={file}
                      />
                    </div>
                    <a style={{ zIndex: '11111' }} href="#">
                      Upload your resume
                    </a>{' '}
                    or drag and drop it here
                  </div>
                  <p className="m-0">(only .pdf format is allowed)</p>
                </React.Fragment>
              )}
            </div>
            <div className="d-flex justify-content-between mt-4 mb-2">
              <button
                className="btn btn-outline btn-primary btn-rounded px-4 py-1 mx-1"
                onClick={() => toggleApplyJob()}
              >
                Cancel
              </button>
              <button
                disabled={
                  (!showWarning && !file) || (showWarning && update && !file)
                }
                className="btn btn-primary btn-rounded px-4 py-1 text-white"
                onClick={(e) => {
                  if (showWarning) {
                    !fileName && !update ? onSubmit(e) : onProfileUpdate();
                  } else if (!showWarning && file) {
                    onProfileUpdate();
                  }
                }}
                id="applyJobButton"
              >
                Apply
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
ApplyJob.propTypes = {
  close: PropTypes.func,
  job: PropTypes.object,
};
export default ApplyJob;
