import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';
import { updateJob } from './service';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import { JobForm } from './form/jobForm';
import { useForm } from 'react-hook-form';

export const EditJob = ({
  data,
  onClose,
  setLoading,
  userInfo,
  inDetail = false,
}) => {
  const {
    register,
    formState: { errors },
    control,
    trigger,
    getValues,
  } = useForm({
    defaultValues: {
      title: data?.title || '',
      company_name: data?.company_name || '',
      experience: data?.experience || 0,
      qualifications: data?.qualifications || '',
      salary: data?.salary || 0,
      description: data?.description || '',
      show_salary: data?.show_salary || false,
      employment_options: data?.employment_options || [],
      skills: data?.skills || [],
      languages: data?.languages || [],
      locations: data?.locations || [],
    },
  });

  const queryClient = useQueryClient();

  const { mutate } = useMutation(updateJob, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(inDetail ? 'fetch-job' : 'get-jobs');
      setLoading(false);
      onClose();
      toast.success('Successfully Updated');
    },
    onError: () => {
      setLoading(false);
      toast.error("Couldn't Update");
    },
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    const {
      title,
      experience,
      salary,
      description,
      show_salary,
      employment_options,
      skills,
      languages,
      locations,
    } = getValues();

    await trigger([
      'title',
      'experience',
      'salary',
      'description',
      'skills',
      'employment_options',
      'languages',
      'locations',
    ]).then((noError) => {
      if (noError) {
        setLoading(true);
        mutate({
          id: data?.id,
          title,
          experience: parseInt(experience),
          salary: parseInt(salary),
          description,
          show_salary,
          employment_options,
          skills,
          languages,
          locations,
          qualifications: '',
          company_name: userInfo.company_name || '',
          company_website: userInfo.company_website || '',
          about_company: userInfo.about_company || '',
        });
      }
    });
  };

  return (
    <div className="create-job py-3">
      <div className="card py-2 px-3 mb-3">
        <h2 className="mb-1">Job Details</h2>
        <p className="mb-0">
          Please describe your position in great details to attract more candidates
        </p>
      </div>
      <div className="card py-2 px-3">
        <Form id="editJob" onSubmit={onSubmit}>
          <JobForm
            control={control}
            errors={errors}
            firsFieldLength={data && data.description}
            register={register}
          />
        </Form>
      </div>
      <div className="d-flex align-items-center mt-4">
        <button
          type="button"
          className="btn btn-outline btn-primary btn-rounded px-4 py-1"
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        >
          Cancel
        </button>
        <button
          form="editJob"
          type="submit"
          className="btn btn-primary btn-rounded text-white px-4 py-1 ml-auto"
        >
          Update job
        </button>
      </div>
    </div>
  );
};

EditJob.propTypes = {
  data: PropTypes.any,
  onClose: PropTypes.func,
  inDetail: PropTypes.bool,
  setLoading: PropTypes.func,
  userInfo: PropTypes.any,
};
