import React, { useEffect, useState } from 'react';
import '../dashboard/styles.scss';
import './styles.scss';
import Select from 'react-select';
import slideClose from '../../images/Icons/slide-close.svg';
import Loader from '../../components/loader';
import Overlay from '../../components/common/modal';
import { InvitationPage } from './invitation-page';
import { getJobs } from '../myJobs/service';
import { useQuery } from 'react-query';
import search from '../../images/Icons/search_lens.svg';
import { useMessageThreads } from '../../context/message-threads-context';
import moment from 'moment';
import { constructEncodedUrl, isCandidate, isRecruiter } from '../../utils/helpers';
import Thread from './thread';
import ContentLoader from 'react-content-loader';
import MessageDetails from './message-details-card';
import PropTypes from 'prop-types';
import { useAppliedJobs } from '../../hooks/useAppliedJobs';

import {
  useLazyQuery as apolloUseQuery,
  useMutation as apolloUseMutation,
  useApolloClient,
} from '@apollo/client';
import { CREATE_THREAD, GET_ALL_THREADS, SEND_MESSAGE } from '../../utils/graphql';
import { escape } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
const Threads = (props) => {
  const { userInfo } = props;
  const {
    jobId,
    selectedJobFilter,
    setSelectedJobFilter,
    setJobId,
    closeThreadModal,
    candidateId,
    recruiterId,
    setCandidateId,
    setRecruiterId,
    candidateData,
    setThreadId,
    threadId,
    showApplied,
    setShowApplied,
    jobData,
    contactedUsers,
  } = useMessageThreads();
  /**
   * Below applied jobs logic needs to go
   */
  const { appliedJobs } = useAppliedJobs(userInfo.user_type);
  const appliedJobsIds = appliedJobs ? appliedJobs?.map((j) => j.id) : [];

  const [threads, setThreads] = useState([]);
  const [searchFilterValue, setSearchFilterValue] = useState('');
  const [messageContent, setMessageContent] = useState('');
  const [showInvitationPage, setShowInvitationPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();
  const [nextToken, setNextToken] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [gqlData, setgqlData] = useState(null);
  const [resetThreads, setResetThreads] = useState(false);
  const [loadMessages, { loading: gqlLoading, error: gqlError, fetchMore }] =
    apolloUseQuery(GET_ALL_THREADS, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (gqlData && !resetThreads) {
          setgqlData({
            getAllThreads: {
              ...data.getAllThreads,
              items: [
                ...gqlData?.getAllThreads?.items,
                ...data?.getAllThreads?.items,
              ],
            },
          });
        } else {
          setgqlData(data);
          resetThreads && setResetThreads(false);
        }
      },
      errorPolicy: 'all',
      variables: {
        nextToken: null,
        limit: 10,
      },
    });

  useEffect(() => {
    if (gqlData) {
      /**
       * OH MY GOD WHAT IS THIS EVEN!
       * All because React cannot handle an array like this
       * [
       *  {name:'something'},
       *  {name:'that wasted'},
       *  {name:'three hours of my time'}
       * ]
       */
      const restructuredThreads = gqlData.getAllThreads.items.map((thread) => {
        if (thread == null) {
          return;
        }
        let { candidate, job } = thread;
        const transform = (items) => (items ? items.map((item) => item.name) : []);
        return {
          ...thread,
          candidate: {
            ...candidate,
            locations: transform(candidate.locations),
            employment_options: transform(candidate.employment_options),
          },
          job: {
            ...job,
            locations: transform(job.locations),
            employment_options: transform(job.employment_options),
          },
        };
      });
      const allThreads = [...restructuredThreads];
      if (!gqlData.getAllThreads.nextToken) {
        setHasMore(false);
      }
      if (gqlData.getAllThreads.nextToken) {
        setHasMore(true);
      }
      setNextToken(gqlData.getAllThreads.nextToken);
      setThreads(allThreads);
    }
  }, [gqlData]);

  const [createThread] = apolloUseMutation(CREATE_THREAD, {
    variables: {
      job_id: '',
      candidate_id: '',
    },
    onCompleted: async (data) => {
      const {
        createThread: { thread_id, job_id },
      } = data;
      await sendMessage({
        variables: {
          content: escape(messageContent),
          content_type: 'text',
          thread_id: thread_id,
        },
      });
      setLoading(false);
      setThreadId(thread_id);
      setJobId(job_id);
    },
  });

  const [sendMessage] = apolloUseMutation(SEND_MESSAGE, {
    variables: {
      thread_id: threadId,
      content: '',
      content_type: 'text',
    },
  });

  const filterThreads = (threads) => {
    const selectedJobIds = selectedJobFilter.map((job) => job.id);
    let filteredThreads = threads;
    const lowerCaseSearchValue = searchFilterValue.toLowerCase();
    if (selectedJobIds.length > 0) {
      filteredThreads = filteredThreads.filter((thread) =>
        selectedJobIds.includes(thread?.job_id)
      );
    }
    if (showApplied) {
      filteredThreads = filteredThreads.filter((thread) => thread?.applied === true);
    }
    if (searchFilterValue) {
      if (isCandidate(userInfo.user_type)) {
        filteredThreads = filteredThreads.filter((thread) =>
          thread?.job?.title?.toLowerCase()?.includes(lowerCaseSearchValue)
        );
      } else if (isRecruiter(userInfo.user_type)) {
        filteredThreads = filteredThreads.filter((thread) => {
          if (thread?.applied) {
            if (thread?.candidate?.name) {
              return thread?.candidate?.name
                ?.toLowerCase()
                ?.includes(lowerCaseSearchValue);
            }
          }
          return thread?.candidate?.designation
            ?.toLowerCase()
            ?.includes(lowerCaseSearchValue);
        });
      }
    }

    return filteredThreads;
  };

  useEffect(() => {
    if (!threadId && !candidateData && !jobData) {
      loadMessages({ variables: { limit: 10, nextToken: null } });
    }
  }, []);
  useEffect(() => {
    if (resetThreads) {
      loadMessages({ variables: { limit: 10, nextToken: null } });
    }
  }, [resetThreads]);

  useEffect(() => {
    if ((jobId != null && threadId == null) || candidateData) {
      if (contactedUsers) {
        /**
         * Also filter by candidate ID
         */
        let threads = contactedUsers;
        let thread;
        if (isRecruiter(userInfo.user_type)) {
          [thread] = threads.filter((item) => item.candidate_id === candidateId);
        } else {
          [thread] = threads.filter((item) => item.job_id === jobId);
        }
        if (thread) {
          setThreadId(thread.thread_id);
          setJobId(thread.job_id);
        } else {
          setShowInvitationPage(true);
        }
      }
    }
  }, [jobId, contactedUsers]);

  const { data: myJobs, isLoading } = useQuery(['get-jobs'], getJobs, {
    refetchOnWindowFocus: false,
    enabled: isRecruiter(userInfo.user_type),
    useErrorBoundary: (error) => {
      return error.response?.status === 401;
    },
    retry: (count, error) => {
      if (error.response?.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const showDetails = (id) => {
    if (isRecruiter(userInfo.user_type) && id) {
      return constructEncodedUrl({ id }, 'job');
    }
    if (candidateData) {
      window.open(constructEncodedUrl({ id: candidateId }, 'candidate'));
    } else if (jobData) {
      window.open(constructEncodedUrl({ id: jobData?.id }, 'job'));
    }
  };

  const sendInvite = async (messageContent, selectedJobId, selectedCandidateId) => {
    setLoading(true);
    try {
      await createThread({
        variables: {
          job_id: selectedJobId,
          candidate_id: selectedCandidateId,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };
  const searchFn = (e) => {};

  const setMessageInfo = (thread) => {
    setJobId(thread.job_id);
    setCandidateId(thread.candidate_id);
    setRecruiterId(thread.recruiter.id);
    setThreadId(thread.thread_id);
  };

  const threadProps = (thread) => {
    let props = {
      title: '',
      lastMessage: '',
      locations: '',
      experience: '',
      employmentOptions: '',
      salary: '',
    };
    const { job, candidate } = thread;
    if (isCandidate(userInfo.user_type)) {
      props = {
        ...props,
        title: job.title,
        jobTitle: '',
        lastMessage: job.description,
        locations: job.locations,
        experience: job.experience,
        status: job.status,
        employmentOptions: job.employment_options,
        salary: job.salary,
      };
    } else if (isRecruiter(userInfo.user_type)) {
      props = {
        ...props,
        title: candidate.name || candidate.designation,
        status: job.status,
        jobTitle: job.title,
        lastMessage: candidate.about_professional_experience,
        locations: candidate.locations,
        experience: candidate.work_experience,
        employmentOptions: candidate.employment_options,
        salary: candidate.salary_expectations,
      };
    }

    return props;
  };

  return (
    <Overlay
      close={() => {
        if (threadId) {
          client.refetchQueries({
            include: ['GetAllUnreadThreads'],
          });
        }
        closeThreadModal();
      }}
    >
      {((!showInvitationPage && candidateData && !threadId) ||
        (!showInvitationPage && jobData && !threadId) ||
        (!candidateData && gqlLoading) ||
        (!jobData && gqlLoading)) && <Loader className="messages-loader" />}
      {(candidateData || jobData) && !threadId && showInvitationPage && (
        <InvitationPage
          userInfo={userInfo}
          messageContent={messageContent}
          setMessageContent={setMessageContent}
          candidateId={candidateId}
          closeThreadModal={closeThreadModal}
          sendInvite={sendInvite}
          showDetails={showDetails}
          loading={loading}
          setLoading={setLoading}
          myJobs={myJobs}
          isLoading={isLoading}
          candidateData={
            isRecruiter(userInfo.user_type)
              ? {
                  ...candidateData,
                  employmentOptions: candidateData.employment_options,
                }
              : jobData
          }
        />
      )}
      {jobId === null && candidateData == null && (
        <div className="slide-container right-slide bg-white">
          <span
            onClick={() => closeThreadModal()}
            style={{ zIndex: 111 }}
            className="slide-close"
          >
            <img src={slideClose} className="cursor-pointer" />
          </span>
          <div className="row">
            <div className="col-md-12 col-xl-12">
              <h5 className="slide-header bb d-inline">My Messages</h5>
              <span className="card-subtitle ml-2" style={{ fontSize: 14 }}>
                {selectedJobFilter.length > 0 || showApplied || searchFilterValue
                  ? !gqlLoading && `${filterThreads(threads).length} found`
                  : !gqlLoading && `${contactedUsers.length} found`}
              </span>
              <p
                className={`f14 mt-2 mb-2${
                  isCandidate(userInfo?.user_type) ? ' text-grey' : ''
                }`}
              >
                Message Center allows quick access to all your conversations.
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-md-12 col-lg-12 px-0">
            <form
              className="slide-search w-100"
              onSubmit={!gqlLoading ? searchFn : () => {}}
            >
              <div
                className={`col-sm-12 col-md-12 col-xl-12 px-0${
                  isCandidate(userInfo.user_type) ? ' mb-4' : ' mb-2'
                }`}
              >
                <div className="col-xs-12 col-md-12 col-lg-12 text-right p-0 d-flex flex-row justify-content-end align-items-start">
                  <span className="position-relative w-100">
                    <div className="input-icon">
                      <img className="absolute-left" src={search} />
                    </div>
                    <input
                      onKeyPress={(e) => {
                        let charCode = e.which ? e.which : e.keyCode;
                        if (charCode === 13) {
                          e.preventDefault();
                        }
                      }}
                      onBlur={!gqlLoading ? searchFn : () => {}}
                      className="form-control mb-0 pl-30 border-3 border-grey text-light-gray f16"
                      type="text"
                      onChange={(e) => {
                        setSearchFilterValue(e.currentTarget.value);
                      }}
                      value={searchFilterValue}
                      placeholder="Search Messages"
                    />
                    {searchFilterValue.length > 0 && (
                      <div
                        onClick={(e) => {
                          setSearchFilterValue('');
                        }}
                        className="dropdown-arrow r-15"
                      >
                        <img
                          className="absolute-right cursor-pointer"
                          src={slideClose}
                        />
                      </div>
                    )}
                  </span>
                </div>
              </div>
              {isRecruiter(userInfo?.user_type) && (
                <div className="recruiter-filters d-flex align-items-start mb-2">
                  <div className="col-10 px-0">
                    <Select
                      className="group-messages multi my-2"
                      options={myJobs?.sort((a, b) =>
                        moment.utc(b.created_at).diff(moment.utc(a.created_at))
                      )}
                      noOptionsMessage={() => 'You have no Jobs'}
                      getOptionValue={(opt) => opt.id}
                      getOptionLabel={(opt) => opt.title}
                      isMulti
                      placeholder="Filter by job"
                      value={selectedJobFilter || []}
                      onChange={(value) => setSelectedJobFilter(value)}
                      classNamePrefix="checkbox-select"
                    />
                  </div>
                  <div className="col-2 pr-0">
                    <button
                      type="button"
                      className={`btn w-100 my-2 border-rounded${
                        showApplied ? ' btn-primary text-white' : ' btn-outline'
                      }`}
                      onClick={() => setShowApplied(!showApplied)}
                      data-dismiss="modal"
                    >
                      Applied
                    </button>
                  </div>
                </div>
              )}
            </form>
          </div>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              {gqlLoading && (
                <ContentLoader
                  speed={2}
                  width={500}
                  height={200}
                  viewBox="0 0 800 200"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="22" y="13" rx="3" ry="3" width="604" height="20" />
                  <rect x="23" y="53" rx="3" ry="3" width="604" height="20" />
                  <rect x="26" y="95" rx="3" ry="3" width="127" height="20" />
                  <rect x="160" y="95" rx="3" ry="3" width="127" height="20" />
                  <rect x="25" y="134" rx="3" ry="3" width="604" height="20" />
                </ContentLoader>
              )}
              <div
                className={`thread-container${
                  isRecruiter(userInfo.user_type) ? ' large' : ''
                }`}
                id="thread-container"
              >
                <InfiniteScroll
                  dataLength={threads?.length}
                  next={() => loadMessages({ variables: { limit: 10, nextToken } })}
                  // next={()=>triggerfunction()}
                  loader={
                    selectedJobFilter?.length == 0 &&
                    searchFilterValue == '' &&
                    !showApplied ? (
                      <p>Loading..</p>
                    ) : (
                      ''
                    )
                  }
                  hasMore={hasMore}
                  endMessage={<p className="text-secondary"></p>}
                  scrollableTarget="thread-container"
                >
                  {!gqlLoading &&
                    filterThreads(threads).map((thread) => {
                      if (thread == null) {
                        return;
                      }
                      return (
                        <Thread
                          key={thread.thread_id}
                          jobId={thread.job_id}
                          showDetails={showDetails}
                          unread={thread.unread}
                          candidateView={isCandidate(userInfo.user_type)}
                          applied={thread.applied}
                          companyName={thread.job.company_name}
                          {...threadProps(thread)}
                          updatedAt={thread.updated_at}
                          onClick={() => setMessageInfo(thread)}
                        />
                      );
                    })}
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      )}
      {jobId !== null && threadId !== null && (
        <MessageDetails
          userInfo={userInfo}
          isApplied={appliedJobsIds.includes(jobId)}
          candidateId={candidateId}
          recruiterId={recruiterId}
          setResetThreads={setResetThreads}
          setCandidateId={setCandidateId}
          setRecruiterId={setRecruiterId}
        />
      )}
    </Overlay>
  );
};

Threads.propTypes = {
  userInfo: PropTypes.any,
  props: PropTypes.any,
  data: PropTypes.any,
};
export default Threads;
