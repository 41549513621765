import React from 'react';
import PropTypes from 'prop-types';
const CardBody = (props) => {
  const { bodyHeader, body, onClick } = props;

  return (
    <div className="card-body pt-1 pb-2">
      <p
        className="font-weight-bold primary-paragraph cursor-pointer"
        onClick={onClick}
      >
        {bodyHeader}
      </p>
      <p
        style={{ maxWidth: 820 }}
        className="mb-0 text-grey secondary-paragraph cursor-pointer preserve-line"
        onClick={onClick}
      >
        {body}
      </p>
    </div>
  );
};

CardBody.propTypes = {
  bodyHeader: PropTypes.string,
  body: PropTypes.string,
  onClick: PropTypes.func,
};

CardBody.defaultProps = {
  bodyHeader: '',
  body: '',
  onClick: null,
};

export default CardBody;
