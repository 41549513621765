import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, Switch, Route } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { deleteUser } from './service';
import Loader from '../../components/loader';
import edit from '../../images/Icons/edit.svg';
import defaultAvatar from '../../images/default-avatar.svg';
import { useMutation, useQueryClient } from 'react-query';
import { updateUser } from './service';
import Info from './info';
import RecruiterInfo from './recruiterInfo';
import Details from './details';
import Alerts from './alerts';
import { allowedTypes } from '../../utils/constants';
import './styles.scss';
import { checkImageWidth, resizeImage } from '../../utils/helpers';

const Profile = ({ userInfo, location: { pathname } }) => {
  const [published, setPublished] = React.useState(userInfo?.profile_published);
  const [loading, setLoading] = React.useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const queryClient = useQueryClient();

  const { mutate } = useMutation(updateUser, {
    onMutate: () => setLoading(true),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries('get-auth0-profile');
      if (variables.updateType == 'publish') {
        toast.success(
          variables.profile_published
            ? 'Published Succesfully'
            : 'Unpublished Succesfully'
        );
      } else {
        toast.success('Updated Succesfully');
      }

      setLoading(false);
    },
    onError: () => {
      toast.error("Couldn't Update");
      setLoading(false);
    },
    useErrorBoundary: (error) => {
      return error.response.status === 401;
    },
    retry: (count, error) => {
      if (error.response.status === 401) {
        return false;
      } else if (count <= 3) {
        return true;
      } else {
        false;
      }
    },
  });

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleProfileImageUpload = async (e) => {
    if (
      allowedTypes.candidate.profilePhoto.indexOf(
        e.target?.files[0]?.type.split('/')[0]
      ) > -1
    ) {
      const width = await checkImageWidth(e.target.files[0]);

      if (width <= 1440) {
        const picture = await toBase64(e.target.files[0]);
        if (picture) {
          mutate({ ...userInfo, avatar_img: picture, updateType: 'img' });
        }
      } else {
        const resizedImage = await resizeImage(e.target.files[0]);
        const resizedFile = {
          file: {
            originFileObj: resizedImage,
          },
        };
        const picture = await toBase64(resizedFile.file.originFileObj);
        if (picture) {
          mutate({ ...userInfo, avatar_img: picture, updateType: 'img' });
        }
      }
    } else {
      toast.error('file selected is not of image format');
    }
  };

  return (
    <div className="w-100 profile-page">
      {/* <Modal
        backdropClassName="profile-modal"
        className="profile-modal"
        show={modalShow}
        centered
        onHide={() => setModalShow(false)}
      >
        <Modal.Body>
          <div className="text-left">
            <h3 className="mb-4 fw-700">
              Delete{' '}
              <span className="d-inline-block text-truncate">{userInfo?.name}</span>{' '}
              account?
            </h3>
            <p className="mb-4">
              Are you sure you want to permanently delete your account and all
              associated data?
            </p>
            <p className="text-grey mb-4">Note: This action cannot be reverted.</p>
            <div className="d-flex align-items-center justify-content-between">
              <button
                onClick={() => setModalShow(false)}
                className="btn btn-outline btn-primary btn-rounded px-4"
              >
                Cancel
              </button>
              <button
                onClick={async () => {
                  setLoading(true);
                  let response = await deleteUser();

                  if (response) {
                    if (response.data.message == 'success') {
                      localStorage.clear();
                      window.location.reload();
                    }
                  } else {
                    toast.error("Couldn't Delete Account");
                    setLoading(false);
                  }
                }}
                className="btn btn-primary btn-rounded text-white px-4"
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
      {(loading || !userInfo || Object.keys(userInfo)?.length == 1) && (
        <div className="loader-container d-flex align-items-center justify-content-center">
          <Loader />
        </div>
      )}
      <div className="profile-header border-grey border-rounded d-flex bg-white align-items-center justify-content-between w-100 px-4 py-3 mb-3">
        <div className="left-side d-flex align-items-center">
          <div className="position-relative mr-2">
            <img
              className="m-0"
              src={userInfo?.avatar_url || defaultAvatar}
              alt="avatar"
            />
            <input
              className="upload-zone image-upload"
              type="file"
              onChange={handleProfileImageUpload}
              accept="image/*"
            />
            <div className="edit d-flex algin-items-center justify-content-center">
              <img src={edit} alt="edit" />
            </div>
          </div>
          <div>
            <span className="avatar-text d-block">{userInfo?.name}</span>
            <span className="avatar-text d-block text-grey">
              Set up your Jobterix presence and hiring needs
            </span>
          </div>
        </div>
        {userInfo.user_type == 'candidate' && (
          <div className="right-side d-flex align-items-center">
            <span className="text-bold">Unpublish</span>
            <label className="switch mb-0 mx-2">
              <input
                type="checkbox"
                checked={published}
                onChange={() => {
                  setPublished(!published);
                  mutate({
                    ...userInfo,
                    profile_published: !published,
                    updateType: 'publish',
                  });
                }}
              />
              <span className="slider round"></span>
            </label>
            <span className="text-bold">Publish</span>
          </div>
        )}
      </div>
      <div className="d-flex w-100">
        <div className="w-320px border-grey p-3 d-flex flex-column bg-white border-rounded">
          <Link
            className={classNames('profile-link px-1 py-2', {
              active: pathname === '/profile',
            })}
            to="/profile"
          >
            Profile
          </Link>
          {userInfo.user_type == 'candidate' && (
            <Link
              className={classNames('profile-link px-1 py-2', {
                active: pathname === '/profile/details',
              })}
              to="/profile/details"
            >
              Contact details and CV
            </Link>
          )}
          {userInfo.user_type == 'candidate' && (
            <Link
              className={classNames('profile-link px-1 py-2', {
                active: pathname === '/profile/job-alerts',
              })}
              to="/profile/job-alerts"
            >
              Job Alerts
            </Link>
          )}
          {/* <button
            onClick={() => setModalShow(true)}
            className="btn btn-delete text-left px-1 py-2"
          >
            Delete Account
          </button> */}
        </div>
        <div className="w-75 pb-4" style={{ paddingLeft: '21px' }}>
          <div className="bg-white border-grey border-rounded">
            <Switch>
              <Route
                exact
                path="/profile"
                render={() =>
                  userInfo.user_type == 'candidate' ? (
                    <Info
                      userInfo={userInfo}
                      published={published}
                      setLoading={(data) => setLoading(data)}
                    />
                  ) : (
                    <RecruiterInfo
                      userInfo={userInfo}
                      published={published}
                      setLoading={(data) => setLoading(data)}
                    />
                  )
                }
              />
              {userInfo.user_type == 'candidate' && (
                <Route
                  exact
                  path="/profile/details"
                  render={() => (
                    <Details
                      userInfo={userInfo}
                      published={published}
                      setLoading={(data) => setLoading(data)}
                    />
                  )}
                />
              )}
              {userInfo.user_type == 'candidate' && (
                <Route
                  exact
                  path="/profile/job-alerts"
                  render={() => <Alerts setLoading={(data) => setLoading(data)} />}
                />
              )}
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

Profile.propTypes = {
  userInfo: PropTypes.any,
  location: PropTypes.any,
};

export default Profile;
