import React from 'react';

export const useDimensions = () => {
  const [dimension, setDimension] = React.useState({
    width: window.innerWidth,
  });

  const onResize = () => setDimension({ width: window.innerWidth });

  React.useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return dimension;
};
