import axios from 'axios';
import httpClient from './utils/httpClient';
export const api = (method, path, data = {}) => {
  const token = localStorage.getItem('id_token');
  return axios({
    method,
    url: `https://${process.env.REACT_APP_DOMAIN}/${path}`,
    data,
    headers: {
      Authorization: token,
    },
  });
};

export const getUserProfile = async () => httpClient.get('/user/profile');
export const getUserOnboardingStatus = async () => httpClient.get('/user/onboarded');
export const getJobs = async (filters) =>
  httpClient.post('jobs/search', {
    ...filters,
  });

export const searchCandidates = async (filters) =>
  httpClient.post('users/search', {
    ...filters,
  });

export const getJob = async (id) => httpClient.get(`/job?id=${id}`);

export const getCandidate = async (id) => httpClient.get(`/user?id=${id}`);

export const getAllSavedJobs = async () => httpClient.get('/jobs/saved');

export const getAppliedJobs = async () => httpClient.get('/jobs/applied');

export const applyJob = async (id, data) =>
  httpClient.post(`/job/${id}/apply`, {
    ...data,
  });

export const saveJob = async (jobID) => httpClient.post(`/job/${jobID}/save`);

export const postSaveCandidate = async (candidateId) => httpClient.post(`/users/candidate/${candidateId}/save`);
export const getSavedCandidates = async () => httpClient.get('/users/candidates/saved');

export const sendMessage = async ({ content, jobId, receiver_id }) =>
  httpClient.post(`/thread/${jobId}`, {
    content_type: 'text',
    content,
    receiver_id
  });

export const getMessages = async (jobId) => httpClient.get(`/thread/${jobId}`);

export const getAllThreads = async () => httpClient.get(`/user/threads`);
