import { createContext, useContext } from 'react';

export const ApplyJobContext = createContext(undefined);

export const useApplyJob = () => {
  const context = useContext(ApplyJobContext);

  if (context === undefined) {
    throw new Error('useApplyJob can only be used inside ApplyJobProvider');
  }

  return context;
};
