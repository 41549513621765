import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import EmojiPicker from 'emoji-picker-react';
import Select, { components } from 'react-select';
import { Modal } from 'react-bootstrap';
import { useApplyJob } from '../../context/apply-job-context';
import AdaptiveTextArea from '../../components/adaptive-textarea';
import home from '../../images/Icons/home.svg';
import tagIcon from '../../images/Icons/tag.svg';
import Loader from '../../components/loader';
import { CreateJob } from '../myJobs/createJob';
import handsIcon from '../../images/Icons/hands.svg';
import location from '../../images/Icons/location.svg';
import smile from '../../images/Icons/smile.svg';
import dotMenu from '../../images/Icons/dot-menu.svg';
import backBig from '../../images/Icons/back-big.svg';
import level from '../../images/Icons/level.svg';

const Option = (props) => (
  <components.Option
    {...props}
    {...(props.data.id == 'createBtn' ? { className: 'create-btn' } : {})}
  >
    {props.data.id == 'createBtn' ? (
      <button className="btn create-job-btn p-2 py-3 d-flex align-items-center justify-content-center">
        <span className="d-block mb-1 mr-1">+ </span>
        {props.data.title}
      </button>
    ) : (
      <span className="d-block text-truncate">{props.data.title}</span>
    )}
  </components.Option>
);

export const InvitationPage = ({
  loading,
  userInfo,
  closeThreadModal,
  showDetails,
  sendInvite,
  setLoading,
  isLoading,
  myJobs,
  candidateData,
  messageContent,
  setMessageContent,
  candidateId,
}) => {
  const [createModalShow, setCreateModalShow] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [showErrors, setShowErrors] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [textAreaFocusKey, setTextAreaFocusKey] = useState(0);
  const titleRef = useRef();
  const [rows, setRows] = React.useState(1);
  const [threadContainerHeight, setThreadContainerHeight] = useState('big');
  const { toggleApplyJob, showMessages } = useApplyJob();

  const keyPressHandler = (e) => {
    setRows(1);
    if ((e.keyCode === 13 || e.which == 13) && e.shiftKey == false) {
      e.preventDefault();
      messageContent?.trim() !== '' &&
        sendInvite(messageContent, candidateData?.id, userInfo?.id);
      e.target.blur();
    }
  };

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.offsetHeight > 45 && setThreadContainerHeight('small');
    }
  }, [titleRef.current]);

  return (
    <div className="slide-container right-slide bg-white">
      {!showMessages && <Loader className="messages-loader white-bg" />}
      {userInfo?.user_type == 'recruiter' && (
        <Modal
          className="create-job-modal"
          show={createModalShow}
          animation="false"
          onHide={() => setCreateModalShow(false)}
        >
          <CreateJob
            userInfo={userInfo}
            onClose={(data) => {
              setCreateModalShow(false);
              data &&
                setSelectedJob({ id: data?.data?.id, title: data?.data?.title });
            }}
            setLoading={setLoading}
            isLoading={loading}
          />
        </Modal>
      )}
      {(isLoading || loading) && <Loader className="messages-loader" />}
      <div className="row card-border-bottom mb-0 d-flex align-items-center">
        <div
          className={`col-md-12 col text-md-center text-xl-left pr-0 d-flex col-xl-${
            userInfo?.user_type == 'candidate' ? 6 : 8
          }`}
          onClick={() => closeThreadModal()}
        >
          <img
            className="slide-bt-back btn-ab-left cursor-pointer mt-1"
            src={backBig}
          />
          <h5
            className="slide-header job-title pl-large mb-0"
            ref={titleRef}
            onClick={(e) => e.stopPropagation()}
          >
            {candidateData && userInfo?.user_type == 'recruiter'
              ? candidateData.designation
              : candidateData.title}
          </h5>
        </div>
        <div
          className={`col-md-12 col text-md-center text-xl-right col-xl-${
            userInfo?.user_type == 'candidate' ? 6 : 4
          }`}
        >
          <div className="confirmation-buttons messages d-flex align-items-center justify-content-around">
            <button
              type="button"
              className={`btn btn-outline btn-primary btn-rounded ml-2${
                userInfo.user_type == 'recruiter' ? ' ml-4' : ''
              }`}
              data-dismiss="modal"
              onClick={(e) => {
                e.target.blur();
                showDetails();
              }}
            >
              See Details
            </button>
            {userInfo?.user_type == 'candidate' && (
              <button
                onClick={() => toggleApplyJob(candidateData, userInfo)}
                type="button"
                className="btn btn-primary btn-rounded text-white"
              >
                Apply
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="col-md-12 col text-md-center text-xl-left px-0 mt-4">
        {userInfo?.user_type == 'candidate' && (
          <div
            className="d-flex flex-column justify-content-between"
            style={{
              height: `calc(100vh - ${
                threadContainerHeight == 'small' ? 8.5 : 7
              }rem)`,
            }}
          >
            <div
              className="mesages-container py-1"
              style={{
                maxHeight: `calc(100vh - 168px${rows && ' - ' + rows + '*24px'})`,
              }}
            ></div>
            {showPicker && (
              <div className="chat-emoji-container">
                <EmojiPicker
                  onEmojiClick={(event, emojiObject) => {
                    setMessageContent((prev) => prev + emojiObject.emoji);
                    setTextAreaFocusKey((prev) => prev + 1);
                    setShowPicker(!showPicker);
                  }}
                />
              </div>
            )}
            <form
              className={`mt-0${userInfo.user_type == 'recruiter' ? ' mb-2' : ''}`}
              onSubmit={(e) =>
                e.preventDefault() +
                sendInvite(messageContent, candidateData?.id, userInfo?.id)
              }
              onKeyPress={keyPressHandler}
            >
              <div className="d-flex align-items-start position-relative">
                <AdaptiveTextArea
                  {...{ autofocus: true }}
                  msg={messageContent}
                  setMsg={setMessageContent}
                  getRows={(data) => setRows(data)}
                  rows={rows}
                  focusKey={textAreaFocusKey}
                />
                <img
                  className="emoji-toggler position absolute"
                  src={smile}
                  alt="smile"
                  onClick={() => setShowPicker(!showPicker)}
                />
              </div>
            </form>
          </div>
        )}
        {userInfo?.user_type == 'recruiter' && (
          <>
            <div
              className="message-info"
              {...(threadContainerHeight == 'big'
                ? { style: { height: 'calc( 100vh - 170px )' } }
                : {})}
            >
              <label className="form-label" style={{ fontWeight: 500 }}>
                Choose job from the list
              </label>
              <Select
                className={`group-messages my-2 mb-3${
                  showErrors && !selectedJob ? ' is-invalid' : ''
                }`}
                hideSelectedOptions={false}
                options={[
                  ...(myJobs
                    ? myJobs.sort((a, b) =>
                        moment.utc(b.created_at).diff(moment.utc(a.created_at))
                      )
                    : []),
                  { id: 'createBtn', title: 'Create New Job' },
                ]}
                getOptionValue={(opt) => opt.id}
                getOptionLabel={(opt) => opt.title}
                placeholder="Select an option"
                isClearable
                components={{ Option }}
                value={selectedJob}
                onChange={(value) =>
                  value?.id === 'createBtn'
                    ? setCreateModalShow(true)
                    : setSelectedJob(value)
                }
                classNamePrefix="checkbox-select"
              />
              {showErrors && !selectedJob && (
                <span className="invalid-feedback mb-2">Required</span>
              )}
              <label className="form-label" style={{ fontWeight: 500 }}>
                Message
              </label>
              <textarea
                value={messageContent}
                onChange={(e) => setMessageContent(e.target.value)}
                className={`form-control${
                  showErrors && !messageContent ? ' is-invalid' : ''
                }`}
                placeholder="Enter your message"
                style={{ height: '200px' }}
              />
              {showErrors && !messageContent && (
                <span className="invalid-feedback mb-1">Required</span>
              )}
            </div>
            <div className="confirmation-buttons messages d-flex align-items-center justify-content-between">
              <button
                type="button"
                className="btn btn-outline btn-primary btn-rounded mr-2"
                data-dismiss="modal"
                onClick={() => closeThreadModal()}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  if (messageContent && selectedJob) {
                    sendInvite(messageContent, selectedJob.id, candidateId);
                  } else if (!messageContent || !selectedJob) {
                    setShowErrors(true);
                  }
                }}
                type="button"
                className="btn btn-primary btn-rounded text-white"
              >
                Send
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

Option.propTypes = {
  data: PropTypes.any,
};

InvitationPage.propTypes = {
  userInfo: PropTypes.any,
  myJobs: PropTypes.any,
  candidateData: PropTypes.any,
  isLoading: PropTypes.any,
  loading: PropTypes.any,
  setLoading: PropTypes.any,
  messageContent: PropTypes.any,
  setMessageContent: PropTypes.any,
  candidateId: PropTypes.any,
  closeThreadModal: PropTypes.any,
  showDetails: PropTypes.any,
  sendInvite: PropTypes.any,
};
