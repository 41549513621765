import React from 'react';
import { Button } from 'react-bootstrap';
import './social.scss';
import Google from '../../images/google.svg';
import Github from '../../images/github.svg';
import Linkedin from '../../images/linkedin.svg';
import PropTypes from 'prop-types';
import { useAuth } from '../../context/auth-context';

const SocialBtn = ({ imagePath, children, className, ...otherProps }) => (
  <Button className={`${className} social-btn py-4 py-md-3 py-sm-2`} {...otherProps}>
    <div className="d-flex justify-content-between align-items-center">
      <img className="social-icon" src={imagePath} />
      <div className="text-center w-100">{children}</div>
    </div>
  </Button>
);

export const SocialSignIn = ({ checkAgreement }) => {
  const auth = useAuth();

  const googleSignIn = () => {
    if (checkAgreement) {
      checkAgreement(() => {
        auth.authorize({
          connection: 'google-oauth2',
        });
      });
    } else {
      auth.authorize({
        connection: 'google-oauth2',
      });
    }
  };

  const githubSignIn = () => {
    if (checkAgreement) {
      checkAgreement(() => {
        auth.authorize({
          connection: 'github',
        });
      });
    } else {
      auth.authorize({
        connection: 'github',
      });
    }
  };

  const linkedInSignIn = () => {
    if (checkAgreement) {
      checkAgreement(() => {
        auth.authorize({
          connection: 'linkedin',
        });
      });
    } else {
      auth.authorize({
        connection: 'linkedin',
      });
    }
  };

  return (
    <div className="d-flex justify-content-between align-items-center w-100 mb-1">
      <SocialBtn imagePath={Github} className="w-50" onClick={githubSignIn}>
        Sign In
      </SocialBtn>
      <SocialBtn imagePath={Linkedin} className="w-50 mx-3" onClick={linkedInSignIn}>
        Sign In
      </SocialBtn>
      <SocialBtn imagePath={Google} className="w-50" onClick={googleSignIn}>
        Sign In
      </SocialBtn>
    </div>
  );
};

SocialBtn.propTypes = {
  children: PropTypes.any,
  imagePath: PropTypes.string,
  className: PropTypes.string,
};

SocialSignIn.propTypes = {
  auth: PropTypes.any,
  checkAgreement: PropTypes.any,
};
