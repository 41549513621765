import React from 'react';
import PropTypes from 'prop-types';
import linkIcon from '../../images/Icons/link.svg';
import level from '../../images/Icons/level.svg';
import messageIcon from '../../images/Icons/comment.svg';
import messageIconActive from '../../images/Icons/commentActive.svg';
import saveIcon from '../../images/Icons/bookmark.svg';
import savedIcon from '../../images/Icons/bookmark-filled.svg';
import locationIcon from '../../images/Icons/location.svg';
import dotMenu from '../../images/Icons/dot-menu.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import homeIcon from '../../images/Icons/home.svg';
import handsIcon from '../../images/Icons/hands.svg';
import { Dropdown } from 'react-bootstrap';
import tagIcon from '../../images/Icons/tag.svg';
import primaryTick from '../../images/Icons/primary-tick.svg';
import { Spinner } from 'react-bootstrap';
import { postTypes } from '../../utils/constants';
import './index.scss';
import { constructEncodedUrl } from '../../utils/helpers';

const CardHeader = (props) => {
  const {
    userInfo,
    onClick,
    showActions,
    type,
    id,
    show_tag,
    title,
    onSave,
    savingId,
    saved,
    onTogglePublish,
    experience,
    onMessage,
    companyName,
    status,
    published,
    inJobDetail,
    showCLipboard,
    onOpenConverSation,
    seeApplicants,
    moreCandidates,
    onEdit,
    onDelete,
    locations,
    employmentOptions,
    salary,
    contactedJobs,
    appliedJobs,
  } = props;

  const [showTick, setShowTick] = React.useState(false);

  return (
    <div className="card-header bg-white d-flex flex-column">
      {appliedJobs?.includes(id) && show_tag && (
        <span className="applied-tag">Applied</span>
      )}
      <div className="row mx-0">
        <div
          className={`col-md-${
            showActions ? '8' : '10'
          } p-0 d-flex flex-column justify-content-center`}
        >
          <h5
            onClick={onClick}
            className={`mb-0 font-weight-bold${onClick ? ' cursor-pointer' : ''}`}
          >
            {title}
          </h5>
        </div>
        <div
          className={`col-md-${
            showActions ? '4' : '2'
          } d-flex p-0 align-items-center justify-content-end`}
        >
          {/* Revisit this, we need to have better link copies */}
          {showActions && (
            <>
              {published && <span style={{ color: '#0ca789' }}>Active</span>}
              {!published && <span style={{ color: '#0ca789' }}>Draft</span>}
              <Dropdown alignRight={false} className="actions">
                <Dropdown.Toggle
                  as={'div'}
                  className="row ml-1 p-2 pr-3"
                  id="dropdown-basic"
                >
                  <img
                    className="cursor-pointer"
                    style={{ height: 14 }}
                    src={dotMenu}
                    alt="dot-menu"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="py-0">
                  <Dropdown.Item onClick={onEdit}>Edit</Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      navigator.clipboard.writeText(
                        `${window.location.origin}${constructEncodedUrl(
                          { id },
                          'job'
                        )}`
                      )
                    }
                  >
                    Copy job&apos;s link
                  </Dropdown.Item>
                  <Dropdown.Item onClick={onTogglePublish}>
                    {published ? 'Unpublish' : 'Publish'}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={onDelete}>Delete</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={onOpenConverSation}>
                    Conversations
                  </Dropdown.Item>
                  <Dropdown.Item onClick={seeApplicants}>Applicants</Dropdown.Item>
                  <Dropdown.Item onClick={moreCandidates}>
                    More candidates
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
          {showCLipboard && (
            <CopyToClipboard
              text={`${window.location.origin}${constructEncodedUrl(
                { id: id },
                inJobDetail
                  ? 'job'
                  : userInfo.user_type == 'candidate'
                  ? 'job'
                  : 'candidate'
              )}`}
              onCopy={() => {
                if (showTick) {
                  return;
                }
                setShowTick(true);
                setTimeout(() => setShowTick(false), 5000);
              }}
            >
              <button
                className="btn p-0"
                onClick={(e) => e.preventDefault() + e.stopPropagation()}
              >
                <img className="ch-icons" src={showTick ? primaryTick : linkIcon} />
              </button>
            </CopyToClipboard>
          )}
          {onMessage && (
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onMessage(id);
              }}
              className="btn p-0"
            >
              <img
                className="ch-icons"
                src={
                  contactedJobs?.includes(id) && status
                    ? messageIconActive
                    : messageIcon
                }
              />
            </button>
          )}
          {onSave && (
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onSave(id);
              }}
              className={`btn p-0`}
            >
              {savingId !== id ? (
                <img
                  className="ch-icons"
                  src={saved && status ? savedIcon : saveIcon}
                />
              ) : (
                <div style={{ paddingRight: '8px', paddingLeft: '8px' }}>
                  <Spinner size="sm" animation="border" role="status" />
                </div>
              )}
            </button>
          )}
        </div>
      </div>
      <div className="d-flex particulars mt-2">
        {companyName && (
          <div className="d-flex align-items-center">
            <img className="ch-icons" src={homeIcon} />
            <p className="mb-0">{companyName}</p>
          </div>
        )}
        {locations.length > 0 && (
          <div className="d-flex align-items-center">
            <img className="ch-icons" src={locationIcon} />
            <p className="mb-0">{locations.join(', ')}</p>
          </div>
        )}
        <div className="d-flex align-items-center">
          <img className="ch-icons" src={level} />
          <p className="mb-0">
            {experience !== 0 && experience > 1
              ? `${experience} years of experience`
              : `${experience} year of experience`}
          </p>
        </div>
        {employmentOptions.length > 0 && (
          <div className="d-flex align-items-center">
            <img className="ch-icons" src={handsIcon} />
            <p className="mb-0">{employmentOptions.join(', ')}</p>
          </div>
        )}
        {salary && (
          <div className="d-flex justify-content-center">
            <img className="ch-icons" src={tagIcon} />
            <p className="mb-0 text-primary">${salary}</p>
          </div>
        )}
      </div>
    </div>
  );
};

CardHeader.propTypes = {
  type: PropTypes.string,
  userInfo: PropTypes.any,
  showActions: PropTypes.bool,
  id: PropTypes.string,
  title: PropTypes.string,
  onSave: PropTypes.any,
  savingId: PropTypes.string,
  saved: PropTypes.bool,
  published: PropTypes.any,
  showCLipboard: PropTypes.bool,
  status: PropTypes.bool,
  show_tag: PropTypes.bool,
  onMessage: PropTypes.func,
  onClick: PropTypes.func,
  onOpenConverSation: PropTypes.func,
  onTogglePublish: PropTypes.func,
  moreCandidates: PropTypes.func,
  seeApplicants: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  companyName: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.string),
  inJobDetail: PropTypes.bool,
  employmentOptions: PropTypes.arrayOf(PropTypes.string),
  savedJobs: PropTypes.arrayOf(PropTypes.string),
  appliedJobs: PropTypes.arrayOf(PropTypes.any),
  contactedJobs: PropTypes.arrayOf(PropTypes.string),
  salary: PropTypes.number,
  experience: PropTypes.number,
};

CardHeader.defaultProps = {
  type: postTypes.job,
  published: true,
  showActions: false,
  inJobDetail: false,
  userInfo: null,
  id: null,
  title: null,
  onSave: null,
  savingId: null,
  saved: false,
  onMessage: null,
  showCLipboard: true,
  show_tag: false,
  onClick: null,
  moreCandidates: null,
  seeApplicants: null,
  onOpenConverSation: null,
  onEdit: null,
  status: true,
  onDelete: null,
  companyName: null,
  locations: [],
  employmentOptions: [],
  savedJobs: [],
  appliedJobs: [],
  contactedJobs: [],
  salary: null,
  experience: null,
};

export default CardHeader;
