/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import '../styles.scss';
import Page from '../page';
import { Form, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import btnArrow from '../../../images/Icons/btn-arrow.svg';
import Classnames from 'classnames';
import { useAuth } from '../../../context/auth-context';
import {
  validateEmail,
  validateLinkedInUrl,
  validateUrl,
} from '../../../utils/helpers';
import { useMutation, useQueryClient } from 'react-query';
import { createRecruiterUser } from './service';
import { useDimensions } from '../../../hooks/useDimensions';
export const AboutCompany = ({ control, errors, page = null, values }) => {
  const [firstField, setFirstField] = React.useState('');

  React.useEffect(() => {
    const fieldsValue = values();
    fieldsValue?.aboutCompany && setFirstField(fieldsValue?.aboutCompany);
  }, []);

  return (
    <>
      <div className="col">
        <h4 className="mb-4 mb-md-2">Company Details</h4>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-xl-6">
            <Form.Group className="form-group position-relative">
              <Form.Label>Whats your company name?</Form.Label>
              <Controller
                control={control}
                id="companyName"
                name="companyName"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <input
                      type="text"
                      className={Classnames(
                        'form-control',
                        errors?.companyName && 'is-invalid'
                      )}
                      placeholder="Enter company name"
                      onChange={(e) => onChange(e.target.value)}
                      defaultValue={value}
                      autoFocus
                    />
                    <label
                      className={`custom-label position-absolute${
                        value !== '' || errors?.companyName ? ' show' : ''
                      }`}
                    >
                      {errors?.companyName
                        ? 'Whats your company name? (Required)'
                        : 'Whats your company name?'}
                    </label>
                  </>
                )}
              />
              {Boolean(errors?.companyName) && (
                <Form.Control.Feedback className="d-flex" type="invalid">
                  Required
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </div>
          <div className="col-sm-12 col-md-6 col-xl-6">
            <Form.Group className="form-group position-relative">
              <Form.Label>Company Website</Form.Label>
              <Controller
                control={control}
                id="companyWebsite"
                name="companyWebsite"
                rules={{ validate: (url) => validateUrl(url, true) }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <input
                      type="text"
                      className={Classnames(
                        'form-control',
                        errors?.companyWebsite && 'is-invalid'
                      )}
                      placeholder="Enter company website"
                      onChange={(e) => onChange(e.target.value)}
                      defaultValue={value}
                    />
                    <label
                      className={`custom-label position-absolute${
                        value !== '' || errors?.companyWebsite ? ' show' : ''
                      }`}
                    >
                      {errors?.companyWebsite
                        ? 'Company Website (Invalid Link)'
                        : 'Company Website'}
                    </label>
                  </>
                )}
              />
              {Boolean(errors?.companyWebsite) && (
                <Form.Control.Feedback className="d-flex" type="invalid">
                  Invalid Link
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-xl-12">
            <Form.Group className="form-group position-relative">
              <Form.Label>About company</Form.Label>
              <Controller
                control={control}
                id="aboutCompany"
                name="aboutCompany"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <textarea
                      className={Classnames(
                        'form-control',
                        errors?.aboutCompany && 'is-invalid'
                      )}
                      maxLength="2000"
                      placeholder="General overview of your company"
                      onChange={(e) =>
                        setFirstField(e.target.value) + onChange(e.target.value)
                      }
                      defaultValue={value}
                    />{' '}
                    <label
                      className={`custom-label position-absolute${
                        value !== '' || errors?.aboutCompany ? ' show' : ''
                      }`}
                    >
                      {errors?.aboutCompany
                        ? 'About company (Required)'
                        : 'About company'}
                    </label>
                  </>
                )}
              />
              <Form.Control.Feedback className="d-flex text-grey" type="invalid">
                {firstField.length}/2000
                {Boolean(errors?.aboutCompany) && (
                  <span className="d-flex text-danger ml-2" type="invalid">
                    Required
                  </span>
                )}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
      </div>
      <div className="col" style={{ marginTop: 10 }}>
        <h4 className="mb-4 mb-md-2">Recruiter Details</h4>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-xl-6">
            <Form.Group className="form-group position-relative">
              <Form.Label>Name</Form.Label>
              <Controller
                control={control}
                id="name"
                name="name"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <input
                      type="text"
                      className={Classnames(
                        'form-control',
                        errors?.name && 'is-invalid'
                      )}
                      placeholder="Enter your name"
                      onChange={(e) => onChange(e.target.value)}
                      defaultValue={value}
                    />
                    <label
                      className={`custom-label position-absolute${
                        value !== '' || errors?.name ? ' show' : ''
                      }`}
                    >
                      {errors?.name ? 'Name (Required)' : 'Name'}
                    </label>
                  </>
                )}
              />
              {Boolean(errors?.name) && (
                <Form.Control.Feedback className="d-flex" type="invalid">
                  Required
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </div>
          <div className="col-sm-12 col-md-6 col-xl-6">
            <Form.Group className="form-group position-relative">
              <Form.Label>Title</Form.Label>
              <Controller
                control={control}
                id="designation"
                name="designation"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <input
                      type="text"
                      className={Classnames(
                        'form-control',
                        errors?.designation && 'is-invalid'
                      )}
                      placeholder="Enter your job title"
                      onChange={(e) => onChange(e.target.value)}
                      defaultValue={value}
                    />
                    <label
                      className={`custom-label position-absolute${
                        value !== '' || errors?.designation ? ' show' : ''
                      }`}
                    >
                      {errors?.designation ? 'Title (Required)' : 'Title'}
                    </label>
                  </>
                )}
              />
              {Boolean(errors?.designation) && (
                <Form.Control.Feedback className="d-flex" type="invalid">
                  Required
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </div>
        </div>
        {page && (
          <div className="row">
            <div className="col-sm-12 col-md-12 col-xl-12">
              <Form.Group className="form-group position-relative">
                <Form.Label>Email ID</Form.Label>
                <Controller
                  control={control}
                  id="emailId"
                  name="emailId"
                  rules={{ required: !page, validate: validateEmail }}
                  render={({ field: { onChange, value } }) => (
                    <input
                      type="text"
                      className={Classnames(
                        'form-control',
                        errors?.emailId && 'is-invalid'
                      )}
                      disabled={page}
                      placeholder="Enter your work email id"
                      onChange={(e) => onChange(e.target.value)}
                      defaultValue={value}
                    />
                  )}
                />
                {Boolean(errors?.emailId) && errors?.emailId?.type !== 'validate' && (
                  <Form.Control.Feedback className="d-flex" type="invalid">
                    Required
                  </Form.Control.Feedback>
                )}
                {Boolean(errors?.emailId) && errors?.emailId?.type === 'validate' && (
                  <Form.Control.Feedback className="d-flex" type="invalid">
                    Invalid email
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-sm-12 col-md-12 col-xl-12">
            <Form.Group className="form-group position-relative">
              <Form.Label>LinkedIn profile link</Form.Label>
              <Controller
                control={control}
                id="linkedInProfile"
                name="linkedInProfile"
                rules={{ required: true, validate: validateLinkedInUrl }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <input
                      type="text"
                      className={Classnames(
                        'form-control',
                        errors?.linkedInProfile && 'is-invalid'
                      )}
                      placeholder="Enter your LinkedIn profile link"
                      onChange={(e) => onChange(e.target.value)}
                      defaultValue={value}
                    />
                    <label
                      className={`custom-label position-absolute${
                        value !== '' || errors?.linkedInProfile ? ' show' : ''
                      }`}
                    >
                      {errors?.linkedInProfile
                        ? 'LinkedIn profile link (Required)'
                        : 'LinkedIn profile link'}
                    </label>
                  </>
                )}
              />
              {Boolean(errors?.linkedInProfile) &&
                errors?.linkedInProfile?.type !== 'validate' && (
                  <Form.Control.Feedback className="d-flex" type="invalid">
                    Required
                  </Form.Control.Feedback>
                )}
              {Boolean(errors?.linkedInProfile) &&
                errors?.linkedInProfile?.type === 'validate' && (
                  <Form.Control.Feedback className="d-flex" type="invalid">
                    Invalid link
                  </Form.Control.Feedback>
                )}
            </Form.Group>
          </div>
        </div>
      </div>
    </>
  );
};

const Summary = ({ data, back }) => {
  const {
    companyName,
    companyWebsite,
    aboutCompany,
    name,
    designation,
    emailId,
    linkedInProfile,
  } = data;

  return (
    <>
      <div className="summary-data mh200">
        <div className="row pb-0">
          <div className="col-6">
            {' '}
            <h6 className="font-weight-bold mb-0">Company Details</h6>
          </div>
          <div className="col-6 text-right btn btn-link" onClick={back}>
            Edit
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <p className="card-subtitle">Whats your company name?</p>
          </div>

          <div className="col">
            <p className="card-subtitle font-weight-bold">{companyName}</p>
          </div>
        </div>
        {companyWebsite && (
          <div className="row">
            <div className="col-5">
              <p className="card-subtitle">Company Website</p>
            </div>

            <div className="col">
              <p className="card-subtitle font-weight-bold">{companyWebsite}</p>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-5">
            <p className="card-subtitle">About company</p>
          </div>

          <div className="col">
            <p className="card-subtitle font-weight-bold preserve-line">
              {aboutCompany}
            </p>
          </div>
        </div>
      </div>
      <div className="summary-data mh200">
        <div className="row pb-0">
          <div className="col-6">
            {' '}
            <h6 className="font-weight-bold mb-0">Recruiter Details</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <p className="card-subtitle">Name</p>
          </div>
          <div className="col">
            <p className="card-subtitle font-weight-bold">{name}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <p className="card-subtitle">Designation</p>
          </div>
          <div className="col">
            <p className="card-subtitle font-weight-bold">{designation}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <p className="card-subtitle">Email ID</p>
          </div>
          <div className="col">
            <p className="card-subtitle font-weight-bold">{emailId}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <p className="card-subtitle">LinkedIn Profile</p>
          </div>
          <div className="col">
            <p className="card-subtitle font-weight-bold">{linkedInProfile}</p>
          </div>
        </div>
      </div>
    </>
  );
};

function RecruiterOnboardingForm(props) {
  const { push } = useHistory();
  const { setStep, currentStep } = props;
  const dimensions = useDimensions();

  const [email, setEmail] = React.useState('');

  const auth = useAuth();

  React.useEffect(() => {
    auth.getProfile((profile) => setEmail(profile.email));
  }, []);

  const {
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      companyName: '',
      companyWebsite: '',
      aboutCompany: '',
      name: '',
      designation: '',
      linkedInProfile: '',
    },
  });

  const next = async (e) => {
    // TODO: Validate form fields
    const res = await trigger([
      'companyName',
      'name',
      'designation',
      'aboutCompany',
      'linkedInProfile',
      'companyWebsite',
    ]);
    res && currentStep <= 1 && setStep(currentStep + 1);

    e.preventDefault();
    e.stopPropagation();
  };

  const previous = () => {
    if (currentStep === 1) {
      push('/onboarding');
    } else {
      setStep(currentStep - 1);
    }
  };

  const queryClient = useQueryClient();

  const { isLoading, isSuccess, mutate } = useMutation(
    (data) => createRecruiterUser(data),
    {
      useErrorBoundary: (error) => {
        return error.response.status === 401;
      },
      retry: (count, error) => {
        if (error.response.status === 401) {
          return false;
        } else if (count <= 3) {
          return true;
        } else {
          false;
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries('get-user-onboarding-status');
        queryClient.invalidateQueries('get-auth0-profile');
        push({ pathname: '/', state: 'user-details-submitted' });
      },
    }
  );

  return (
    <div className="w-100by485 sw-padding vh-100 bg-white">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-12">
          <div className="sw-headings sw-top-padding">
            <h1>Tell us more about yourself</h1>
            <h3>Describe Your Company And Your Position</h3>
          </div>
          <Form
            className="w-100 about-recruiter"
            noValidate
            onSubmit={handleSubmit(mutate)}
          >
            <div
              className="sw-form"
              {...(dimensions.width < 576
                ? { style: { paddingBottom: '5rem' } }
                : {})}
            >
              {currentStep == 1 && (
                <AboutCompany errors={errors} control={control} values={getValues} />
              )}
              {currentStep == 2 && (
                <Summary back={previous} data={{ ...getValues(), emailId: email }} />
              )}
              <div className="sw-action">
                <div
                  className={`onboarding-progress step-${currentStep} recruiter`}
                ></div>
                <div className="row align-items-center">
                  <div className="confirmation-buttons">
                    {currentStep > 0 ? (
                      <button
                        type="button"
                        onClick={previous}
                        className="btn sw-btn-default btn-rounded "
                      >
                        {dimensions.width < 576 ? (
                          <span className="chevron left"></span>
                        ) : (
                          'PREVIOUS STEP'
                        )}
                      </button>
                    ) : (
                      <span></span>
                    )}
                  </div>
                  <div className="col text-right">
                    <div className="confirmation-buttons">
                      {currentStep > 1 ? (
                        <button
                          type="submit"
                          className="btn btn-rounded sw-btn-next"
                        >
                          {isLoading ? (
                            <Spinner animation="border" role="status">
                              <span className="sr-only">Loading...</span>{' '}
                            </Spinner>
                          ) : (
                            'Publish profile'
                          )}
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={next}
                          className="btn btn-rounded sw-btn-next"
                        >
                          Next Step
                          {currentStep <= 2 && (
                            <img height="11" width="16" src={btnArrow} />
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

RecruiterOnboardingForm.propTypes = {
  currentStep: PropTypes.number,
  setStep: PropTypes.func,
  page: PropTypes.string,
};

const steps = ['Pick your role', 'Information about your company', 'Summary'];

export default Page(RecruiterOnboardingForm, steps, 1);
