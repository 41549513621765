import React, { createRef } from 'react';
import ReactDOM, { render } from 'react-dom';
import PropTypes from 'prop-types';

export const overlay = {
  height: '100%',
  width: '100%',
  position: 'fixed',
  StayInPlaceZIndex: '1',
  SitOnTopLeft: '0',
  top: '0',
  zIndex: 111,
  backgroundColor: 'rgba(0,0,0, 0.5)',
  onpagehide,
};

export const overlayContent = {
  position: 'relative',
  width: '100%',
};

class Overlay extends React.Component {
  constructor(props) {
    super(props);
    this.ref = createRef();
    // Create container DOM element and append to DOM.
    this.overlayContainer = document.createElement('span');
    this.overlayContainer.setAttribute('id', 'modal');
    document.body.appendChild(this.overlayContainer);
    this.listener = (event) => {
      if (event.srcElement.id !== 'overlaycomponent') {
        return;
      }
      if (this.props.close) {
        this.props.close();
      }
    };
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.listener);
    document.addEventListener('touchstart', this.listener);
    document.getElementById('root').style.height = '100vh';
    document.getElementById('root').style.overflow = 'hidden';
  }
  componentWillUnmount() {
    document.getElementById('root').style.height = null;
    document.getElementById('root').style.overflow = null;
    document.body.removeChild(this.overlayContainer);
    document.removeEventListener('mousedown', this.listener);
    document.removeEventListener('touchstart', this.listener);
  }

  render() {
    return ReactDOM.createPortal(
      <div style={overlay} id="overlaycomponent">
        <div style={overlayContent}>
          <div
            className="modal-animate"
            ref={this.ref}
            style={{
              background: 'white',
              height: '100vh',
              width: '588px',
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            {this.props.children}
          </div>
        </div>
      </div>,
      this.overlayContainer
    );
  }
}

Overlay.propTypes = {
  children: PropTypes.any,
  close: PropTypes.func,
};

export default Overlay;
