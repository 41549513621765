/* eslint-disable no-prototype-builtins */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Threads from '../threads';
import './styles.scss';
import ApplyJob from '../applyJob';
import withUserStateChecks from '../../hoc/with-user-state-checks';
import Header from '../../components/header';
import PropTypes from 'prop-types';
import Profile from '../profile';
import SearchJobsPage from '../search-jobs';
import MyJobs from '../myJobs';
import JobDetailPage from '../jobDetail/jobDetail';
import RecruiterDetailPage from '../recruiterDetail';
import SavedJobDetail from '../savedJobDetail/savedJobDetail';
import AppliedJobDetail from '../appliedJobDetail/appliedJobDetail';
import MyJobsDetail from '../myJobDetail';
import SearchCandidatePage from '../search-candidates';
import CandidateDetail from '../candidateDetail';
import SavedCandidateDetail from '../savedCandidateDetail';
import { useMessageThreads } from '../../context/message-threads-context';
import { useApplyJob } from '../../context/apply-job-context';

import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

const config = {
  url: process.env.REACT_APP_MESSAGING_BASE_URL,
  region: process.env.REACT_APP_MESSAGING_REGION,
  auth: {
    type: 'OPENID_CONNECT',
    jwtToken: async () => localStorage.getItem('id_token'),
  },
};

const client = new ApolloClient({
  link: ApolloLink.from([
    createAuthLink(config),
    createSubscriptionHandshakeLink(config),
  ]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

const Dashboard = (props) => {
  const { userInfo } = props;

  const { showThreads } = useMessageThreads();

  const { job: selectedJobApply, showApplyJob } = useApplyJob();

  const redirectPath = (userType) =>
    userType === 'candidate' ? '/jobs' : '/candidates';
  return (
    <ApolloProvider client={client}>
      <>
        <Header userDetails={userInfo} />
        <div className="main-container">
          <section className="s-padding pb-0">
            <div className="container-lg">
              <div className="w-100 d-flex justify-content-start">
                <Switch>
                  <Route
                    path={`/profile`}
                    component={(props) => <Profile {...props} userInfo={userInfo} />}
                  />
                  <Route
                    path={`/my-jobs`}
                    exact
                    component={(props) => <MyJobs userInfo={userInfo} {...props} />}
                  />
                  <Route
                    exact
                    path={'/recruiter/:id'}
                    component={() => <RecruiterDetailPage userInfo={userInfo} />}
                  />
                  <Route
                    path={`/jobs`}
                    component={() => <SearchJobsPage userInfo={userInfo} />}
                  />
                  <Route
                    path={'/job/:id'}
                    component={() => <JobDetailPage userInfo={userInfo} />}
                    exact
                  />
                  <Route
                    exact
                    path={'/job/saved/:id'}
                    component={() => <SavedJobDetail userInfo={userInfo} />}
                  />
                  <Route
                    exact
                    path={'/job/applied/:id'}
                    component={() => <AppliedJobDetail userInfo={userInfo} />}
                  />
                  <Route
                    exact
                    path={'/my-jobs/:id'}
                    component={() => <MyJobsDetail userInfo={userInfo} />}
                  />
                  <Route
                    path={`/candidates`}
                    component={() => <SearchCandidatePage userInfo={userInfo} />}
                  />
                  <Route
                    exact
                    path={'/candidate/:id'}
                    component={() => <CandidateDetail userInfo={userInfo} />}
                  />
                  <Route
                    exact
                    path={'/candidate/saved/:id'}
                    component={() => <SavedCandidateDetail userInfo={userInfo} />}
                  />
                  <Redirect exact from="/" to={redirectPath(userInfo.user_type)} />
                </Switch>
              </div>
            </div>
          </section>
        </div>

        {showApplyJob && <ApplyJob job={selectedJobApply} userInfo={userInfo} />}
        {showThreads && <Threads userInfo={userInfo} />}
      </>
    </ApolloProvider>
  );
};

Dashboard.propTypes = {
  userInfo: PropTypes.any,
};

export default withUserStateChecks(Dashboard);
