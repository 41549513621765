import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

const AdaptiveTextArea = ({ msg, setMsg, rows, getRows, focusKey, onClick }) => {
  const [row, setRow] = useState(rows);
  const textAreaRef = useRef();
  const maxRows = 10,
    minRows = rows;

  const focusOnTextArea = () => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  };

  React.useEffect(() => {
    focusOnTextArea();
  }, [focusKey]);

  const handleChange = (event) => {
    const textareaLineHeight = 24;

    const previousRows = event.target.rows;
    event.target.rows = minRows;

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }
    getRows && getRows(currentRows < maxRows ? currentRows : maxRows);
    setRow(currentRows < maxRows ? currentRows : maxRows);
    setMsg(event.target.value);
  };

  React.useEffect(() => {
    rows && setRow(rows);
  }, [rows]);

  return (
    <textarea
      rows={row}
      onClick={onClick}
      value={msg}
      ref={textAreaRef}
      placeholder={'Send a message'}
      className="form-control message-input"
      onChange={handleChange}
      autoFocus
    />
  );
};

AdaptiveTextArea.propTypes = {
  msg: PropTypes.string,
  setMsg: PropTypes.func,
  onClick: PropTypes.func,
  rows: PropTypes.number,
  focusKey: PropTypes.number,
  getRows: PropTypes.func,
};
export default AdaptiveTextArea;
