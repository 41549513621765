const initialState = {
  loading: false,
  name: '',
  experience: '',
  appliedJobs: [],
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, loading: true };
    case 'SET_USER_SUCCESS':
      return { ...state, ...action.payload, loading: false };
    case 'SET_USER_FAILURE':
      return { ...state, loading: false };
    case 'REMOVE_USER':
      return { ...initialState };
    case 'APPLY_JOB':
      return {
        ...state,
        appliedJobs:
          state.appliedJobs.indexOf(action.payload) >= 0
            ? [...state.appliedJobs]
            : [...state.appliedJobs, action.payload],
      };
    default:
      return state;
  }
}
