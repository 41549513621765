import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from '../../components/card';
import { Link } from 'react-router-dom';
import ContentLoader, { Facebook } from 'react-content-loader';
import { useMessageThreads } from '../../context/message-threads-context';

const MyLoader = (props) => (
  <>
    {props.small ? (
      <ContentLoader
        speed={2}
        width={275}
        height={200}
        viewBox="0 0 275 200"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="22" y="29" rx="3" ry="3" width="200" height="20" />
        <rect x="28" y="100" rx="3" ry="3" width="200" height="9" />
        <rect x="24" y="129" rx="3" ry="3" width="200" height="9" />
        <rect x="25" y="160" rx="3" ry="3" width="200" height="9" />
      </ContentLoader>
    ) : (
      <ContentLoader
        speed={2}
        width={800}
        height={200}
        viewBox="0 0 800 200"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="22" y="13" rx="3" ry="3" width="604" height="20" />
        <rect x="23" y="53" rx="3" ry="3" width="604" height="20" />
        <rect x="26" y="95" rx="3" ry="3" width="127" height="20" />
        <rect x="160" y="95" rx="3" ry="3" width="127" height="20" />
        <rect x="25" y="134" rx="3" ry="3" width="604" height="20" />
      </ContentLoader>
    )}
  </>
);

const JobList = ({
  small = false,
  loading,
  data,
  selectJob,
  to,
  userInfo,
  currentJobId,
  showRemoveText,
  saveJobFn,
  isJobSaved,
  savingJobId,
  savedJobs,
  contactedJobs,
  appliedJobs,
  onSelect,
  candidateId,
}) => {
  const { openThreadModal, setCandidateId, setJobData } = useMessageThreads();
  useEffect(() => {
    setCandidateId(candidateId);
  }, [candidateId]);
  if (loading) {
    return (
      <div className="row">
        <div onClick={selectJob} className="col-md-12 col-xl-12 col-sm-12 mt-2 mb-2">
          <div className={`card side-card`}>
            <div className="card-body job-detail">
              <MyLoader small={small} />
            </div>
          </div>
        </div>
        <div onClick={selectJob} className="col-md-12 col-xl-12 col-sm-12 mb-2">
          <div className={`card side-card`}>
            <div className="card-body job-detail">
              <MyLoader small={small} />
            </div>
          </div>
        </div>
        <div onClick={selectJob} className="col-md-12 col-xl-12 col-sm-12 mb-2">
          <div className={`card side-card`}>
            <div className="card-body job-detail">
              <MyLoader small={small} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (small) {
    return (
      <div>
        <div style={{ maxHeight: '725px', overflowY: 'scroll' }}>
          {data?.map((job) => (
            <div
              key={job.id}
              id={job.id}
              onClick={selectJob}
              className="col-md-12 col-xl-12 col-sm-12 p-0"
            >
              <div
                className={`card side-card ${job.id === currentJobId && 'left-bar'}`}
              >
                <div className="card-body job-detail">
                  <div className="row">
                    <div className="col">
                      <h5 className="card-subtitle">{job.title}</h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-md-12 col-xl-12 b-detail">
                      <p className="job-card-small-description">{job.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="tab-content" id="pills-tabContent">
      <div
        className="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        {data?.map((job, key) => (
          <Link
            onClick={(e) => {
              if (job.status == 'unpublished' || job.status == 'deleted') {
                e.preventDefault();
              }
            }}
            className={`card-link m-0${
              job.status == 'unpublished' || job.status == 'deleted'
                ? ' greyed-out'
                : ''
            }`}
            to={() => {
              if (job.status == 'unpublished' || job.status == 'deleted') {
                return '';
              } else {
                return to(job.id, key, true, {
                  unavilablePost:
                    job.status == 'unpublished' || job.status == 'deleted'
                      ? true
                      : false,
                });
              }
            }}
            key={job.id}
          >
            <Card
              id={job.id}
              userInfo={userInfo}
              title={job.title}
              appliedJobs={appliedJobs}
              showRemoveText={
                showRemoveText &&
                (job.status == 'unpublished' || job.status == 'deleted')
              }
              status={job.status == 'published'}
              contactedJobs={contactedJobs}
              experience={job.experience}
              show_tag
              className="my-2"
              locations={job.location ? job.location.map((l) => l.name) : []}
              employmentOptions={
                job.employmentOptions ? job.employmentOptions.map((e) => e.name) : []
              }
              salary={job.salary}
              companyName={job.companyName}
              body={job.description}
              bodyHeader="About the job"
              savingId={savingJobId}
              onSave={saveJobFn}
              onClick={(id) => {
                if (job.status == 'unpublished' || job.status == 'deleted') {
                  return;
                }
                onSelect(id, key);
              }}
              onMessage={(id) => {
                openThreadModal(job.id);
                setJobData(job);
              }}
              saved={savedJobs && savedJobs.includes(job.id)}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

JobList.propTypes = {
  small: PropTypes.any,
  userInfo: PropTypes.any,
  loading: PropTypes.bool,
  data: PropTypes.any,
  selectJob: PropTypes.any,
  currentJobId: PropTypes.number,
  showRemoveText: PropTypes.any,
  saveJobFn: PropTypes.any,
  isJobSaved: PropTypes.func,
  savingJobId: PropTypes.string,
  to: PropTypes.func,
  savedJobs: PropTypes.arrayOf(PropTypes.string),
  appliedJobs: PropTypes.array,
  contactedJobs: PropTypes.array,
  onSelect: PropTypes.func,
  candidateId: PropTypes.string,
};

MyLoader.propTypes = {
  small: PropTypes.any,
};

export default JobList;
