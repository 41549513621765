import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../../../context/auth-context';

export const PrivateRoute = ({ component: Component, ...otherProps }) => {
  const auth = useAuth();
  return (
    <Route
      {...otherProps}
      render={(props) =>
        !auth.isAuthenticated() ? (
          <Redirect to="/login" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
};
