import React from 'react';
import { useQuery } from 'react-query';
import { fetchSavedCandidates } from '../pages/search-candidates/service';
import { useLists } from '../context/lists-context';

export function useSavedCandidates() {
  const { savedCandidates, setSavedCandidates } = useLists();
  const { data, isLoading, refetch, isError, error, isFetching } = useQuery(
    'get-saved-candidates',
    fetchSavedCandidates,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      useErrorBoundary: (error) => {
        return error.response.status === 401;
      },
      retry: (count, error) => {
        if (error.response.status === 401) {
          return false;
        } else if (count <= 3) {
          return true;
        } else {
          false;
        }
      },
      enabled: savedCandidates?.length == 0,
    }
  );

  React.useEffect(() => {
    data && setSavedCandidates(data);
  }, [data]);

  return {
    candidates: data,
    isLoading,
    isError,
    error,
    refetch,
    isFetching,
  };
}
