import React, { createContext, useContext } from "react";

export const AuthContext = createContext(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth can only be used inside AuthProvider");
  }

  return context;
}