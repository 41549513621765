import { FiltersContext } from './filters-context';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDebounce } from '../hooks/useDebounce';

const initialFilters = {
  title: null,
  skills: [],
  salary: '',
  languages: [],
  employment_options: [],
  experience: '',
  locations: [],
};

export const FiltersProvider = ({ children }) => {
  //title or position filter
  const [selectedTitleFilter, setSelectedTitleFilter] = useState('');

  //experience filter
  const [experience, setExperience] = useState('');

  //skills filter
  const [selectedSkillsFilter, setSelectedSkillsFilter] = useState([]);

  //languages filter
  const [selectedLanguagesFilter, setSelectedLanguagesFilter] = useState([]);

  //salary filter
  const [salary, setSalary] = useState('');

  //job types filter
  const [jobTypesFilter, setJobTypesFilter] = useState([]);

  //geos filter
  const [selectedGeosFilter, setSelectedGeosFilter] = useState([]);

  const debouncedSelectedTitleFilter = useDebounce(selectedTitleFilter, 500);
  const debouncedSelectedSkillsFilter = useDebounce(selectedSkillsFilter, 500);
  const debouncedSelectedLanguagesFilter = useDebounce(selectedLanguagesFilter, 500);
  const debouncedHandleSalaryChange = useDebounce(salary, 500);
  const debouncedJobTypesFilter = useDebounce(jobTypesFilter, 500);
  const debouncedExperience = useDebounce(experience, 500);
  const debouncedSelectedGeosFilter = useDebounce(selectedGeosFilter, 500);

  const [appliedFilters, setAppliedFilters] = useState(initialFilters);

  useEffect(() => {
    setAppliedFilters({
      title: selectedTitleFilter,
      skills: selectedSkillsFilter.map(({ id }) => id),
      languages: selectedLanguagesFilter.map(({ id }) => id),
      salary: salary && Number(salary),
      employment_options: jobTypesFilter.map(({ id }) => id),
      experience: experience && Number(experience),
      locations: selectedGeosFilter.map(({ id }) => id),
    });
  }, [
    debouncedSelectedTitleFilter,
    debouncedSelectedSkillsFilter,
    debouncedSelectedLanguagesFilter,
    debouncedHandleSalaryChange,
    debouncedJobTypesFilter,
    debouncedExperience,
    debouncedSelectedGeosFilter,
  ]);

  const clearAllFilters = () => {
    setSelectedTitleFilter(null);
    setSelectedSkillsFilter([]);
    setSelectedLanguagesFilter([]);
    setJobTypesFilter([]);
    setExperience('');
    setSalary('');
    setSelectedGeosFilter([]);
  };

  return (
    <FiltersContext.Provider
      value={{
        clearAllFilters,
        appliedFilters,
        selectedTitleFilter,
        setSelectedTitleFilter,
        selectedSkillsFilter,
        setSelectedSkillsFilter,
        selectedLanguagesFilter,
        setSelectedLanguagesFilter,
        jobTypesFilter,
        setJobTypesFilter,
        experience,
        setExperience,
        salary,
        setSalary,
        selectedGeosFilter,
        setSelectedGeosFilter,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

FiltersProvider.propTypes = {
  children: PropTypes.any,
};
