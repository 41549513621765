import { ListsContext } from './lists-context';
import PropTypes from 'prop-types';
import { useState } from 'react';

export const ListsProvider = ({ children }) => {
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [isAppliedJobsInvalidated, setIsAppliedJobsInvalidated] = useState(false);
  const [savedJobs, setSavedJobs] = useState([]);
  const [savedCandidates, setSavedCandidates] = useState([]);

  return (
    <ListsContext.Provider
      value={{
        appliedJobs,
        setAppliedJobs,
        isAppliedJobsInvalidated,
        setIsAppliedJobsInvalidated,
        savedJobs,
        setSavedJobs,
        savedCandidates,
        setSavedCandidates,
      }}
    >
      {children}
    </ListsContext.Provider>
  );
};

ListsProvider.propTypes = {
  children: PropTypes.any,
};
