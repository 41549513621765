import { getJob } from '../../api';

export async function fetchJob({ queryKey: [_, { id }] }) {
  try {
    const { data } = await getJob(id);
    return data;
  } catch (e) {
    console.log(e);
    throw e
  }
}
